import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

import { DailyLocal, DailyLocalExtended, DailyOverview } from '@shared/factories';
import { NotificationService, SessionStorageService    } from '@shared/services';
import { collapse } from '@shared/animations';

@Component({
  selector:      'daily-overview-item',
  templateUrl: './daily-overview-item.component.html',
  host: { class: 'shadow' },
  animations: [collapse]
})
export class DailyOverviewItemComponent {
  @Input() daily:     DailyOverview | DailyLocal | DailyLocalExtended;
  @Input() allowEdit: boolean;
  @Output() delete = new EventEmitter<number>;

  collapsed:   boolean;
  optionsOpen: boolean;

  @HostListener('document:click', ['$event']) close(event: MouseEvent) {
    if (this.optionsOpen) this.optionsOpen = false;
  }
  constructor(
    private notificationService:   NotificationService,
    private sessionStorageService: SessionStorageService,
  ) {}

  toggleOptions(): void {
    if (!this.optionsOpen) setTimeout(() => this.optionsOpen = !this.optionsOpen);
  }

  editDaily(): void {
    let daily = new DailyLocalExtended(this.daily.toJSON());
    this.sessionStorageService.pushDynamicComponent({
      component: 'DailyReport',
      props: {
        daily: daily,
        date:  daily.startsAt,
        callback: (daily: DailyLocalExtended) => this.daily = daily
      }
    });
  }

  deleteReportPrompt(): void {
    this.notificationService.confirm({
      title: 'delete',
      desc: 'Are you sure you want to delete this daily?',
      buttons: {
        left:  'yes',
        right: 'no',
      },
      leftFn: () => this.delete.emit(this.daily.id)
    });
  }

  getMileage(): boolean {
    return this.daily instanceof DailyLocalExtended ? this.daily.mileage : false;
  }

  getErrors(): Set<string> {
    return this.daily instanceof DailyLocal || this.daily instanceof DailyLocal ? this.daily.errorsAll : null;
  }

}
