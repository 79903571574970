<span *ngFor="let el of sidebarIcons"
      [class.hide]="el.hide"
      [ngClass]="(el.border ? 'bb-grey' : el.arrow ? 'du-flex-justify pt-s pb-s' : 'pt-m pb-m') + ' pl-xl pr-xl ' + el.class"
      (click)="elCallback(el)">
  <div class="du-flex-align gap-m">
    <img  *ngIf="el.svg"  class="width-35" [src]="'assets/svg/'+el.svg+'.svg'">
    <span *ngIf="el.icon" class="du-flex-center font-icon" [ngClass]="'du-icon-'+el.icon"></span>
    <span *ngIf="el.label">{{ el.label | translate }}</span>
  </div>
  <img *ngIf="el.arrow" class="width-20" src="assets/svg/arrow-right-blue.svg">
</span>
