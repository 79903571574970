<div class="du-flex-column du-flex-1 gap-m overflow">
  <span class="text-l bold" translate="selectDocumentType"></span>
  <ul class="du-flex-column gap-m overflow-auto" *ngIf="types && types.length">
    <li *ngFor="let type of types" (click)="tempType = type"
         class="du-flex-align du-flex-max-content lh-l b-radius p-m hover-active"
        [class.bg-card-grey]="!tempType || tempType.name !== type.name"
        [class.bg-blue]="tempType && tempType.name === type.name"
        [class.color-white]="tempType && tempType.name === type.name">
      <span>{{ type.name }}</span>
    </li>
  </ul>
</div>

<button class="btn-primary btn-round" translate="next" (click)="confirmType()" [disabled]="!tempType"></button>