import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { SessionStorageService } from '@shared/services';

const MAX_HEIGHT = 50;

@Component({
  selector:      'pull-to-refresh',
  templateUrl: './pull-to-refresh.component.html',
  host: { class: 'du-flex-center animate-height overflow-y-auto du-flex-50', '[class.hide]': 'offlineMode' }
})
export class PullToRefreshComponent implements OnInit {
  offlineMode:  boolean;
  scrollStart:  number;
  scrollMove:   number;
  scrollLocked: number;

  @Output() callback = new EventEmitter<void>;

  @HostListener('document:mousedown',  ['$event'])
  @HostListener('document:touchstart', ['$event'])
  scrollStartHandler(event: MouseEvent | TouchEvent): void {
    if (!this.offlineMode) {
      event.stopPropagation();
      let el = (this.elRef.nativeElement.parentElement as HTMLDivElement);
      if (el?.scrollTop === 0) this.scrollStart = this.getMousePosition(event);
    }
  }
  @HostListener('document:mousemove', ['$event'])
  @HostListener('document:touchmove', ['$event'])
  scrollMoveHandler(event: MouseEvent | TouchEvent): void {
    event.stopPropagation();
    if (this.scrollStart) this.scrollMove = this.getMousePosition(event);
  }

  @HostListener('document:mouseup',  ['$event'])
  @HostListener('document:touchend', ['$event'])
  scrollEndHandler(event: MouseEvent): void {
    event.stopPropagation();
    let height = this.getScrollHeight();
    if (height > 30 && height <= MAX_HEIGHT) {
      this.scrollLocked = MAX_HEIGHT;
      this.triggerRefresh();
    } else if (this.scrollStart) this.reset();
  }

  constructor(
    private elRef:                 ElementRef,
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.reset();
    this.sessionStorageService.offlineMode.subscribe(offline => this.offlineMode = offline);
    this.sessionStorageService.pulltoRefreshState.subscribe(state => { if (state) this.reset(); });
    (this.elRef.nativeElement.parentElement as HTMLDivElement).classList.add('relative', 'overflow-x-none', 'animate-top', 'top-0', 'height-min-full-75');
  }

  getScrollHeight(): number {
    if (this.scrollLocked) return this.scrollLocked;
    if (this.scrollStart && this.scrollMove && this.scrollMove - this.scrollStart > 0) return Math.min(MAX_HEIGHT, this.scrollMove - this.scrollStart);
    return +!!this.scrollLocked;
  }

  triggerRefresh() {
    (this.elRef.nativeElement.parentElement as HTMLDivElement).classList.remove('top--75');
    setTimeout(() => this.callback.emit()); 
  }

  private reset(): void {
    this.scrollStart  = null;
    this.scrollMove   = null;
    this.scrollLocked = null;
    this.sessionStorageService.setPulltoRefreshState(false);
    (this.elRef.nativeElement.parentElement as HTMLDivElement).classList.add('top--75');
  }

  private getMousePosition(e: MouseEvent | TouchEvent): number {
    return (e as TouchEvent).touches?.length ? (e as TouchEvent).touches[(e as TouchEvent).touches.length-1].clientY : (e as MouseEvent).clientY;
  }

}
