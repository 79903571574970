import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService, NotificationService, SessionStorageService } from '@shared/services';
import { slideUp } from '@shared/animations';

@Component({
  selector:      'privacy-box',
  templateUrl: './privacy-box.component.html',
  host: { class: 'absolute bottom-0 left-0 right-0 du-flex-column btl-radius btr-radius absolute bg-white z-2' },
  animations: [ slideUp ]
})
export class PrivacyBoxComponent implements OnInit, OnDestroy {
  privacy: boolean;
  token:   string;
  @HostBinding('@slideUp') get slideIn() { return 'open'; }
  private readonly COMPONENT_NAME: string = 'PrivacyBox';
  constructor(
    private router:                Router,
    private authService:           AuthService,
    private notificationService:   NotificationService,
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.sessionStorageService.pushOverflowStack(this.COMPONENT_NAME);
  }

  ngOnDestroy(): void {
    this.sessionStorageService.popOverflowStack(this.COMPONENT_NAME);
  }

  close(): void {
    this.sessionStorageService.popDynamicComponent(this.COMPONENT_NAME)
  }

  openPrivacy(e: any = null): void {
    if (e) e.stopPropagation();
    window.open('https://www.tempton.de/datenschutz', '_blank');
  }

  sendEmail(email: string): void {
    window.open(`mailto:${email}`);
  }

  acceptPrivacy(): void {
    this.notificationService.wait();
    this.authService.acceptPrivacy().subscribe(
      ()  => {
        if (this.token) {
          this.authService.saveRefreshToken(this.token);
          this.router.navigate(['time-tracking/home']);
        }
        this.close();
        this.notificationService.close();
      },
      err => this.notificationService.alert(err) 
    );
  }

}
