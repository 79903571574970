import { Component, OnInit } from '@angular/core';
import { Router            } from '@angular/router';

import { PushNotificationOverview } from '@shared/factories';
import { SortModel            } from '@shared/models';
import { NotificationService, NotificationsOverviewService, SessionStorageService } from '@shared/services';

@Component({
  selector:      'notifications',
  templateUrl: './notifications.component.html',
  host: { class: 'height-full' }
})
export class NotificationsComponent implements OnInit {
  page: number      = 0;
  sort: SortModel[] = [
    { label: 'latest',       column: 'created_at', active: true },
    { label: 'alphabetical', column: 'name'       },
    { label: 'underReview',  column: '!approved'  },
    { label: 'reviewed',     column: 'approved'   },
  ];

  notifications: PushNotificationOverview[] = [];
  constructor(
    private router:                       Router,
    private notificationService:          NotificationService,
    private sessionStorageService:        SessionStorageService,
    private notificationsOverviewService: NotificationsOverviewService,
  ) {}

  ngOnInit(): void {
    this.sessionStorageService.setHeaderTitle('notifications');
    this.sessionStorageService.setHeaderControls({ left: [{ icon: 'arrow-big-left color-blue font-icon', callback: () => this.router.navigateByUrl('time-tracking/home') }] });
    this.sessionStorageService.setProgressBar(null);
    this.loadNotifications();
  }

  loadNotifications(): void {
    this.notificationService.wait();
    this.page = this.page ? this.page+1 : 1;
    this.notificationsOverviewService.syncNotifications(this.page).subscribe(
      notifications => {
        this.notifications = [...this.notifications, ...notifications];
        this.notificationService.close();
      },
      err => this.notificationService.alert(err)
    );
  }

}
