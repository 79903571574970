<div *ngIf="!documents.length" class="du-flex-column">
  <span class="pl-xl pr-xl pt-xl pb-m color-primary font-l lh-l bb-grey bold" translate="documentCreationPage"></span>
  <span class="pl-xl pr-xl pt-xl" translate="noPendingDocuments"></span>
</div>

<div class="du-flex-column du-flex-1 gap-l p-xl overflow-auto">
  <document-preselect-item *ngFor="let document of documents"
                           [document]="document"
                           (submitDocument)="submitDocument($event)"
                           (deleteDocument)="deleteDocument($event)">
  </document-preselect-item>
</div>

<div class="du-flex-column du-flex-justify gap-m p-xl">
  <button class="width-full btn-primary btn-round" translate="newDocument" (click)="callbackHandler()"></button>
</div>
