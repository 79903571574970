import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router       } from '@angular/router';
import { Location } from '@angular/common';
import { take     } from 'rxjs';

import { ActivityReportLocal, CustomerEmail, VacationRequestLocal } from '@shared/factories';
import { ActivityReportsService, NotificationService, SessionStorageService, VacationRequestsService } from '@shared/services';

@Component({
  templateUrl: './status-selector.component.html',
  host: { class: 'height-full du-flex-column' }
})
export class StatusSelectorComponent implements OnInit, OnDestroy {
  entry:          ActivityReportLocal | VacationRequestLocal;
  checkSignature: boolean;
  useCase:        string;
  constructor(
    private route:                   ActivatedRoute,
    private router:                  Router,
    private location:                Location,
    private activityReportsService:  ActivityReportsService,
    private vacationRequestsService: VacationRequestsService,
    private notificationService:     NotificationService,
    private sessionStorageService:   SessionStorageService
  ) {}

  ngOnInit(): void {
    this.useCase = this.route.snapshot.data['useCase'];
    this.prepareHeader();
    this.checkData();
  }

  ngOnDestroy(): void {
    if (this.useCase === 'vr') this.sessionStorageService.setTemporaryVacation(this.entry as VacationRequestLocal);
    else this.sessionStorageService.setTemporaryReport(this.entry as ActivityReportLocal);
  }

  private prepareHeader(): void {
    if (this.useCase === 'ar' || this.useCase === 'ar-t' ) {
      this.sessionStorageService.setHeaderTitle('activityReport');
      this.sessionStorageService.setProgressBar(5, 6);
    } else if (this.useCase === 'dr') {
      this.sessionStorageService.setHeaderTitle('combineDailyReports');
      this.sessionStorageService.setProgressBar(null);
    } else if (this.useCase === 'vr') {
      this.sessionStorageService.setHeaderTitle('vacationRequest');
      this.sessionStorageService.setProgressBar(4, 5);
    }
    this.sessionStorageService.setHeaderControls({
      left: [{ icon: 'arrow-big-left color-blue font-icon', callback: () => this.location.back() }]
    });
  }

  private checkData(): void {
    if (this.useCase === 'vr') this.setVacationData();
    else this.setReportData();
  }

  private setVacationData(): void {
    this.sessionStorageService.temporaryVacation.pipe(take(1)).subscribe((vacation: VacationRequestLocal) => {
      if (!vacation || !vacation.assignment || !vacation.startsOn || !vacation.endsOn) this.router.navigateByUrl('time-tracking/home');
      else this.entry = vacation;
    });
  }

  private setReportData(): void {
    this.sessionStorageService.temporaryReport.pipe(take(1)).subscribe((report: ActivityReportLocal) => {
      if (!report || !report.assignment || !report.startDate || !report.dailyReports || !report.dailyReports.length) this.router.navigateByUrl('time-tracking/home');
      else this.entry = report;
    });
  }

  selectLifeState(lifeState: number): void {
    if ((!this.entry.signedByCustomer) && lifeState === 1) this.openSignatureBox();
    else this.checkSignature = false;
    this.setLifeState(lifeState);
  }

  private setLifeState(lifeState: number): void {
    switch (lifeState) {
      case 1:
        return this.entry.setSignedByCustomer();
      case 2:
        return this.entry.setWaitingCustomerApproval();
      case 3:
        return this.entry.setWaitingClarification();
      default:
        break;
    }
  }

  openSignatureBox(): void {
    this.sessionStorageService.pushDynamicComponent({
      component: 'SignatureBox',
      props: {
        signature: this.entry.signature,
        signer:    this.entry.signer,
        save: ({ signature, signer }: any) => {
          this.entry.signature = signature;
          this.entry.signer    = signer;
        },
        closeCallback: () => { this.checkSignature = true; }
      }
    });
  }

  openCustomerEmailBox(): void {
    this.sessionStorageService.pushDynamicComponent({
      component: 'CustomerEmailBox',
      props: {
        assignmentId: (this.entry as ActivityReportLocal).assignment.id,
        activeEmails: (this.entry as ActivityReportLocal).customerEmails || [],
        save: ({ customerEmails }: { customerEmails: CustomerEmail[] }) => {
          (this.entry as ActivityReportLocal).customerEmails = customerEmails.map(c => c.email);
        }
      }
    });
  }

  readyForSubmit(): boolean {
    if (this.entry && this.entry.lifeState) {
      if (this.entry.signedByCustomer) return !!this.entry.signature && !!this.entry.signer;
      else return true;
    }
    return false;
  }

  saveEntry(): void {
    if (this.useCase === 'vr') this.saveVacation();
    else this.saveReport();
  }

  private saveReport(): void {
    this.activityReportsService.saveActivityReport(this.entry as ActivityReportLocal).subscribe(
      res => this.notificationService.close(),
      err => this.notificationService.alert(err)
    );
  }

  private saveVacation(): void {
    this.vacationRequestsService.saveVacationRequest(this.entry as VacationRequestLocal).subscribe(
      res => this.notificationService.close(),
      err => this.notificationService.alert(err)
    );
  }

  submitEntry() {
    if (this.useCase === 'vr') this.submitVacation();
    else this.submitReport();
  }

  private submitReport(): void {
    this.activityReportsService.submitActivityReport(this.entry as ActivityReportLocal).subscribe(
      res => this.notificationService.close(),
      err => this.notificationService.alert(err)
    );
  }

  private submitVacation() {
    this.notificationService.wait();
    this.vacationRequestsService.submitVacationRequest(this.entry as VacationRequestLocal).subscribe(
      res => this.notificationService.close(),
      err => this.notificationService.alert(err)
    );
  }

  getReport(): ActivityReportLocal {
    return this.entry as ActivityReportLocal;
  }

}