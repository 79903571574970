import { MileageDataModel, MileageDataTimeFrameModel } from "@shared/models";

export class MileageData {
  userId:                number;
  assignmentId:          number;
  mileageDataTimeFrames: MileageDataTimeFrame[];
  constructor(data: MileageDataModel) {
    this.assignmentId          = data.assignment_id;
    this.mileageDataTimeFrames = data.mileage_money_time_frames && data.mileage_money_time_frames.length ? data.mileage_money_time_frames.map(mdtf => new MileageDataTimeFrame(mdtf)) : null;
  }

  toJSON(): MileageDataModel {
    return {
      userId:                    this.userId       ? this.userId       : null,
      assignment_id:             this.assignmentId ? this.assignmentId : null,
      mileage_money_time_frames: this.mileageDataTimeFrames && this.mileageDataTimeFrames.length ? this.mileageDataTimeFrames.map(mdtf => mdtf.toJSON()) : null,
    };
  }

}

export class MileageDataTimeFrame {
  startsAt: Date;
  endsAt:   Date;
  constructor(data: MileageDataTimeFrameModel) {
    this.startsAt = this.parceDate(data.starts_at)
    this.endsAt   = this.parceDate(data.ends_at);
  }

  private parceDate(date: any) {
    return date ? date instanceof Date ? date : new Date(date) : null;
  }

  toJSON(): MileageDataTimeFrameModel {
    return {
      starts_at: this.startsAt ? this.startsAt.toISOString() : null,
      ends_at:   this.endsAt   ? this.endsAt.toISOString()   : null
    };
  }

}
