export default (userName: string) => [
  { icon:  'user',      label: userName                                    }, { border: true },

  { icon:  'tutorial',  label: 'tutorials',                                },
  { icon:  'mileage',   label: 'mileageMoney',   path: 'mileage-config'    }, { border: true },

  { icon:  'user-plus', label: 'referAFriend',   path: 'refer-a-friend'    },
  { icon:  'euro',      label: 'benefits',       path: 'employee-benefits' },
  { icon:  'tutorial',  label: 'visitWebsite'                              },
  { icon:  'bell',      label: 'notifications',  path: 'notifications'     },
  { icon:  'question',  label: 'helpAndSupport', path: 'support'           },
  { icon:  'logout',    label: 'logout'                                    }, { border: true },
  {                     label: 'lang', arrow: true                         }, { border: true },

  { icon:  'releases',  label: 'releases',       path: 'releases', class: 'color-primary-70' },
  { icon:  'legal',     label: 'legal',          path: 'legal',    class: 'color-primary-70' },
  { icon:  'legal',     label: 'debug',          path: 'debug',    class: 'color-primary-70', hide: true }
];
