import { Component, OnInit      } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location               } from '@angular/common';
import { take                   } from 'rxjs';

import { ActivityReportLocal    } from '@shared/factories';
import { SessionStorageService  } from '@shared/services';

@Component({
  templateUrl: './report-confirm.component.html',
  host: { class: 'height-full du-flex-column du-flex-justify' }
})
export class ReportConfirmComponent implements OnInit {
  report:  ActivityReportLocal;
  useCase: string;
  constructor(
    private route:                 ActivatedRoute,
    private router:                Router,
    private location:              Location,
    private sessionStorageService: SessionStorageService,
  ) {}

  ngOnInit(): void {
    this.useCase = this.route.snapshot.data['useCase'];
    this.prepareHeader();
    this.sessionStorageService.temporaryReport.pipe(take(1)).subscribe((report: ActivityReportLocal) => {
      if (!report || !report.assignment || !report.startDate || !report.dailyReports || !report.dailyReports.length) this.router.navigateByUrl('time-tracking/home');
      else this.report = report;
    });
  }

  private prepareHeader(): void {
    if (this.useCase === 'ar' || this.useCase === 'ar-t') {
      this.sessionStorageService.setHeaderTitle('activityReport');
      this.sessionStorageService.setProgressBar(4, 6);
    } else if (this.useCase === 'dr') {
      this.sessionStorageService.setHeaderTitle('combineDailyReports');
      this.sessionStorageService.setProgressBar(null);
    }
    this.sessionStorageService.setHeaderControls({
      left: [{ icon: 'arrow-big-left color-blue font-icon', callback: () => this.location.back() }]
    });
  }

  callbackHandler(): void {
    this.router.navigateByUrl(`time-tracking/status-select-${this.useCase}`);
  }

}
