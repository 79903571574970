import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, forkJoin, from } from 'rxjs';
import { map, switchMap, tap  } from 'rxjs/operators';

import { Network } from '@capacitor/network';

import { DBService } from './db.service';
import { PushNotificationsService } from './push-notifications.service';

import { environment } from 'environments/environment';
import { UserService } from './user.service';
import { Device } from '@capacitor/device';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  private readonly ACCESS_TOKEN  = 'accessToken';
  private readonly REFRESH_TOKEN = 'refreshToken';
  private readonly USERS_API     = `${environment.apiUrl}/api/mobile/v3/users`;

  private accessTokenSubject = new BehaviorSubject<string>(localStorage.getItem(this.ACCESS_TOKEN) || '');
  accessToken = this.accessTokenSubject.asObservable();

  private refreshTokenSubject = new BehaviorSubject<string>(localStorage.getItem(this.REFRESH_TOKEN) || '');
  refreshToken = this.refreshTokenSubject.asObservable();

  constructor(
    private http:                     HttpClient,
    private dbService:                DBService,
    private userService:              UserService,
    private pushNotificationsService: PushNotificationsService,
  ) { }

  saveAccessToken(token: string): void {
    if (token) localStorage.setItem(this.ACCESS_TOKEN, token);
    else localStorage.setItem(this.ACCESS_TOKEN, '');
    this.accessTokenSubject.next(token);
  }

  public get accessTokenValue(): string {
    return this.accessTokenSubject.value;
  }

  saveRefreshToken(token: string): void {
    if (token) localStorage.setItem(this.REFRESH_TOKEN, token);
    else localStorage.setItem(this.REFRESH_TOKEN, '');
    this.refreshTokenSubject.next(token);
  }

  public get refreshTokenValue(): string {
    return this.refreshTokenSubject.value;
  }

  isLoggedIn(): Promise<boolean> {
    return Network.getStatus().then(res => {
      if (res.connected) return !!this.refreshTokenValue;
      else return this.dbService.loadLoggedUser().then(user => !!user);
    });
  }

  login(email: string, password: string): Observable<string> {
    return forkJoin([
      from(Device.getId()),
      from(Device.getInfo())
    ]).pipe(
      switchMap(res => {
        let data = {
          user: {
            email,
            password,
            uuid: res[0].identifier,
            manufacturer: res[1].manufacturer,
            model: res[1].model
          }
        };
        return this.http.post<any>(`${this.USERS_API}/sign_in`, data);
      }),
      map((res: any) => res.refresh_token),
      tap((token: string) => this.saveRefreshToken(token))
    );
  }

  logout(): void {
    this.removeTokens();
    this.pushNotificationsService.unSubscribeUser(this.userService.currentUserValue);
    location.reload();
  }

  removeTokens(): void {
    this.saveAccessToken(null);
    this.saveRefreshToken(null);
  }

  resetPassword(email: string): Observable<any> {
    return this.http.post<any>(`${this.USERS_API}/reset_password.json`, { email });
  }

  acceptPrivacy(): Observable<any> {
    return this.http.post<any>(`${this.USERS_API}/accept_privacy_policy`, {});
  }

}
