<li *ngFor="let daily of report.dailyReports; let i = index" class="du-flex-column du-flex-center du-flex-1 height-50 b-radius gap-xs font-s pl-xxs pr-xxs pt-xs pb-xs hover-active" 
    (click)="daySelectCallback.emit(i)"
    [ngClass]="dayIsDisabled(daily)      ? 'b-grey color-disabled event-none' :
               daily.techErrorsAll.size  ? 'bg-red    color-white'            :
               daily.legalErrorsAll.size ? 'bg-orange color-white'            :
              !daily.placeholder         ? 'bg-blue   color-white'            :
               activeReportIndex === i   ? 'bg-blue   color-white'            : 'bg-card-grey'">
  <span>{{ daily.startsAt | date: 'EEE' }}</span>
  <span *ngIf="useCase !== 't'" class="bold">{{ daily.startsAt | date: 'dd.MM' }}</span>
</li>
