<div class="du-flex-column gap-xxs pt-m pl-m pr-m pb-s">
  <div class="du-flex-justify du-flex-align">
    <span class="bold du-flex-align gap-xxs">
      <span translate="cw"></span>
      <span>{{  report.startDate | date: 'ww' }}</span>
      <span>({{ report.startDate | date: 'dd' }}.{{ report.startDate | date: 'MM' }} - {{ report.endDate | date: 'dd' }}.{{ report.endDate | date: 'MM' }})</span>
    </span>

    <span *ngIf="getLocalReport().notSynced" class="du-flex-align gap-xs pl-xs pr-xs pt-xxs pb-xxs b-radius bg-light-orange color-orange">
      <span class="du-flex-center du-icon-offline font-l"></span>
      <span class="font-s" translate="notSynced"></span>
    </span>
    <span *ngIf="getOverviewReport().customerSignature" class="du-flex-align gap-xs pl-xs pr-xs pt-xxs pb-xxs b-radius bg-green-light color-green">
      <span class="du-flex-center du-icon-tick font-l"></span>
      <span class="font-s" translate="signed"></span>
    </span>
  </div>
  <span class="lh-l color-primary-75">{{ report.assignment.title }}</span>
</div>

<div class="du-flex-justify du-flex-align pt-xs pl-m pr-m pb-xs bt-grey" *ngIf="getOverviewReport().createdByTempton || getOverviewReport().confirmationEmails || getOverviewReport().reportCorrected || getOverviewReport().haveHolidays || getOverviewReport().attachmentPath || getOverviewReport().mileageMoneyReportId || getOverviewReport().splitParentId || getOverviewReport().splitChildId">
  <span *ngIf="getOverviewReport().createdByTempton" class="font-s color-blue" translate="createdByTempton"></span>
  <div class="du-flex-justify-end du-flex-1 gap-xs">
    <span class="du-flex-center p-xs circle-24 bg-light-blue" *ngIf="getOverviewReport().confirmationEmails">
      <img class="width-13" src="assets/svg/envelope-blue.svg">
    </span>
    <span class="du-flex-center p-xs circle-24 bg-light-blue" *ngIf="getOverviewReport().reportCorrected && !getOverviewReport().attachmentPath">
      <img class="width-13" src="assets/svg/edited-ar-blue.svg">
    </span>
    <span class="du-flex-center p-xs circle-24 bg-light-blue" *ngIf="getOverviewReport().haveHolidays">
      <img class="width-13" src="assets/svg/holiday-blue.svg">
    </span>
    <span class="du-flex-center p-xs circle-24 bg-light-blue" *ngIf="getOverviewReport().attachmentPath">
      <img class="width-13" src="assets/svg/camera-blue.svg">
    </span>
    <span class="du-flex-center p-xs circle-24 bg-light-blue" *ngIf="getOverviewReport().mileageMoneyReportId">
      <img class="width-13" src="assets/svg/mileage.svg">
    </span>
    <span class="du-flex-center p-xs circle-24 bg-light-blue" *ngIf="getOverviewReport().splitParentId || getOverviewReport().splitChildId">
      <img class="width-10" src="assets/svg/split-blue.svg">
    </span>
  </div>
</div>
