import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FilterFlowModel, PillSectionModel } from '@shared/models';
import { SessionStorageService } from '@shared/services';
import { Assignment            } from '@shared/factories';
import { slideUp               } from '@shared/animations';

@Component({
  selector:      'filter',
  templateUrl: './filter.component.html',
  host: { class: 'absolute bottom-0 left-0 right-0 du-flex-column btl-radius btr-radius absolute bg-white' },
  animations: [ slideUp ]
})
export class FilterComponent implements OnInit, OnDestroy {
  @Input() assignment: Assignment;
  @Input() from:       string;
  @Input() to:         string;
  @Input() pills:      PillSectionModel[];
  @Output() save = new EventEmitter<FilterFlowModel>;
  @HostBinding('@slideUp') get slideIn() { return 'open'; }

  activeAssignment: Assignment;
  fromDate:         Date;
  toDate:           Date;
  private readonly COMPONENT_NAME: string = 'Filter';
  constructor( private sessionStorageService: SessionStorageService ) {}

  ngOnInit(): void {
    this.sessionStorageService.pushOverflowStack(this.COMPONENT_NAME);
    this.sessionStorageService.filterFlow.subscribe(form => {
      this.reset();
      if (form) {
        if (form.assignment) this.activeAssignment = form.assignment;
        if (form.from?.date) this.fromDate         = new Date(form.from.date);
        if (form.to?.date)   this.toDate           = new Date(form.to.date);
        if (form.pills)      this.mapPills(form.pills);
      }
    });
  }

  ngOnDestroy(): void {
    this.sessionStorageService.popOverflowStack(this.COMPONENT_NAME);
  }

  adjustFrom(dateString: string): void {
    this.fromDate = new Date(dateString);
  }

  adjustTo(dateString: string): void {
    this.toDate = new Date(dateString);
  }

  reset(): void {
    this.fromDate         = null;
    this.toDate           = null;
    this.activeAssignment = null;
    this.pills?.forEach((s: any) => s.pills.forEach((p:any) => p.active = false));
  }

  applyFilters(): void {
    this.sessionStorageService.setFilterFlow({
      assignment: this.activeAssignment,
      from: { date: this.fromDate, field: this.from },
      to:   { date: this.toDate,   field: this.to   },
      pills: this.savePills()
    });

    this.save.emit();
    this.close();
  }

  private mapPills(pills: PillSectionModel[]): void {
    pills.forEach(section => {
      let temp = this.pills.find(sec => sec.label === section.label);
      if (temp) section.pills.forEach(pill => {
        let el = temp.pills.find((p: any) => p.value === pill.value);
        if (el) el.active = true;
      });
    });
  }

  private savePills(): PillSectionModel[] {
    return this.pills.map(section => {
      let temp = {...section};
      temp.pills = temp.pills.filter(pill => pill.active);
      return temp;
    }).filter((section: PillSectionModel) => section.pills.length);
  }

  close(): void {
    this.sessionStorageService.popDynamicComponent(this.COMPONENT_NAME)
  }

}
