import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, take, tap } from 'rxjs';

import { MetaModel, SalaryReportExtendedModel, SalaryReportModel } from '@shared/models';
import { SalaryReport, SalaryReportExtended                      } from '@shared/factories';
import { DBService, UserService                                  } from '@shared/services';

import { environment } from 'environments/environment';

interface SalaryReportsByIdResponse {
  salary_report: SalaryReportExtendedModel;
}

interface SalaryReportsResponse {
  meta:           MetaModel;
  salary_reports: SalaryReportModel[];
}

interface SalaryReportsTokenResponse {
  two_factor_authentication_access_token: string;
}

@Injectable({
  providedIn: 'root'
})
export class SalaryReportsService {
  private readonly SALARY_REPORTS_API = `${environment.apiUrl}/api/mobile/v3/salary_reports`;

  constructor(
    private http:        HttpClient,
    private dbService:   DBService,
    private userService: UserService
  ) { }

  getSalaryReportsById(reportId: number): Observable<SalaryReportExtended> {
    let headers = new HttpHeaders().set('Two-Factor-Access-Token', this.userService.salaryTwoFactorTokenValue);
    return this.http.get<SalaryReportsByIdResponse>(`${this.SALARY_REPORTS_API}/${reportId}`, { headers }).pipe(
      take(1),
      map(res => new SalaryReportExtended(res.salary_report))
    );
  }

  loadSalaryReports(page: number = 1, column: string = 'created_at', dir: boolean = false): Observable<SalaryReport[]> {
    let sort = `&q[sort][0][pos]=0&q[sort][0][name]=${column}&q[sort][0][dir]=${dir ? 'asc' : 'desc'}`;
    let headers = new HttpHeaders().set('Two-Factor-Access-Token', this.userService.salaryTwoFactorTokenValue);
    return this.http.get<SalaryReportsResponse>(`${this.SALARY_REPORTS_API}?per_page=50&page=${page}${sort}`, { headers }).pipe(
      take(1),
      map(res => res.salary_reports.map(sr => new SalaryReport(sr)))
    );
  }

  syncSalaryReports(): Observable<SalaryReport[]> {
    return this.loadSalaryReports().pipe(tap(res => this.dbService.saveMultipleToDB('salaryReports', res)));
  }

  requestSalaryReportsToken(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/api/v2/two_factor_authentications`, {}).pipe(take(1));
  }

  createSalaryReportsToken(birth: string): Observable<string> {
    return this.http.post<SalaryReportsTokenResponse>(`${environment.apiUrl}/api/v2/two_factor_authentications/create_access_token`, { two_factor_authentication_otp_code: birth }).pipe(
      take(1),
      map(res => res.two_factor_authentication_access_token)
    );
  }

}
