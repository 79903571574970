import { Component, OnInit } from '@angular/core';
import { Router     } from '@angular/router';
import { Location   } from '@angular/common'
import { Observable } from 'rxjs';

import { NotificationService, SessionStorageService } from '@shared/services';

@Component({
  templateUrl: './reset.component.html'
})
export class ResetComponent implements OnInit {
  stage:  string = 'user';
  code:   string;
  userId: string;
  constructor(
    private location:              Location,
    private router:                Router,
    private sessionStorageService: SessionStorageService,
    private notificationService:   NotificationService,
  ) {}

  ngOnInit(): void {
    this.sessionStorageService.setHeaderTitle('resetPassword');
    this.sessionStorageService.setHeaderControls({
      left:  [{ icon: 'arrow-big-left color-blue font-icon', callback: () => this.location.back() }]
    });
  }

  requestResetCode(obs: Observable<string>): void {
    obs.subscribe(
      userId => {
        this.notificationService.close();
        this.userId = userId;
        this.stage  = 'code';
      },
      err => this.notificationService.alert(err)
    );
  }

  sendResetCode(obs: Observable<string>): void {
    obs.subscribe(
      code => {
        this.notificationService.close();
        this.code  = code;
        this.stage = 'password';
      },
      err => this.notificationService.alert(err)
    );
  }

  setPassword(obs: Observable<string>): void {
    obs.subscribe(
      pass => {
        this.notificationService.close();
        let creds = { userId: this.userId, pass };
        localStorage.setItem('activation_creds', JSON.stringify(creds));
        this.router.navigate(['auth/info'], { queryParams: { type: 'auth' }});
      },
      err => this.notificationService.alert(err)
    );
  }

}
