<ng-container *ngIf="report">
  <div class="du-flex-column du-flex-1 overflow-auto">
    <div class="du-flex-column gap-m pt-xl pl-xl pr-xl pb-m bb-grey">

      <div class="du-flex-justify gap-s">
        <div class="du-flex-column gap-xxs">
          <span class="font-s" translate="assignment"></span>
          <span class="lh-l bold">{{ report.assignment.title }}</span>
          <span class="du-flex-align gap5 roboto">
            <span>{{ report.startDate | date: 'dd.MM.YYYY' }}</span>
            <span>-</span>
            <span>{{ report.endDate   | date: 'dd.MM.YYYY' }}</span>
          </span>
        </div>

        <div class="du-flex-justify gap-s">
          <button class="btn-secondary width-35 height-35" (click)="goPrevReport()" [disabled]="!reportIndex">
            <img *ngIf="reportIndex"  class="width-15 rotate-90" src="assets/svg/arrow-down-blue.svg">
            <img *ngIf="!reportIndex" class="width-15 rotate-90" src="assets/svg/arrow-down-black.svg">
          </button>
          <button class="btn-secondary width-35 height-35" (click)="goNextReport()" [disabled]="reportIndex+1 === cachedReports.length">
            <img *ngIf="reportIndex+1 !== cachedReports.length" class="width-15 rotate-270" src="assets/svg/arrow-down-blue.svg">
            <img *ngIf="reportIndex+1 === cachedReports.length" class="width-15 rotate-270" src="assets/svg/arrow-down-black.svg">
          </button>
        </div>
      </div>

      <div class="du-flex-column gap-xs">
        <div class="du-flex-justify du-flex-align gap-s pl-s pr-s pt-xs pb-xs height-min-40 bg-light-blue b-radius" *ngIf="!report.attachmentPath && report.reportCorrected">
          <img  class="width-20" src="assets/svg/edited-ar-blue.svg">
          <span class="font-s lh-l du-flex-1" translate="reportEdited"></span>
        </div>
        <div class="du-flex-justify du-flex-align gap-s pl-s pr-s pt-xs pb-xs height-min-40 bg-light-blue b-radius" *ngIf="report.mileageMoneyReportId">
          <img  class="width-20" src="assets/svg/mileage.svg">
          <span class="font-s lh-l du-flex-1" translate="millageAppliedToAssignment"></span>
        </div>
        <div class="du-flex-justify du-flex-align gap-s pl-s pr-s pt-xs pb-xs height-min-40 bg-light-blue b-radius" *ngIf="report.confirmationEmails" (click)="showEmailDetails()">
          <img  class="width-20" src="assets/svg/envelope-blue.svg">
          <span class="font-s lh-l du-flex-1" translate="sentByEmail"></span>
        </div>
        <div class="du-flex-justify du-flex-align gap-s pl-s pr-s pt-xs pb-xs height-min-40 shadow" *ngIf="report.splitParentId || report.splitChildId" [routerLink]="['/time-tracking/report-details', report.splitParentId || report.splitChildId]">
          <img  class="width-15" src="assets/svg/split-blue.svg">
          <span class="font-s lh-l du-flex-1" translate="arWasSplit"></span>
          <img  class="mla width-15" src="assets/svg/arrow-right-blue.svg">
        </div>
      </div>
    </div>

    <ul class="du-flex-column gap-m pt-m pl-xl pr-xl pb-m" *ngIf="!report.attachmentPath || report.approvedAt">
      <daily-overview-item [daily]="daily" *ngFor="let daily of report.dailyReports"></daily-overview-item>
    </ul>
    <photo-item *ngIf="report.attachmentPath" class="mt-m ml-xl mr-xl mb-xl" [itemUrl]="report.attachmentPath"></photo-item>

    <div class="du-flex-column bb-grey" *ngIf="report.customerSignature">
      <div class="du-flex-justify du-flex-align pl-xl pr-xl pt-m pb-m bg-card-grey" (click)="collapseSignature = !collapseSignature">
        <span class="font-500" translate="customerSignature"></span>
        <img [class.rotate]="collapseSignature" src="assets/svg/arrow-down-blue.svg">
      </div>
      <div class="du-flex-column pl-xl pr-xl pt-s pb-s" @collapse *ngIf="collapseSignature">
        <div class="du-flex-column overflow relative">
          <img class="b-grey" [src]="report.customerSignature">
          <span *ngIf="report.customerSigner" class="absolute left-0 bottom-0 ml-s mb-s text-s">Unterschrieben von: {{ report.customerSigner }}</span>
        </div>
      </div>
    </div>

    <div class="du-flex-column bb-grey" *ngIf="report.externalNote">
      <div class="du-flex-justify du-flex-align pl-xl pr-xl pt-m pb-m bg-card-grey" (click)="collapseNote = !collapseNote">
        <span class="font-500" translate="noteToManager"></span>
        <img [class.rotate]="collapseNote" src="assets/svg/arrow-down-blue.svg">
      </div>
      <div class="du-flex-column pl-xl pr-xl pt-s pb-s" @collapse *ngIf="collapseNote">
        <div class="du-flex-column overflow">
          <span class="overflow">{{ report.externalNote }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="du-flex-column gap-m bt-grey pt-l pl-xl pr-xl pb-xxxl">
    <span class="du-flex-align du-flex-justify">
      <span translate="totalWorkingHours"></span>
      <span class="text-l bold roboto">{{ report.durationWithPauses | numberToTime }}</span>
    </span>
    <span class="du-flex-align du-flex-justify">
      <span translate="totalWorkingHoursWithoutBreaks"></span>
      <span class="text-l bold roboto">{{ report.durationExludingPauses | numberToTime }}</span>
    </span>
  </div>
</ng-container>
