import { BannerModel } from "@shared/models";

export class Banner {
  id:        string;
  userId:    number;

  tag:       string;
  url:       string;

  imagePath: string;
  image:     string;
  etag:      string;

  link:      string;
  deepLink:  string;
  updatedAt: Date;
  constructor(data: BannerModel, etag?: string, image?: string) {
    this.id        = data.id;
    this.userId    = data.userId;

    this.tag       = data.tag;
    this.url       = data.url;

    this.imagePath = data.image_path;
    this.etag      = data.etag  || etag;
    this.image     = data.image || image;

    this.link      = data.link;
    this.deepLink  = data.deep_link;
    this.updatedAt = this.parceDate(data.updated_at);
  }

  private parceDate(date: any) {
    return date ? date instanceof Date ? date : new Date(date) : null;
  }

  toJSON(): BannerModel {
    return {
      id:         this.id        ? this.id                      : null,
      userId:     this.userId    ? this.userId                  : null,

      tag:        this.tag       ? this.tag                     : null,
      url:        this.url       ? this.url                     : null,

      image_path: this.imagePath ? this.imagePath               : null,
      image:      this.image     ? this.image                   : null,
      etag:       this.etag      ? this.etag                    : null,

      link:       this.link      ? this.link                    : null,
      deep_link:  this.deepLink  ? this.deepLink                : null,
      updated_at: this.updatedAt ? this.updatedAt.toISOString() : null
    };
  }

}
