import { PauseExtendedModel, PauseModel } from "@shared/models";
import { LegalError, TechnicalError, ValidationError } from "@shared/factories";

export class PauseBasic {
  id:    number;
  start: Date;
  end:   Date;
  constructor(data: PauseModel) {
    this.id    = data.id;
    this.start = this.parceDate(data.start);
    this.end   = this.parceDate(data.end);
  }

  get duration(): number {
    return this.end.getTime() - this.start.getTime();
  }

  toJSON(): PauseModel {
    return {
      id:    this.id    ? this.id                  : null,
      start: this.start ? this.start.toISOString() : null,
      end:   this.end   ? this.end.toISOString()   : null
    };
  }

  private parceDate(date: any): Date {
    return date ? date instanceof Date ? date : new Date(date) : null;
  }
}

export class PauseLocal extends PauseBasic {
  errors?: ValidationError[];
  constructor(data: PauseModel) {
    super(data);
    this.errors = [];
  }

  get techErrors(): Set<string> {
    return new Set(this.errors.filter(err => err instanceof TechnicalError).map(err => err.message));
  }
  get legalErrors(): Set<string> {
    return new Set(this.errors.filter(err => err instanceof LegalError).map(err => err.message));
  }
}

export class PauseExtended extends PauseLocal {
  corrected: boolean;
  constructor(data: PauseExtendedModel) {
    super(data);
    this.corrected = data.corrected;
  }

  toJSON(): PauseExtendedModel {
    return Object.assign(super.toJSON(), {
      corrected: this.corrected ? this.corrected : null
    });
  }
}
