import { ChangeDetectorRef, Component } from '@angular/core';
import { NotificationService } from "@shared/services";

@Component({
  selector:    'notification',
  templateUrl: 'notification.component.html',
  styleUrls: ['./notification.component.sass'],
})
export class NotificationComponent {
  message: any;

  icon:    string;
  title:   string;
  desc:    string;
  buttons: any;
  noFn:    any;
  constructor(
    private notificationService: NotificationService,
    private changeDetectorRef:   ChangeDetectorRef
  ) { }
  
  ngOnInit() {
    this.notificationService.getMessage().subscribe((message: any) => {
      this.message = message;
      this.changeDetectorRef.detectChanges();
    });
  }

  overflowClick(): void {
    if (this.message && !this.message.keepOnClick) this.closePopup();
  }

  closePopup() {
    this.notificationService.close();
  }

  isArray(val: any): boolean {
    return Array.isArray(val);
  }

}
