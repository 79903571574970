import { trigger, transition, style, animate } from '@angular/animations';

export const collapse = trigger('collapse', [
  transition(':leave', [
    style({              display: 'grid', 'grid-template-rows': '1fr', 'padding-top': '*', 'padding-bottom': '*', 'margin-top': 0,                }),
    animate(200, style({ display: 'grid', 'grid-template-rows': '0fr', 'padding-top':  0,  'padding-bottom':  0,  'margin-top': '{{ offsetTop }}' }))
    
  ], { params: { offsetTop: '0' }}), 
  transition(':enter', [
    style({              display: 'grid', 'grid-template-rows': '0fr', 'padding-top':  0,  'padding-bottom':  0,  'margin-top': '{{ offsetTop }}' }),
    animate(200, style({ display: 'grid', 'grid-template-rows': '1fr', 'padding-top': '*', 'padding-bottom': '*', 'margin-top': 0,                }))
  ], { params: { offsetTop: '0' }} )
]);