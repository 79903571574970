import { Component, OnDestroy, OnInit        } from '@angular/core';
import { ActivatedRoute, Router              } from '@angular/router';
import { Location                            } from '@angular/common';
import { Observable, forkJoin, from, map, of } from 'rxjs';

import { AssignmentModel, DailyLocalExtendedModel } from '@shared/models';
import { ActivityReportLocal, Assignment, DailyLocalExtended, VacationRequestLocal } from '@shared/factories';
import { SessionStorageService, DBService, NotificationService, UserService        } from '@shared/services';
import { collapse } from '@shared/animations';

interface AssignmentSelectItem extends Assignment {
  localDailies?: number;
}

@Component({
  templateUrl: './assignment-select.component.html',
  host: { class: 'height-full du-flex-column du-flex-justify p-xl' },
  animations: [ collapse ]
})
export class AssignmentSelectComponent implements OnInit, OnDestroy {
  useCase:         string;
  search:          string;

  assignments:     AssignmentSelectItem[] = [];
  tempAssignment:  AssignmentSelectItem;

  animate:         boolean;
  closeMileageTip: boolean;
  closeDailiesTip: boolean;
  constructor(
    private location:              Location,
    private router:                Router,
    private route:                 ActivatedRoute,
    private dbService:             DBService,
    private sessionStorageService: SessionStorageService,
    private notificationService:   NotificationService,
    public  userService:           UserService,
  ) {}

  ngOnInit(): void {
    this.useCase = this.route.snapshot.data['useCase'];
    this.setHeader();
    this.notificationService.wait();
    forkJoin([
      this.useCase === 'vr' ? of(null) : this.loadAssignments(),
      this.useCase === 'ar' ? this.loadLocalDailies() : of(null)
    ]).subscribe(
      (res: Array<Assignment[] | DailyLocalExtended[]>) => {
        this.assignments = res[0] as Assignment[];

        let temp: any;
        if (this.useCase === 'vr') {
          temp            = this.sessionStorageService.temporaryVacationValue;
          let assignments = this.sessionStorageService.temporaryAssignmentsValue;
          if (assignments) this.assignments = assignments;
          else this.router.navigateByUrl('time-tracking/home'); 
        } else temp = this.sessionStorageService.temporaryReportValue;

        if (temp && temp.assignment) {
          let active = this.assignments.find(a => a.id === temp.assignment.id);
          if (active) this.tempAssignment = active;
        }

        if (res[1]?.length) this.parceLocalDaiies(res[1] as DailyLocalExtended[]);
        this.notificationService.close();
        setTimeout(() => this.animate = true, 200);
      }
    );
  }

  ngOnDestroy(): void {
    this.animate = false;
  }

  private loadAssignments(): Observable<Assignment[]> {
    return from(this.dbService.loadMultipleFromDB('assignments')).pipe(
      map((res: AssignmentModel[]) => res.map(a => new Assignment(a)).filter(a => 
        a.startsAt.getTime() <= new Date().getTime() && 
        a.endsAt.getTime()   >= new Date(new Date().getTime() - 7*7*24*60*60*1000).getTime() // ending not more then 7 weeks in the past
      ).sort((a, b) => a.startsAt.getTime() - b.startsAt.getTime()))
    );
  }

  private loadLocalDailies(): Observable<DailyLocalExtended[]> {
    return from(this.dbService.loadMultipleFromDB('localDailies')).pipe(
      map((res: DailyLocalExtendedModel[]) => res.map(d => new DailyLocalExtended(d)))
    );
  }

  private parceLocalDaiies(localDailies: DailyLocalExtended[]): void {
    localDailies.forEach(d => {
      let i = this.assignments.findIndex(a => a.id === d.assignment.id);
      if (!this.assignments[i].localDailies) this.assignments[i].localDailies = 0;
      ++this.assignments[i].localDailies;
    });
  }

  selectAssignment(assignment: Assignment): void {
    this.tempAssignment  = assignment;
    this.animate         = true;
    this.closeMileageTip = false;
    this.closeDailiesTip = false;
  }

  confirmEBS(): void {
    let report = this.sessionStorageService.temporaryReportValue;
    report = new ActivityReportLocal(Object.assign(report.toJSON(), { assignment: this.tempAssignment.toJSON() }));
    this.sessionStorageService.setTemporaryReport(report);
    this.router.navigate(['/time-tracking/ebs-details', this.tempAssignment.id, 'max']);
  }

  confirmAssignment(): void {
    if (this.useCase === 'vr') this.confirmAssignmentForVacation();
    else this.confirmAssignmentForReport();
    this.goNext();
  }

  private confirmAssignmentForVacation(): void {
    let vacation = this.sessionStorageService.temporaryVacationValue;
    if (!vacation.assignment || vacation.assignment.id !== this.tempAssignment.id) {
      vacation = new VacationRequestLocal(Object.assign(vacation.toJSON(), { assignment: this.tempAssignment.toJSON() }));
    }
    this.sessionStorageService.setTemporaryVacation(vacation);
  }

  private confirmAssignmentForReport(): void {
    let report = this.sessionStorageService.temporaryReportValue;
    if (report) {
      if     (!report.assignment) report = new ActivityReportLocal(Object.assign(report.toJSON(), { assignment: this.tempAssignment.toJSON() }));
      else if (report.assignment.id !== this.tempAssignment.id) {
        if (this.useCase === 'ar-t') report = new ActivityReportLocal(Object.assign(report.toJSON(), { assignment: this.tempAssignment.toJSON() }));
        else report = new ActivityReportLocal({ assignment: this.tempAssignment.toJSON() });
      }
    } else report = new ActivityReportLocal({ assignment: this.tempAssignment.toJSON() });
    this.sessionStorageService.setTemporaryReport(report);
  }

  private setHeader(): void {
    this.sessionStorageService.setHeaderControls({ left: [{ icon: 'arrow-big-left color-blue font-icon', callback: () => this.location.back() }] });
    if (this.useCase === 'ar' || this.useCase === 'ar-t') {
      this.sessionStorageService.setHeaderTitle('activityReport');
      this.sessionStorageService.setProgressBar(1, 6);
    } else if (this.useCase === 'pr' ) {
      this.sessionStorageService.setHeaderTitle('photoReport');
      this.sessionStorageService.setProgressBar(1, 4);
    } else if (this.useCase === 'vr' ) {
      this.sessionStorageService.setHeaderTitle('vacationRequest');
      this.sessionStorageService.setProgressBar(3, 5);
    }
    if (this.useCase !== 'ar-t' && !this.userService.checkTutorial(this.useCase)) this.sessionStorageService.pushDynamicComponent({
      component: 'TutorialScreen',
      props: { useCase: this.useCase }
    });
  }

  private goNext(): void {
    if (this.useCase === 'vr') this.router.navigateByUrl(`time-tracking/status-select-${this.useCase}`);
    else this.router.navigateByUrl(`time-tracking/week-select-${this.useCase}`);
  }

  assignmentReady(): boolean {
    return this.tempAssignment && this.tempAssignment.confirmed && (!this.tempAssignment.mileageData || this.tempAssignment.mileageData && this.userService.currentUserValue.mileageConfig);
  }

  mileageNotConfigured(): boolean {
    return this.tempAssignment && this.tempAssignment.confirmed && this.tempAssignment.mileageData && !this.userService.currentUserValue.mileageConfig;
  }

  openMileageConfig(): void {
    this.sessionStorageService.pushDynamicComponent({
      component: 'MileageConfigBox'
    });
  }

}
