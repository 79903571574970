<div class="du-flex-column du-flex-1">
  <templates-box *ngIf="useCase === 'ar'"></templates-box>
  <span class="p-xl text-center" *ngIf="!reports.length" translate="noSavedReports"></span>
  
  <div class="du-flex-column du-flex-1 gap-l p-xl overflow-auto">
    <preselect-report-item *ngFor="let report of reports"
                          [useCase]="useCase"
                          [report]="report"
                          (submitReport)="submitReport($event)"
                          (deleteReport)="deleteReport($event)">
    </preselect-report-item>
  </div>
</div>

<div class="du-flex-column du-flex-justify gap-m p-xl">
  <button class="width-full btn-primary btn-round" translate="newReport" (click)="callbackHandler()"></button>
</div>
