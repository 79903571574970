import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router     } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { from, of   } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import { VacationRequestLocal, VacationRequestOverview } from '@shared/factories';
import { MetaModel, VacationRequestOverviewModel       } from '@shared/models';
import { DBService             } from './db.service'
import { QueryCollectorService } from './query-collector.service';
import { SessionStorageService } from './session-storage.service';

import { environment } from 'environments/environment';

interface VacationRequestResponseModel {
  vacation_request: VacationRequestOverviewModel;
}

interface VacationRequestsResponseModel {
  vacation_requests: VacationRequestOverviewModel[];
  meta:              MetaModel;
}

@Injectable({
  providedIn: 'root'
})
export class VacationRequestsService {
  private VACATION_REQUESTS_API:   string = `${environment.apiUrl}/api/mobile/v3/vacation_requests`;
  private VR_DB_TABLE:             string = 'vacationRequests';
  private LOCAL_VR_DB_TABLE:       string = 'localVacations';
  private PENDING_UPLOAD_DB_TABLE: string = 'pendingUpload';

  constructor(
    private http:                  HttpClient,
    private router:                Router,
    private dbService:             DBService,
    private sessionStorageService: SessionStorageService,
    private queryCollectorService: QueryCollectorService,
  ) { }

  loadVacations(page: number = 1): Observable<VacationRequestOverview[]> {
    let offline = this.sessionStorageService.offlineModeValue;
    if (offline) return this.loadVacationsFromDB(page);
    else return this.requestVacations(page);
  }

  private loadVacationsFromDB(page: number = 1): Observable<VacationRequestOverview[]> {
    let { filter, sort } = this.queryCollectorService.parceVacationRequestDBQuery(page);
    return from(this.dbService.loadMultipleFromDBPaginated(this.VR_DB_TABLE, page, filter, sort)).pipe(
      take(1),
      map(res => {
        this.sessionStorageService.setPaging(res.paging);
        return res.data.map((v: VacationRequestOverviewModel) => new VacationRequestOverview(v));
      })
    );
  }

  private requestVacations(page: number = 1): Observable<VacationRequestOverview[]> {
    return this.http.get<VacationRequestsResponseModel>(`${this.VACATION_REQUESTS_API}${this.queryCollectorService.getVacationRequestsQuery(page)}`).pipe(
      take(1),
      map(res => {
        this.sessionStorageService.setPaging(res.meta.paging);
        return res.vacation_requests.map(v => new VacationRequestOverview(v));
      }),
      tap(res => this.dbService.saveMultipleToDB(this.VR_DB_TABLE, res))
    );
  }

  submitVacationRequest(request: VacationRequestLocal): Observable<VacationRequestOverview | VacationRequestLocal> {
    let offline = this.sessionStorageService.offlineModeValue;
    if (offline) return this.pushReportToPendingList(request);
    else return this.postLocalVacationRequest(request);
  }

  submitPendingVacationRequest(request: VacationRequestLocal): Observable<VacationRequestOverview> {
    let offline = this.sessionStorageService.offlineModeValue;
    if (!offline) return this.postPendingVacationRequest(request);
    return of(null);
  }

  private pushReportToPendingList(request: VacationRequestLocal): Observable<VacationRequestLocal> {
    return from(this.dbService.saveOneToDB(this.PENDING_UPLOAD_DB_TABLE, Object.assign(request, { useCase: 'vr', notSynced: true }))).pipe(
      tap(() => { if (request.id) from(this.dbService.deleteOneFromDB(this.LOCAL_VR_DB_TABLE, request.id));    }),
      tap(() => this.router.navigate(['time-tracking/info'], { queryParams: { type: 'offline', useCase: 'vr' }})),
      map(vr => request)
    );
  }

  private postVacationRequest(request: VacationRequestLocal): Observable<VacationRequestOverview> {
    let data = { vacation_request: request.toSubmitJSON() };
    return this.http.post<VacationRequestResponseModel>(this.VACATION_REQUESTS_API, data).pipe(
      map(res => new VacationRequestOverview(res.vacation_request))
    );
  }

  private postLocalVacationRequest(request: VacationRequestLocal): Observable<VacationRequestOverview> {
    return this.postVacationRequest(request).pipe(
      tap(()  => { if (request.id) from(this.dbService.deleteOneFromDB(this.LOCAL_VR_DB_TABLE, request.id));  }),
      tap(()  => this.router.navigate(['time-tracking/info'], { queryParams: { type: 'submit', useCase: 'vr' }}),
          err => this.router.navigate(['time-tracking/info'], { queryParams: { type: 'failed', useCase: 'vr' }})
      )
    );
  }

  private postPendingVacationRequest(request: VacationRequestLocal): Observable<VacationRequestOverview> {
    return this.postVacationRequest(request).pipe(
      tap(() => { if (request.notSynced) from(this.dbService.deleteOneFromDB(this.PENDING_UPLOAD_DB_TABLE, request.id)); }),
    );
  }

  saveVacationRequest(request: VacationRequestLocal): Observable<VacationRequestLocal> {
    return from(this.dbService.saveOneToDB(this.LOCAL_VR_DB_TABLE, request)).pipe(
      tap(() => this.router.navigate(['time-tracking/info'], { queryParams: { type: 'save', useCase: 'vr' }})),
      map(vr => request)
    );
  }

}
