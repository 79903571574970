<ul class="du-flex-column gap-m p-xl overflow-auto">
  <li class="du-flex-justify du-flex-align-start gap-xl p-m shadow" *ngFor="let ebs of ebsList" [routerLink]="['/time-tracking/ebs-details', ebs.assignmentId, ebs.version]">
    <div class="du-flex-column du-flex-1 gap-m">
      <span class="lh-l">{{ ebs.title }}</span>
      <div class="du-flex-justify">
        <span class="du-flex-align gap-xxs font-s">
          <span translate="version"></span>
          <span>{{ ebs.version }}</span>
        </span>
      </div>
    </div>
    <span class="bg-orange circle-s" *ngIf="!ebs.confirmedAt"></span>
  </li>
</ul>

<div class="du-flex-column sticky" [style.height]="collapseArchive ? '50%' : '65px'" *ngIf="archiveEbsList && archiveEbsList.length">
  <div class="du-flex-justify du-flex-align p-xl bt-grey bb-grey bg-card-grey" (click)="collapseArchive = !collapseArchive">
    <span class="bold" translate="archive"></span>
    <img [class.rotate]="collapseArchive" src="assets/svg/arrow-down-blue.svg">
  </div>
  <ul class="du-flex-column gap-m p-xl overflow-auto" *ngIf="collapseArchive">
    <li class="du-flex-column gap-m p-m shadow" *ngFor="let ebs of archiveEbsList" [routerLink]="['/time-tracking/ebs-details', ebs.id]">
      <span class="lh-l">{{ ebs.title }}</span>
      <div class="du-flex-justify">
        <span class="du-flex-align gap-xxs font-s">
          <span translate="version"></span>
          <span>{{ ebs.version }}</span>
        </span>
      </div>
    </li>
  </ul>
</div>
