<div class="du-flex-align du-flex-justify pl-m pt-m pr-m pb-s bb-grey">
  <div class="du-flex-align gap-xs">
    <div class="du-flex-center circle-xl"
        [class.bg-green]="getOverviewVacation().internalReview === 'approved'"
        [class.bg-orange]="!getOverviewVacation().internalReview"
        [class.bg-red]="getOverviewVacation().internalReview === 'rejected'">
      <span *ngIf="getOverviewVacation().internalReview === 'approved'" class="du-icon-circle-tick  du-flex-center color-white"></span>
      <span *ngIf="!getOverviewVacation().internalReview"               class="du-icon-circle-dots  du-flex-center color-white"></span>
      <span *ngIf="getOverviewVacation().internalReview === 'rejected'" class="du-icon-circle-cross du-flex-center color-white"></span>
    </div>
    <span class="bold du-flex-column gap-xxs">
      <span class="du-flex-align gap-xxs">
        <span translate="cw"></span>
        <span>{{  vacation.startsOn | date: 'ww' }}</span>
      </span>
      <span>{{ vacation.startsOn | date: 'dd.MM.YYYY' }} - {{ vacation.endsOn | date: 'dd.MM.YYYY' }}</span>
    </span>
  </div>
  <span *ngIf="getLocalVacation().notSynced" class="du-flex-align gap-xs pl-xs pr-xs pt-xxs pb-xxs b-radius bg-light-orange color-orange">
    <span class="du-icon-offline font-l"></span>
    <span class="font-s" translate="notSynced"></span>
  </span>
  <span class="du-flex-align gap-xs p-s font-s bg-green-light b-radius" *ngIf="extended && getOverviewVacation().customerSignatureUrl">
    <img  src="assets/svg/check-green.svg">
    <span translate="signed"></span>
  </span>
  <img *ngIf="extended" src="assets/svg/close.svg">
</div>

<div class="pt-xs pl-m pr-m pb-m du-flex gap-s">
  <div class="du-flex-1 du-flex-column gap-xxs">
    <span class="font-s" translate="assignment"></span>
    <span *ngIf=" vacation.assignment" class="lh-l bold">{{ vacation.assignment.title }}</span>
    <span *ngIf="!vacation.assignment" class="lh-l bold">Kein Einsatz vorliegend</span>
  </div>
  <div class="du-flex-column gap-xxs width-100">
    <span class="font-s" translate="vacationType"></span>
    <span class="lh-l bold">{{ vacation.leaveType | translate }}</span>
  </div>
</div>