import { ZipCodeModel } from "@shared/models";

export class ZipCode {
  name:        string;
  countryIso: string;
  stateIso:   string;
  zipCode:    string;
  constructor(data: ZipCodeModel) {
    this.name       = data.name;
    this.countryIso = data.country_iso;
    this.stateIso   = data.state_iso;
    this.zipCode    = data.zip_code;
  }

  toJSON(): ZipCodeModel {
    return {
      name:        this.name       ? this.name       : null,
      country_iso: this.countryIso ? this.countryIso : null,
      state_iso:   this.stateIso   ? this.stateIso   : null,
      zip_code:    this.zipCode    ? this.zipCode    : null
    };
  }

}
