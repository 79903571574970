import { PhotoDocumentLocalModel, PhotoDocumentModel, PhotoDocumentSubmitModel } from "@shared/models";
import { DocumentType } from "@shared/factories";

export class PhotoDocumentLocal {
  id:              number;
  userId:          number;
  docType:         DocumentType;
  attachment:      string;
  messageExternal: string;

  useCase:         string;
  notSynced:       boolean;
  constructor(data: PhotoDocumentLocalModel) {
    this.id              = data.id;
    this.userId          = data.userId;
    this.docType         = data.docType ? new DocumentType(data.docType) : null;
    this.attachment      = data.attachment;
    this.messageExternal = data.messageExternal;

    this.useCase         = data.useCase   ? data.useCase   : null;
    this.notSynced       = data.notSynced ? data.notSynced : null;
  }

  toJSON(): PhotoDocumentLocalModel {
    let obj: PhotoDocumentLocalModel = {
      userId:          this.userId          ? this.userId           : null,
      docType:         this.docType         ? this.docType.toJSON() : null,
      attachment:      this.attachment      ? this.attachment       : null,
      messageExternal: this.messageExternal ? this.messageExternal  : null,

      useCase:         this.useCase         ? this.useCase          : null,
      notSynced:       this.notSynced       ? this.notSynced        : null,
    };
    if (this.id) obj = Object.assign(obj, { id: this.id });
    return obj;
  }

  toSubmitJSON(): PhotoDocumentSubmitModel {
    return {
      subject:                   this.docType         ? this.docType.name    : null,
      attachment:                this.attachment      ? this.attachment      : null,
      message_external_employee: this.messageExternal ? this.messageExternal : null,
    };
  }

}

export class PhotoDocument {
  id:              number;
  userId:          number;
  createdAt:       Date;

  readAt:          Date;
  state:           string;

  subject:         string;

  attachmentUrl:   string;
  attachment:      string;
  
  messageExternal: string;
  messageInternal: string;
  constructor(data: PhotoDocumentModel) {
    this.id              = data.id;
    this.userId          = data.userId;
    this.createdAt       = this.parceDate(data.created_at);

    this.readAt          = this.parceDate(data.read_at);
    this.state           = data.state;

    this.subject         = data.subject_corrected || data.subject;

    this.attachmentUrl   = data.attachment_url;
    this.attachment      = data.attachment;

    this.messageExternal = data.message_external_employee;
    this.messageInternal = data.comment_internal_employee;
  }

  private parceDate(date: any) {
    return date ? date instanceof Date ? date : new Date(date) : null;
  }

  toJSON(): PhotoDocumentModel {
    return {
      id:                        this.id              ? this.id                      : null,
      userId:                    this.userId          ? this.userId                  : null,
      created_at:                this.createdAt       ? this.createdAt.toISOString() : null,

      read_at:                   this.readAt          ? this.readAt.toISOString()    : null,
      state:                     this.state           ? this.state                   : null,

      subject:                   this.subject         ? this.subject                 : null,

      attachment_url:            this.attachmentUrl   ? this.attachmentUrl           : null,
      attachment:                this.attachment      ? this.attachment              : null,

      message_external_employee: this.messageExternal ? this.messageExternal         : null,
      comment_internal_employee: this.messageInternal ? this.messageInternal         : null
    };
  }

}
