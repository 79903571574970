<div class="du-flex-column du-flex-1 overflow-auto">
  <div class="relative ml-xl mr-xl" *ngIf="banner && banner.image">
    <img class="b-radius width-full" [src]="banner.image" (load)="notificationService.close()">
  </div>  
  <div class="du-flex-column gap-l p-xl bb-grey">
    <div class="du-flex-column gap-m">
      <span class="font-l bold" translate="enterContactInformation"></span>
      <span class="lh-l" translate="youWillReceiveBonus"></span>
    </div>
    <div class="du-flex-column gap-xl" [formGroup]="friendForm">
      <div class="du-flex-column gap-m">
        <span class="bold" translate="salutation"></span>
        <select formControlName="salutation">
          <option value="mr"      translate="mr"></option>
          <option value="mrs"     translate="mrs"></option>
          <option value="diverse" translate="diverse"></option>
        </select>
      </div>

      <div class="du-flex-column gap-m">
        <span class="bold" translate="firstName"></span>
        <input type="text" formControlName="firstName">
      </div>

      <div class="du-flex-column gap-m">
        <span class="bold" translate="lastName"></span>
        <input type="text"formControlName="lastName" >
      </div>

      <div class="du-flex-column gap-m">
        <span class="bold" translate="mobileNumber"></span>
        <input type="tel" formControlName="mobileNumber">
      </div>

      <div class="du-flex-column gap-m">
        <span class="bold" translate="email"></span>
        <input type="email" formControlName="email">
      </div>

      <div class="bg-light-orange du-flex-align-start p-s gap-xs">
        <img src="assets/svg/warning.svg">
        <span class="lh-l" translate="ensureContactIsHappy"></span>
      </div>
    </div>
  </div>

  <div class="du-flex-column gap-s p-xl">
    <span class="bold" translate="requirement"></span>
    <span class="lh-l" translate="sendYourContactDetails"></span>
  </div>
</div>

<div class="p-xl">
  <button class="width-full btn-primary btn-round" translate="submit" [disabled]="friendForm.invalid" (click)="referAFriend()"></button>
</div>


