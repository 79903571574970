<div class="du-flex-justify du-flex-align bb-grey">
  <span class="absolute left-70 right-70 du-flex-center color-grey bold" translate="customerSignature"></span>
  <img  class="pl-xl pr-xl pt-m pb-m mla" src="assets/svg/close.svg" (click)="close()">
</div>

<div class="du-flex-column gap-m pt-m pl-xl pr-xl pb-xl bb-grey">
  <span class="bold" translate="signHere"></span>
  <div class="du-flex-align gap-xs p-m b-grey" (click)="openSignaturePad()" *ngIf="!signature">
    <img src="assets/svg/signature.svg">
    <span translate="customerSignature"></span>
  </div>
  <img class="width-full height-200 b-grey object-contain" [src]="signature" *ngIf="signature"  (click)="openSignaturePad()">
</div>

<div class="du-flex-column gap-m pt-m pl-xl pr-xl pb-xl bb-grey">
  <span class="bold" translate="customerName"></span>
  <input type="text" [(ngModel)]="signer">

  <button class="btn-primary btn-round mt-m" translate="done" (click)="submit()" [disabled]="!signature || !signer"></button>
</div>
