import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
  selector:      'pinch-zoom-image',
  templateUrl: './pinch-zoom-image.component.html',
  host: { class: 'b-grey overflow relative pinch-zoom-container' }
})
export class PinchZoomImageComponent {
  @Input() image:       any;
  @Input() currentPage: number;
  @Input() totalPages:  number;
  @Output() load = new EventEmitter<void>;
  
  MIN_SCALE:      number = 1;
  MAX_SCALE:      number = 16;

  imgWidth:       number;
  imgHeight:      number;
  curWidth:       number;
  curHeight:      number;
  viewportWidth:  number;
  viewportHeight: number;
  scale:          number;
  lastScale:      number;

  x:              number = 0;
  y:              number = 0;
  lastX:          number = 0;
  lastY:          number = 0;

  pinchCenter:       any;
  pinchCenterOffset: any;

  container:   any;
  img:         any;

  @HostListener('pan',       ['$event']) pan( e: any)      { this.translate(e.deltaX, e.deltaY); }
  @HostListener('panend',    ['$event']) panend(e: any)    { this.updateLastPos();               }
  @HostListener('pinch',     ['$event']) pinch(e: any)     { this.pinchHandler(e);               }
  @HostListener('doubletap', ['$event']) doubletap(e: any) { this.doubleTapHandler(e);           }
  @HostListener('pinchend',  ['$event']) pinchend(e: any)  { this.pinchEndHandler();             }

  constructor () { }

  onLoad() {
    this.img = document.getElementById(`pinch-zoom-image-id-${this.currentPage}`);
    this.container = this.img.parentElement;

    this.imgWidth       = this.img.width;
    this.imgHeight      = this.img.height;
    this.viewportWidth  = this.img.offsetWidth;
    this.scale          = this.viewportWidth / this.imgWidth;
    this.lastScale      = this.scale;
    this.viewportHeight = this.img.parentElement.offsetHeight;
    this.curWidth       = this.imgWidth  * this.scale;
    this.curHeight      = this.imgHeight * this.scale;

    setTimeout(() => this.load.emit());
  }

  private absolutePosition(el: any): any {
    let x = 0, y = 0;

    while (el !== null) {
      x += el.offsetLeft;
      y += el.offsetTop;
      el = el.offsetParent;
    }

    return { x: x, y: y };
  }

  private restrictScale(scale: number): number {
    if      (scale < this.MIN_SCALE) scale = this.MIN_SCALE;
    else if (scale > this.MAX_SCALE) scale = this.MAX_SCALE;
    return scale;
  }

  private restrictRawPos(pos: number, viewportDim: number, imgDim: number): number {
    if (pos < viewportDim / this.scale - imgDim) pos = viewportDim / this.scale - imgDim;
    else if (pos > 0) pos = 0;
    return pos;
  }

  private updateLastPos(): void {
    this.lastX = this.x;
    this.lastY = this.y;
  }

  private translate(deltaX: number, deltaY: number): void {
    let newX = this.restrictRawPos(this.lastX + deltaX / this.scale, Math.min(this.viewportWidth, this.curWidth), this.imgWidth);
    this.x = newX;
    this.img.style.marginLeft = Math.ceil(newX * this.scale) + 'px';

    let newY = this.restrictRawPos(this.lastY + deltaY / this.scale, Math.min(this.viewportHeight, this.curHeight), this.imgHeight);
    this.y = newY;
    this.img.style.marginTop = Math.ceil(newY * this.scale) + 'px';
  }

  private zoom(scaleBy: number): void {
    this.scale = this.restrictScale(this.lastScale * scaleBy);

    this.curWidth  = this.imgWidth  * this.scale;
    this.curHeight = this.imgHeight * this.scale;

    this.img.style.width  = Math.ceil(this.curWidth)  + 'px';
    this.img.style.height = Math.ceil(this.curHeight) + 'px';

    this.translate(0, 0);
  }

  private rawCenter(e: any): any {
    let pos = this.absolutePosition(this.container);

    let scrollLeft = window.pageXOffset ? window.pageXOffset : document.body.scrollLeft;
    let scrollTop  = window.pageYOffset ? window.pageYOffset : document.body.scrollTop;

    let zoomX = -this.x + (e.center.x - pos.x + scrollLeft) / this.scale;
    let zoomY = -this.y + (e.center.y - pos.y + scrollTop)  / this.scale;

    return { x: zoomX, y: zoomY };
  }

  private updateLastScale() {
    this.lastScale = this.scale;
  }

  private zoomAround(scaleBy: number, rawZoomX: number, rawZoomY: number, doNotUpdateLast: boolean = null): void {
    this.zoom(scaleBy);

    let rawCenterX = -this.x + Math.min(this.viewportWidth,  this.curWidth)  / 2 / this.scale;
    let rawCenterY = -this.y + Math.min(this.viewportHeight, this.curHeight) / 2 / this.scale;

    let deltaX = (rawCenterX - rawZoomX) * this.scale;
    let deltaY = (rawCenterY - rawZoomY) * this.scale;

    this.translate(deltaX, deltaY);

    if (!doNotUpdateLast) {
      this.updateLastScale();
      this.updateLastPos();
    }
  }

  private zoomCenter(scaleBy: number): void {
    let zoomX = -this.x + Math.min(this.viewportWidth,  this.curWidth)  / 2 / this.scale;
    let zoomY = -this.y + Math.min(this.viewportHeight, this.curHeight) / 2 / this.scale;

    this.zoomAround(scaleBy, zoomX, zoomY);
  }

  zoomIn() {
    this.zoomCenter(2);
  }

  zoomOut() {
    this.zoomCenter(1/2);
  }

  resetZoom() {
    this.zoomCenter(1 / this.lastScale);
    this.x     = 0;
    this.y     = 0;
    this.lastX = 0;
    this.lastY = 0;
    this.pinchCenter = null;
    this.lastScale   = null;
  }

  private pinchHandler(e: any): void {
    if (this.pinchCenter === null) {
      this.pinchCenter = this.rawCenter(e);
      let offsetX = this.pinchCenter.x * this.scale - (-this.x * this.scale + Math.min(this.viewportWidth,  this.curWidth)  / 2);
      let offsetY = this.pinchCenter.y * this.scale - (-this.y * this.scale + Math.min(this.viewportHeight, this.curHeight) / 2);
      this.pinchCenterOffset = { x: offsetX, y: offsetY };
    }

    let newScale = this.restrictScale(this.scale * e.scale);
    let zoomX = this.pinchCenter.x * newScale - this.pinchCenterOffset.x;
    let zoomY = this.pinchCenter.y * newScale - this.pinchCenterOffset.y;
    let zoomCenter = { x: zoomX / newScale, y: zoomY / newScale };

    this.zoomAround(e.scale, zoomCenter.x, zoomCenter.y, true);
  }

  private doubleTapHandler(e: any): void {
    let c = this.rawCenter(e);
    this.zoomAround(2, c.x, c.y);
  }

  private pinchEndHandler(): void {
    this.updateLastScale();
    this.updateLastPos();
    this.pinchCenter = null;
  }

}