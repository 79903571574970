<div class="du-flex-justify du-flex-align pl-xl bb-grey">
  <span translate="changeLanguage"></span>
  <img class="p-l" src="assets/svg/close.svg" (click)="close()">
</div>
<ul class="du-flex-column" *ngIf="langList && langList.length">
  <li *ngFor="let lang of langList" class="du-flex-justify du-flex-align gap-m pl-xl pr-xl pt-xs pb-xs bb-grey hover-active" (click)="changeLang(lang.value)">
    <div class="du-flex-align gap-m">
      <img class="circle-35" [src]="'assets/svg/flags/' + lang.value + '.svg'">
      <span>{{ lang.label }}</span>
    </div>
    <img *ngIf="translateService.currentLang === lang.value" class="width-m" src="assets/svg/tick.svg">
  </li>
</ul>
