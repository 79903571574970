<div class="du-flex-column du-flex-center height-full gap-m">
  <img class="width-100" src="assets/svg/tick.svg"            *ngIf="type === 'submit'">
  <img class="width-100" src="assets/svg/edited-ar-blue.svg"  *ngIf="type === 'save'">
  <img class="width-100" src="assets/svg/circle-excl-red.svg" *ngIf="type === 'failure'">
  <img class="width-100" src="assets/svg/offline-orange.svg"  *ngIf="type === 'offline'">
  <h2 class="font-xl mt40"
     [class.color-blue]="type === 'submit' || type === 'save'"
     [class.color-red]="type === 'failure'"
     [class.color-orange]="type === 'offline'">{{ title   | translate }}!</h2>
  <h3 class="color-primary ml20 mr20 text-center lh-l">{{ message | translate }}</h3>
</div>

<div class="du-flex-column gap-l mt20"> 
  <button class="width-full btn-round" *ngFor="let button of buttons"
         [class.btn-primary]="!button.secondary"
         [class.btn-secondary]="button.secondary"
         (click)="button.callback()">
    {{ button.label | translate }}
  </button>
</div>
