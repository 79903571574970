import { Component, Input } from '@angular/core';

import { SalaryReport } from '@shared/factories';
import { NotificationService, SalaryReportsService } from '@shared/services';

@Component({
  selector:      'salary-overview-item',
  templateUrl: './salary-overview-item.component.html',
  host: { class: 'shadow' }
})
export class SalaryOverviewItemComponent {
  @Input() report: SalaryReport;

  constructor(
    private salaryReportsService: SalaryReportsService,
    private notificationService:  NotificationService,
  ) {}

  getDocType(type: any) {

  }

  saveReport(report: SalaryReport): void {
    this.notificationService.wait();
    this.salaryReportsService.getSalaryReportsById(report.id).subscribe(res => {
      var a = document.createElement("a");
      a.href = res.base64pdf;
      a.download = this.getFileNameWIthExtension(report.filename);
      a.click();
      this.notificationService.close();
    });
  }

  private getFileNameWIthExtension(fileName: string): string {
    if (fileName.toLowerCase().includes('.pdf')) return fileName;
    else return fileName + '.pdf';
  }
}
