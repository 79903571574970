import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router     } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { forkJoin, from, of } from 'rxjs';
import { defaultIfEmpty, map, take, tap } from 'rxjs/operators';

import { ActivityReportLocal, ActivityReportOverview } from '@shared/factories';
import { MetaModel, ActivityReportOverviewModel      } from '@shared/models';
import { DBService             } from './db.service'
import { QueryCollectorService } from './query-collector.service';
import { SessionStorageService } from './session-storage.service';

import { environment } from 'environments/environment';

interface ActivityReportResponse {
  working_period:  ActivityReportOverviewModel;
}

interface ActivityReportsResponse {
  meta:            MetaModel;
  working_periods: ActivityReportOverviewModel[];
}

@Injectable({
  providedIn: 'root'
})
export class ActivityReportsService {
  private readonly WORKING_PERIODS_API = `${environment.apiUrl}/api/mobile/v3/working_periods`;
  private WP_DB_TABLE:             string = 'activityReports';
  private LOCAL_WP_DB_TABLE:       string = 'localReports';
  private LOCAL_DR_DB_TABLE:       string = 'localDailies';
  private PHOTO_WP_DB_TABLE:       string = 'photoReports';
  private PENDING_UPLOAD_DB_TABLE: string = 'pendingUpload';

  constructor(
    private http:                  HttpClient,
    private router:                Router,
    private dbService:             DBService,
    private sessionStorageService: SessionStorageService,
    private queryCollectorService: QueryCollectorService,
  ) { }

  loadReports(page: number = 1): Observable<ActivityReportOverview[]> {
    let offline = this.sessionStorageService.offlineModeValue;
    if (offline) return this.loadReportsFromDB(page);
    else return this.requestReports(page);
  }

  private loadReportsFromDB(page: number = 1): Observable<ActivityReportOverview[]> {
    let { filter, sort } = this.queryCollectorService.parceActivityReportDBQuery(page);
    return from(this.dbService.loadMultipleFromDBPaginated(this.WP_DB_TABLE, page, filter, sort)).pipe(
      take(1),
      map(res => {
        this.sessionStorageService.setPaging(res.paging);
        return res.data.map((v: ActivityReportOverviewModel) => new ActivityReportOverview(v));
      })
    );
  }

  private requestReports(page: number = 1): Observable<ActivityReportOverview[]> {
    return this.http.get<ActivityReportsResponse>(`${this.WORKING_PERIODS_API}${this.queryCollectorService.getActivityReportsQuery(page)}`).pipe(
      take(1),
      map(res => {
        this.sessionStorageService.setPaging(res.meta.paging);
        return res.working_periods.map(w => new ActivityReportOverview(w));
      }),
      tap(res => this.dbService.saveMultipleToDB(this.WP_DB_TABLE, res))
    );
  }

  submitActivityReport(report: ActivityReportLocal): Observable<ActivityReportOverview | ActivityReportLocal> {
    let offline = this.sessionStorageService.offlineModeValue;
    if (offline) return this.pushReportToPendingList(report);
    else return this.postLocalActivityReport(report);
  }

  submitPendingActivityReport(report: ActivityReportLocal): Observable<ActivityReportOverview> {
    let offline = this.sessionStorageService.offlineModeValue;
    if (!offline) return this.postPendingActivityReport(report);
    return of(null);
  }

  private pushReportToPendingList(report: ActivityReportLocal): Observable<ActivityReportLocal> {
    return from(this.dbService.saveOneToDB(this.PENDING_UPLOAD_DB_TABLE, Object.assign(report, { useCase: 'ar', notSynced: true }))).pipe(
      tap(() => forkJoin(report.activeDailyReports.filter(d => d.id).map(d => this.dbService.deleteOneFromDB(this.LOCAL_DR_DB_TABLE, d.id))).pipe(defaultIfEmpty(null))),
      tap(() => { if (report.id) from(this.dbService.deleteOneFromDB(this.LOCAL_WP_DB_TABLE, report.id));      }),
      tap(() => this.router.navigate(['time-tracking/info'], { queryParams: { type: 'offline', useCase: 'ar' }})),
      map(vr => report)
    );
  }

  private postActivityReport(report: ActivityReportLocal): Observable<ActivityReportOverview> {
    let data = { working_period: report.toSubmitJSON() };
    return this.http.post<ActivityReportResponse>(this.WORKING_PERIODS_API, data).pipe(
      map(res => new ActivityReportOverview(res.working_period))
    );
  }

  private postLocalActivityReport(report: ActivityReportLocal): Observable<ActivityReportOverview> {
    return this.postActivityReport(report).pipe(
      tap(()  => forkJoin(report.activeDailyReports.filter(d => d.id).map(d => this.dbService.deleteOneFromDB(this.LOCAL_DR_DB_TABLE, d.id))).pipe(defaultIfEmpty(null))),
      tap(()  => { if (report.id) from(this.dbService.deleteOneFromDB(this.LOCAL_WP_DB_TABLE, report.id));  }),
      tap(()  => this.router.navigate(['time-tracking/info'], { queryParams: { type: 'submit', useCase: 'ar' }}),
          err => this.router.navigate(['time-tracking/info'], { queryParams: { type: 'failed', useCase: 'ar' }})
      )
    );
  }

  private postPendingActivityReport(report: ActivityReportLocal): Observable<ActivityReportOverview> {
    return this.postActivityReport(report).pipe(
      tap(() => { if (report.notSynced) from(this.dbService.deleteOneFromDB(this.PENDING_UPLOAD_DB_TABLE, report.id)); }),
    );
  }

  saveActivityReport(report: ActivityReportLocal): Observable<ActivityReportLocal> {
    return from(this.dbService.saveOneToDB(this.LOCAL_WP_DB_TABLE, report)).pipe(
      tap(() => forkJoin(report.activeDailyReports.filter(d => d.id).map(d => this.dbService.deleteOneFromDB(this.LOCAL_DR_DB_TABLE, d.id))).pipe(defaultIfEmpty(null))),
      tap(() => this.router.navigate(['time-tracking/info'], { queryParams: { type: 'save', useCase: 'ar' }})),
      map(vr => report)
    );
  }

  savePhotoReport(report: ActivityReportLocal): Observable<ActivityReportLocal> {
    return from(this.dbService.saveOneToDB(this.PHOTO_WP_DB_TABLE, report)).pipe(
      tap(() => this.router.navigate(['time-tracking/info'], { queryParams: { type: 'save', useCase: 'ar' }})),
      map(vr => report)
    );
  }

}
