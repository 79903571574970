import { LOCALE_ID, NgModule } from '@angular/core';
import { registerLocaleData  } from '@angular/common';
import { BrowserModule, HammerModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';

import { BrowserAnimationsModule                         } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';

import { TranslateModule, TranslateLoader, MissingTranslationHandler } from '@ngx-translate/core';
import { TranslateHttpLoader                                         } from '@ngx-translate/http-loader';
// import { DYNAMIC_ENVIRONMENT                                         } from 'ngx-http-env';

// import { environment } from '../environments/environment';

import { SharedComponentsModule } from './shared/shared.components.module';
import { AppRoutingModule       } from './app-routing.module';
import { AppComponent           } from './app.component';

import { JwtInterceptor } from '@shared/interceptors';
import { BlankComponent } from './layout/blank/blank.component';
import { FullComponent  } from './layout/full/full.component';

import {
  SidebarComponent,
  OverflowComponent,
  NotificationComponent,
  HeaderComponent,
  ChipComponent,
  OfflineNoteComponent,
  BlockerComponent
} from '@shared/shared';
import { ComponentLoaderComponent } from '@shared/components/dynamic';

import {
  AuthService,
  MissingTranslationService, 
  UserService,
  NotificationService,
  SessionStorageService, 
  ActivationService,
  WeeksService,
  HolidaysService,
  ActivityReportsService
} from '@shared/services';

import localeDe from '@angular/common/locales/de';
registerLocaleData(localeDe);

export function HttpLoaderFactory(http: HttpClient): TranslateLoader  {
  return new TranslateHttpLoader(http, './assets/locale/', '.json');
}

class MyHammerConfig extends HammerGestureConfig  {
  buildHammer(element: HTMLElement): any {
    let mc = new Hammer(element, { domEvents: true});
    mc.get('pinch').set({ enable: true });
    return mc;
  }
}

@NgModule({
  declarations: [
    AppComponent,
    
    BlankComponent,
    FullComponent,
    HeaderComponent,
    SidebarComponent,
    OverflowComponent,

    NotificationComponent,
    ComponentLoaderComponent,
    ChipComponent,
    OfflineNoteComponent,
    BlockerComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedComponentsModule,
    AppRoutingModule,
    HttpClientModule,
    HammerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MissingTranslationService },
      useDefaultLang: false,
    })
  ],
  providers: [
    HttpClient,
    { provide: HTTP_INTERCEPTORS,     useClass: JwtInterceptor, multi: true },
    { provide: LOCALE_ID,             useValue: "de-DE"                     },
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig              },
    ActivationService,
    WeeksService,
    HolidaysService,
    ActivityReportsService,
    
    AuthService,
    UserService,
    NotificationService,
    SessionStorageService
    /*
      Dynamic Environment:
      
      For libraries that require the environment configuration at startup:
      {
        provide: LibraryConfig,
        useFactory: (): LibraryConfig => environment.libraryConfig,
        deps: [DYNAMIC_ENVIRONMENT],
      }
    */
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
