import { Component, OnInit } from '@angular/core';
import { fadeIn } from '@shared/animations';
import { SessionStorageService } from '@shared/services';

@Component({
  selector:      'overflow',
  templateUrl: './overflow.component.html',
  animations: [ fadeIn ]
})
export class OverflowComponent implements OnInit {
  stack: string[] = [];
  constructor( private sessionStorageService: SessionStorageService ) {}

  ngOnInit(): void {
    this.sessionStorageService.overflowStack.subscribe(stack => this.stack = stack ? [...stack] : this.stack);
  }

  overflowClose(useCase: string): void {
    switch (useCase) {
      case 'Sort':
      case 'Filter':
      case 'Sidebar':
      case 'LanguageList':
      case 'TutorialsList':
      case 'TutorialScreen':
      case 'VacationDetails':
        this.sessionStorageService.popDynamicComponent(useCase);
        break;
      default:
        break;
    }
  }

}
