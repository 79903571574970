<div class="du-flex-column du-flex-1 overflow-auto">
  <div class="relative ml-xl mr-xl mt-xl" *ngIf="banner && banner.image" (click)="openBannerUrl()">
    <img class="b-radius width-full" [src]="banner.image" (load)="notificationService.close()">
  </div>  
  <div class="du-flex-column gap-m pl-xl pr-xl pt-xl pb-s bb-grey">
    <span class="font-l bold" translate="youImportantToUs"></span>
    <div class="du-flex-column">
      <span class="lh-l" translate="specialThank"></span>
      <span class="lh-l" translate="exclusiveOffers"></span>
    </div>
  </div>
  <div class="du-flex-column gap-m p-xl">
    <span class="font-l bold" translate="secureBenefits"></span>
    <span class="lh-l" translate="logOnToTheWebsite"></span>
    <span class="lh-l" translate="checkYourEmail"></span>
  </div>
</div>

<div class="du-flex-column gap-xxl pt-m pl-xl pr-xl pb-xl bt-grey">
  <div class="du-flex-column gap-s">
    <span class="font-l bold" translate="actionCode"></span>
    <div class="du-flex-justify du-flex-align p-m b-grey" (click)="copyToClipboard()">
      <span class="color-blue">{{ promoCode }}</span>
      <img src="assets/svg/copy-blue.svg">
  </div>
  </div>
  <button class="width-full btn-primary btn-round" translate="registerHere" (click)="openBenefitProgram()"></button>
</div>
