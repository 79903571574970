import { Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'numberToTime' })
export class NumberToTime implements PipeTransform {
  public transform(duration: number) {
    if (duration < 0) return '00:00';
    const hours = Math.floor(duration / 60 / 60 / 1000);
    const minutes = Math.round((duration - (hours * 60 * 60 * 1000)) / 60 / 1000);
    return `${!hours ? '00' : hours < 10 ? '0' + hours : hours}:${!minutes ? '00' : minutes < 10 ? '0' + minutes : minutes}`;
  }
}