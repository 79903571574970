import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AssignmentsService, NotificationService, SessionStorageService, UserService } from '@shared/services';
import { slideUp } from '@shared/animations';
import { CustomerEmail } from '@shared/factories';

@Component({
  selector:      'customer-email-box',
  templateUrl: './customer-email-box.component.html',
  host: { class: 'absolute bottom-0 left-0 right-0 du-flex-column btl-radius btr-radius absolute bg-white z-2'},
  animations: [ slideUp ],
})
export class CustomerEmailBoxComponent implements OnInit, OnDestroy {
  customEmail:    string;
  customerEmails: CustomerEmail[] = [];

  @Input() assignmentId: number;
  @Input() activeEmails: string[];
  @Output() save = new EventEmitter<any>;
  @HostBinding('@slideUp') get slideIn() { return 'open'; }
  constructor(
    private sessionStorageService: SessionStorageService,
    private notificationService:   NotificationService,
    private assignmentsService:    AssignmentsService,
    public  userService:           UserService
  ) {}

  ngOnInit(): void {
    this.notificationService.wait();
    this.sessionStorageService.pushOverflowStack('CustomerEmailBox');
    this.assignmentsService.loadCustomerEmails(this.assignmentId).subscribe(customerEmails => {
      this.customerEmails = customerEmails;
      this.activeEmails.forEach(e => {
        let customer = this.customerEmails.find(c => c.email === e);
        if (customer) customer.active = true;
        else this.addEmail(e);
      });
      this.notificationService.close();
    });
  }

  ngOnDestroy(): void {
    this.sessionStorageService.popOverflowStack('CustomerEmailBox');
  }

  addEmail(email: string = null): void {
    this.customerEmails.push(new CustomerEmail({
      first_name:     '',
      email:           email || this.customEmail,
      active:          true,
      id:              null,
      last_name:       null,
      personal_number: null,
      phone_number:    null,
      created_at:      null,
    }, this.assignmentId));

    this.customEmail = null;
  }

  checkedEmails(): CustomerEmail[] {
    return this.customerEmails.filter(ce => ce.active);
  }

  submit(): void {
    this.save.emit({ customerEmails: this.checkedEmails() });
    this.close();
  }

  close(): void {
    this.sessionStorageService.popDynamicComponent('CustomerEmailBox');
  }

}

