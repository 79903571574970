import { trigger, transition, style, animate } from '@angular/animations';

export const slideRight = trigger('slideRight', [
  transition(':leave', [
    style({ left: 0 }),
    animate(350, style({ left: '-100%' }))
  ]), 
  transition(':enter', [
    style({ left: '-100%' }),
    animate(250, style({ left: 0 }))
  ])
]);
