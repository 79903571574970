import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';

import { Assignment } from '@shared/factories';
import { FilterFlowModel, PillSectionModel, SortFlowModel } from '@shared/models';

import { SessionStorageService } from './session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class QueryCollectorService {
  constructor(private sessionStorageService: SessionStorageService) { }

  getActivityReportsQuery(page: number): string {
    let sort    = this.sessionStorageService.sortFlowValue || { column: 'created_at', label: 'Create Date' };
    let filters = this.collectFilters();

    return '?'+[
      `per_page=50&page=${page}`,
      ...this.getWorkingPeriodTableQuery(filters),
      ...this.getWorkingPeiodExtraInfoQuery(filters?.additional_info),
      ...this.getAssignmentsTableQuery(filters),
      ...this.getSortingQuery(sort),
    ].filter(q => q.length).join('&');
  }

  private getWorkingPeriodTableQuery(filters: any): string[] {
    let queryObj = {
      date_from: filters?.date_from ? formatDate(filters.date_from, 'yyyy-MM-dd', 'de') : null,
      date_to:   filters?.date_to   ? formatDate(filters.date_to,   'yyyy-MM-dd', 'de') : null,
    };
    return this.toQueryStringArray(queryObj, 'working_periods');
  }

  private getWorkingPeiodExtraInfoQuery(filters: string): string[] {
    let pills = filters?.split(',').reduce((sum: any, val: string) => {
      sum[val] = true;
      return sum;
    }, {});

    let queryObj = {
      with_released_state: pills?.withReleasedState ? pills.withReleasedState : null,
      with_customer_email: pills?.withCustomerEmail ? pills.withCustomerEmail : null,
      with_split_report:   pills?.withSplitReport   ? pills.withSplitReport   : null,
      checked_internally:  pills?.checkedInternally ? pills.checkedInternally : null,
      with_mileage_money:  pills?.withMileageMoney  ? pills.withMileageMoney  : null,
      with_holidays:       pills?.withHolidays      ? pills.withHolidays      : null,
      with_attachment:     pills?.withAttachment || pills?.notAttachment ? 
                           pills.withAttachment  && pills.notAttachment  ? null :
                           pills.withAttachment ? true : pills.notAttachment ? false : null : null
    };
    return this.toQueryStringArray(queryObj, 'additional_info');
  }

  parceActivityReportDBQuery(page: number = 1): any {
    let query            = this.getActivityReportsQuery(page);
    let parcedQuery: any = this.parceQuery(query);

    return {
      filter: {
        equals: [{ primKey: 'assignment.title', value: parcedQuery?.assignments?.title ? decodeURIComponent(parcedQuery.assignments.title) : null }],
        anyOfMany: [
          { primKey: 'additional_info', value: parcedQuery?.additional_info ? Object.keys(parcedQuery.additional_info) : null },
        ],
        aboveOrEqual: [{ primKey: 'date_to',   value: parcedQuery?.working_periods?.date_from }],
        belowOrEqual: [{ primKey: 'date_from', value: parcedQuery?.working_periods?.date_to   }]
      },
      sort: { primKey: parcedQuery?.sort?.name, dir: parcedQuery?.sort?.dir }
    };
  }

  getVacationRequestsQuery(page: number): string {
    let sort    = this.sessionStorageService.sortFlowValue || { column: 'created_at', label: 'Create Date' };
    let filters = this.collectFilters();

    return '?'+[
      `per_page=50&page=${page}`,
      ...this.getVacationsRequestsTableQuery(filters),
      ...this.getAssignmentsTableQuery(filters.assignment),
      ...this.getSortingQuery(sort),
    ].filter(q => q.length).join('&');
  }

  private getVacationsRequestsTableQuery(filters: any): string[] {
    let queryObj = {
      starts_on:      filters?.starts_on      ? formatDate(filters.starts_on, 'yyyy-MM-dd', 'de') : null,
      ends_on:        filters?.ends_on        ? formatDate(filters.ends_on,   'yyyy-MM-dd', 'de') : null,
      approval_state: filters?.approval_state ? filters.approval_state                            : null,
      leave_type:     filters?.leave_type     ? filters.leave_type                                : null,
      reason:         filters?.reason         ? filters.reason                                    : null,
    };
    return this.toQueryStringArray(queryObj, 'vacation_requests');
  }

  parceVacationRequestDBQuery(page: number = 1): any {
    let query            = this.getVacationRequestsQuery(page);
    let parcedQuery: any = this.parceQuery(query);

    return {
      filter: {
        equals: [
          { primKey: 'assignment.title', value: parcedQuery?.assignments?.title ? decodeURIComponent(parcedQuery.assignments.title) : null },
          { primKey: 'internal_review',  value: parcedQuery?.vacation_requests?.approval_state && parcedQuery.vacation_requests.approval_state !== 'awaiting' ? parcedQuery.vacation_requests.approval_state : null }
        ],
        nullable: [{ primKey: 'internal_review',  value: parcedQuery?.vacation_requests?.approval_state === 'awaiting' }],
        anyOf: [
          { primKey: 'leave_type', value: parcedQuery?.vacation_requests?.leave_type?.split(',') },
          { primKey: 'reason',     value: parcedQuery?.vacation_requests?.reason?.split(',')     }
        ],
        aboveOrEqual: [{ primKey: 'ends_on',   value: parcedQuery?.vacation_requests?.starts_on  }],
        belowOrEqual: [{ primKey: 'starts_on', value: parcedQuery?.vacation_requests?.ends_on    }]
      },
      sort: { primKey: parcedQuery?.sort?.name, dir: parcedQuery?.sort?.dir }
    };
  }

  getPhotoDocumentsQuery(page: number): string {
    let sort    = this.sessionStorageService.sortFlowValue || { column: 'created_at', label: 'Create Date' };
    let filters = this.collectFilters();

    return '?'+[
      `per_page=50&page=${page}`,
      ...this.getPhotoDocumentsTableQuery(filters),
      ...this.getSortingQuery(sort),
    ].filter(q => q.length).join('&');
  }

  private getPhotoDocumentsTableQuery(filters: any): string[] {
    let queryObj = {
      created_at_from: filters.created_at_from ? filters.created_at_from : null,
      created_at_to:   filters.created_at_to   ? filters.created_at_to   : null,
      unread:          filters.read === 0      ? 1                       : null,
      read:            filters.read === 1      ? 1                       : null,
      subject:         filters.subject         ? filters.subject         : null,
    };
    return this.toQueryStringArray(queryObj, 'photo_documents');
  }

  parcePhotoDocumentsDBQuery(page: number = 1): any {
    let query            = this.getPhotoDocumentsQuery(page);
    let parcedQuery: any = this.parceQuery(query);

    return {
      filter: {
        anyOf: [
          { primKey: 'status',  value: parcedQuery?.photo_documents?.status?.split(',').map((s: string) => +s) },
          { primKey: 'subject', value: parcedQuery?.photo_documents?.subject?.split(',')                       }
        ],
        aboveOrEqual: [{ primKey: 'created_at_to',   value: parcedQuery?.photo_documents?.created_at_from }],
        belowOrEqual: [{ primKey: 'created_at_from', value: parcedQuery?.photo_documents?.created_at_to   }]
      },
      sort: { primKey: parcedQuery?.sort?.name, dir: parcedQuery?.sort?.dir }
    };
  }

  private getAssignmentsTableQuery(assignment: Assignment): string[] {
    let queryObj = {
      title: !!assignment?.title ? encodeURIComponent(assignment.title) : null,
    };
    return this.toQueryStringArray(queryObj, 'assignments');
  }

  private getSortingQuery({ column, dir}: SortFlowModel): string[] {
    return [`q[sort][0][pos]=0&q[sort][0][name]=${column}&q[sort][0][dir]=${this.getSortingDirection(dir)}`];
  }

  private getSortingDirection(order: boolean): string {
    return order ? 'asc' : 'desc';
  }

  private toQueryStringArray(queryObj: any, queryTable: string): string[] {
    let queryL = [];
    for (const key in queryObj) if (queryObj[key] !== null) {
      queryL.push(`q[${queryTable}][${key}]=${queryObj[key]}`);
    }
    return queryL;
  }

  private parceActivePills(pills: PillSectionModel[]) {
    return pills && pills.length && pills.reduce((sum, val) => {
      sum = [...sum, ...val.pills.map(p => {
        p.field = val.field || p.field;
        return p;
      })];
      return sum;
    },[]).reduce((sum, val) => {
      if (!sum[val.field]) sum[val.field] = val.value || true;
      else sum[val.field] = sum[val.field] + ',' + val.value;

      return sum;
    }, {});
  }

  private parcePeriod(filters: FilterFlowModel) {
    let period: any = {};
    if (filters?.from?.field) period[filters.from.field] = filters.from.date;
    if (filters?.to?.field)   period[filters.to.field]   = filters.to.date;
    return period;
  }

  private parceTab(tab: any) {
    return tab && { [tab.field]: tab.value };
  }

  private collectFilters(): any {
    let temp = this.sessionStorageService.filterFlowValue;
    let tab  = this.sessionStorageService.tabFlowValue;
    return Object.assign(
      { assignment: temp?.assignment },
      this.parcePeriod(temp),
      this.parceActivePills(temp?.pills),
      this.parceTab(tab)
    );
  }

  private parceQuery(query: string ): object {
    return query.replace('?', '').split('&').reduce((sum: any, val) => {
      let temp = val.split('=');
      let obj: string[] = temp[0].replaceAll('q[', '').replaceAll('[0]', '').split('][').map(t => t.replace(']', ''));
      let value = temp[1];

      obj.reduce((_, key, i, array) => {
        let temp: any = {};
        if (i) temp = _;
        else temp = sum;

        if (!temp[key]) temp[key] = {};
        if (array.length === i+1) temp[key] = value;
        return temp[key];
      }, {});

      return sum;
    }, {});
  }

}
