import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[tutorialStep]'
})
export class TutorialStepDirective {
  @Input() tutorialStep:   number;
  @Input() tutorialNumber: number;
  @Input() tutorialText:   string;
  wrapper: HTMLDivElement;
  constructor(
    private renderer:    Renderer2,
    private hostElement: ElementRef
  ) { }

  ngOnChanges(): void {
    if (this.tutorialStep) {
      if (this.tutorialStep === this.tutorialNumber) {
        setTimeout(() => {
          this.hostElement.nativeElement.classList.add('relative', 'z-2');
          Array.from(this.hostElement.nativeElement.children).forEach((el: any) => this.renderer.addClass(el, 'z-2'));
          this.createTutorialStep();
        });
      } else this.deleteTutorialStep();
    }
  }

  private createTutorialStep(): void {
    this.wrapper = this.renderer.createElement('div');
    this.wrapper.classList.add('absolute', 'bg-white', 'top-0', 'left--s', 'right--s', 'bottom--s', 'bbl-radius', 'bbr-radius', 'z-1', 'animate-opacity');
    this.renderer.setProperty(this.wrapper, '@fadeIn', 'true');

    let tooltip = this.renderer.createElement('span');
    tooltip.classList.add('du-flex-align-start', 'gap-s', 'absolute', 'bg-white', 'pl-s', 'pr-s', 'pb-l', 'pt-s', 'btl-radius', 'btr-radius', 'bottom-full', 'left-0', 'right-0', 'animate-opacity');

    let tooltipIcon = this.renderer.createElement('img');
    this.renderer.setProperty(tooltipIcon, 'src', 'assets/svg/warning-blue.svg');

    let tooltipText = this.renderer.createElement('span');
    tooltipText.classList.add('lh-l', 'font-s', 'color-blue');

    let text = this.renderer.createText(this.tutorialText);
    this.renderer.appendChild(tooltipText, text);

    this.renderer.appendChild(tooltip, tooltipIcon);
    this.renderer.appendChild(tooltip, tooltipText);
    this.renderer.appendChild(this.wrapper, tooltip);
    this.renderer.appendChild(this.hostElement.nativeElement, this.wrapper);
  }

  private deleteTutorialStep(): void {
    if (this.wrapper) {
      this.hostElement.nativeElement.classList.remove('z-2');
      Array.from(this.hostElement.nativeElement.children).forEach((el: any) => this.renderer.removeClass(el, 'z-2'));
      this.renderer.removeChild(this.hostElement.nativeElement, this.wrapper);
    }
  }

}