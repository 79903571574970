<div class="du-flex-column gap-s bg-white z-1">
  <filter-sort *ngIf="pills" [from]="'created_at_from'" [to]="'created_at_to'" [pills]="pills" [sort]="sort" (updateData)="loadDocuments(true)"></filter-sort>
  <tabs-box [tabs]="tabs" [field]="'read'" (updateData)="loadDocuments(true)"></tabs-box>
</div>
<ul class="du-flex-1 height-full du-flex-column gap-m overflow-auto"
    infiniteScroll
    [infiniteScrollDistance]="1"
    [infiniteScrollThrottle]="150"
    [scrollWindow]="false"
    (scrolled)="loadDocuments()">
  <pull-to-refresh (callback)="loadDocuments(true)"></pull-to-refresh>
  <documents-overview-item *ngFor="let document of documents" [document]="document"></documents-overview-item>
  <span *ngIf="paging && page === paging.total_pages" class="du-flex-center p-m color-disabled">No more entries to load (Total entries: {{ documents.length }})</span>
  <span *ngIf="paging && paging.total_pages === 0"    class="du-flex-center p-m color-disabled">No results were found.</span>
</ul>