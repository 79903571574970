<vacations-overview-item [vacation]="vacation" [extended]="true"></vacations-overview-item>

<div class="du-flex-column gap-l pt-xs pl-m pr-m pb-xxxl bt-grey" *ngIf="vacation.reason">
  <div class="du-flex-column gap-xxs">
    <span class="font-s" translate="reason"></span>
    <span class="bold">{{ vacation.reason | translate }}</span>
  </div>
  <div class="du-flex-justify gap-xxs font-s" *ngIf="vacation.internalReview">
    <div class="du-flex-column gap-s">
      <span *ngIf="vacation.internalReview === 'approved'" class="bold" translate="approved"></span>
      <span *ngIf="vacation.internalReview === 'rejected'" class="bold" translate="rejected"></span>
      <div class="du-flex-column gap-xxs">
        <span class="du-flex-align gap-xxs" *ngIf="vacation.internalReviewedAt">
          <span translate="at"></span>
          <span>{{ vacation.internalReviewedAt | date: 'HH:mm' }}</span>
          <span class="bold">{{ vacation.internalReviewedAt | date: 'dd.MM.yyyy' }}</span>
        </span>
        <span class="du-flex-align gap-xxs" *ngIf="vacation.reviewedByInternal">
          <span translate="by"></span>
          <span class="bold">{{ vacation.reviewedByInternal.first_name }} {{ vacation.reviewedByInternal.last_name }}</span>
        </span>
      </div>
    </div>
    <div class="du-flex-column du-flex-align-end gap-s">
      <span *ngIf="vacation.internalRejectionReason" translate="rejectionReason"></span>
      <span *ngIf="vacation.internalRejectionReason" class="bold">{{ vacation.internalRejectionReason | translate }}</span>
    </div>
  </div>
  <div class="du-flex-column gap-xxs" *ngIf="vacation.internalReviewComment">
    <span class="font-s" translate="comment"></span>
    <span class="bold">{{ vacation.internalReviewComment }}</span>
  </div>
</div>
