<div class="du-flex gap-s">
  <div *ngIf="pills" 
        class="du-flex-center height-45 b-radius gap-s bg-card-grey relative"
       [ngClass]="sort ? 'du-flex-1' : 'du-flex-half'"
       [class.color-blue]="filterActive"
       (click)="openFilter()">
    <span class="du-icon-filter du-flex-center font-icon"></span>
    <span class="du-flex-align gap-xxs">
      <span translate="filter"></span>
      <span *ngIf="filterActive">({{ filterActive }})</span>
    </span>
  </div>
  <div *ngIf="sort"
        class="du-flex-center height-45 b-radius gap-s bg-card-grey"
       [ngClass]="pills ? 'du-flex-1' : 'du-flex-half'"
       [class.color-blue]="sortActive"
       (click)="openSort()">
    <span class="du-icon-sort du-flex-center font-icon"></span>
    <span>{{ sortActive ? sortActive : 'sortBy' | translate }}</span>
  </div>
</div>
