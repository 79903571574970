
<div class="du-flex-justify du-flex-align pl-m pt-m pr-m pb-s gap-xl bb-grey" *ngIf="index >= 0">
  <span class="du-flex-align gap-xxs font-l bold">
    <span>{{ daily.startsAt | date: 'EEE' }}</span>
    <span *ngIf="useCase !== 't'">{{ daily.startsAt | date: '- dd.MM.YYYY' }}</span>
  </span>
  <daily-tooltips [mileage]="daily.mileage" [holidays]="daily.holidays"></daily-tooltips>
  <img *ngIf="!daily.placeholder" src="assets/svg/close.svg" class="pl10 pr10" (click)="resetPlaceholder()">
</div>

<div class="du-flex-column gap-s pl-m pt-m pr-m pb-s bb-grey">
  <div class="du-flex-align gap-xl">
    <span translate="workingHours"></span>
    <daily-tooltips [mileage]="daily.mileage" [holidays]="daily.holidays" *ngIf="index === null || index === undefined"></daily-tooltips>
  </div>
  <div class="du-flex-column" *ngIf="daily.techErrors && daily.techErrors.size || daily.legalErrors && daily.legalErrors.size" @collapse>
    <div class="du-flex-column gap5 text-xs overflow">
      <span class="color-orange" *ngFor="let err of daily.legalErrors" @collapse><span class="overflow">{{ err | translate}}</span></span>
      <span class="color-red"    *ngFor="let err of daily.techErrors"  @collapse><span class="overflow">{{ err | translate}}</span></span>
    </div>
  </div>
  <div class="du-flex-align gap-xl" [tutorialStep]="tutorialStepVal" [tutorialNumber]="4" [tutorialText]="'hereCanInputTime' | translate">
    <div class="du-flex-align gap-xs">
      <input class="time-input" type="time" [class.b-red]="daily.techErrors.size" [class.b-orange]="!daily.techErrors.size && daily.legalErrors.size" [ngModel]="daily.startsAt | date: 'HH:mm'" (ngModelChange)="updateTime($event, daily.startsAt)">
      <span>-</span>
      <input class="time-input" type="time" [class.b-red]="daily.techErrors.size" [class.b-orange]="!daily.techErrors.size && daily.legalErrors.size" [ngModel]="daily.endsAt   | date: 'HH:mm'" (ngModelChange)="updateTime($event, daily.endsAt)">
    </div>
    <span>{{ daily.durationExludingPauses | numberToTime }}</span>
  </div>  
</div>

<div class="du-flex-column gap-s pl-m pt-m pr-m pb-s bb-grey" [class.overflow-auto]="!tutorialStepVal" [tutorialStep]="tutorialStepVal" [tutorialNumber]="5" [tutorialText]="'hereCanInputBreaks' | translate">
  <div class="du-flex-justify">
    <div class="du-flex-column gap-s">
      <span translate="breakTime"></span>
      <div class="du-flex-column" *ngIf="daily.pauseTechErrors && daily.pauseTechErrors.size || daily.pauseLegalErrors && daily.pauseLegalErrors.size" @collapse>
        <div class="du-flex-column gap5 text-xs overflow">
          <span class="color-orange" *ngFor="let err of daily.pauseLegalErrors" @collapse><span class="overflow">{{ err | translate}}</span></span>
          <span class="color-red"    *ngFor="let err of daily.pauseTechErrors"  @collapse><span class="overflow">{{ err | translate}}</span></span>
        </div>
      </div>
    </div>
    <div *ngIf="!daily.placeholder" class="b-blue width-45 height-45 du-flex-center p-m" (click)="addPause()">
      <img src="assets/svg/plus.svg">
    </div>
  </div>
  <div class="du-flex-justify gap-xl" *ngFor="let pause of daily.sortedPauses">
    <div class="du-flex-justify du-flex-align gap-l">
      <div class="du-flex-align gap-xs">
        <input class="time-input" type="time" [ngModel]="pause.start | date: 'HH:mm'" (ngModelChange)="updateTime($event, pause.start)" [class.b-red]="pause.techErrors.size" [class.b-orange]="!pause.techErrors.size && pause.legalErrors.size" >
        <span>-</span>
        <input class="time-input" type="time" [ngModel]="pause.end   | date: 'HH:mm'" (ngModelChange)="updateTime($event, pause.end)"   [class.b-red]="pause.techErrors.size" [class.b-orange]="!pause.techErrors.size && pause.legalErrors.size" >
      </div>
      <span>{{ pause.duration | numberToTime }}</span>
    </div>
    <div *ngIf="!daily.placeholder" class="bg-card-grey b-radius width-45 height-45 du-flex-center p-m" (click)="deletePause(pause)">
      <img src="assets/svg/minus-black.svg">
    </div>
  </div>
</div>

<div class="pt-s pl-m pr-m pb-m" [tutorialStep]="tutorialStepVal" [tutorialNumber]="6" [tutorialText]="'canTypeSpecifications' | translate">
  <input class="relative" type="text" placeholder="Project" [(ngModel)]="daily.project">
</div>

<div class="absolute-full du-flex-center b-radius bg-card-grey-light" @fadeIn *ngIf="daily.placeholder" (click)="addDefaultDay()">
  <div class="du-flex-center width-full" [tutorialStep]="tutorialStepVal" [tutorialNumber]="2" [tutorialText]="'activateDaySelectedAbove' | translate">
    <div class="du-flex-center circle-75 bg-blue-gradient">
      <img src="assets/svg/plus-white.svg">
    </div>
  </div>
</div>
