<div *ngIf="message" id="modal-wrapper" class="fixed-full du-flex-center">
  <div class="bg-semi-black fixed-full z-1" (click)="overflowClick()"></div>

  <div class="du-flex-column du-flex-justify gap-xl width-full bg-white b-white p-m modal-popup z-2" *ngIf="message.title">
    <div class="du-flex-column gap-m">
      <div class="du-flex-justify du-flex-align gap-l">
        <span class="font-l">{{ message.title | translate }}</span>
        <img src="assets/svg/close.svg" class="p-xs" (click)="overflowClick()">
      </div>
      <ul *ngIf="message.desc" [ngClass]="isArray(message.desc) ? 'du-flex-column gap-xs list-disc ml-xl' : ''" class="lh-l">
        <ng-container *ngIf="isArray(message.desc)">
          <li *ngFor="let mes of message.desc">{{ mes | translate }}</li>
        </ng-container>
        <ng-container *ngIf="!isArray(message.desc)">
          <li>{{ message.desc | translate }}</li>
        </ng-container>
      </ul>
      <span *ngIf="message.descHTML" class="lh-l" innerHTML="{{ message.descHTML | translate }}"></span>
    </div>
    <div class="width-full du-flex-justify gap-l" *ngIf="message.buttons">
      <button *ngIf="message.buttons.back"  class="btn-primary   btn-round du-flex-1 font-l" (click)="message.backFn()">{{  message.buttons.back   | translate }}</button>
      <button *ngIf="message.buttons.left"  class="btn-secondary btn-round du-flex-1 font-l" (click)="message.leftFn()" >{{ message.buttons.left   | translate }}</button>
      <button *ngIf="message.buttons.right" class="btn-primary   btn-round du-flex-1 font-l" (click)="message.rightFn()">{{ message.buttons.right  | translate }}</button>
    </div>
  </div>

  <div class="du-flex-align gap-m bg-white b-radius pl-xxxl pr-xxxl pt-m pb-m z-2" *ngIf="message.wait">
    <div class="relative loader-animation">
      <div class="circle-50 loader-circle"></div>
      <div class="loader-end"></div>
    </div>
    <span class="font-l bold" translate="pleaseWait"></span>
  </div>
</div>
