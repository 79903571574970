import { Component, OnInit } from '@angular/core';
import { SessionStorageService } from '@shared/services';

@Component({
  templateUrl: './start-screen.component.html',
  host: { class: 'du-flex-column du-flex-justify pt-xl-ios pb-xl-ios pl-xl pr-xl bg-blue-gradient-auth' }
})
export class StartScreenComponent implements OnInit {
  constructor(
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.sessionStorageService.setHeaderTitle(null);
    this.sessionStorageService.setHeaderControls(null);
    this.sessionStorageService.setProgressBar(null);
  }

}
