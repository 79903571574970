import { Component, Input, OnInit } from '@angular/core';
import { NotificationService, ImageService } from '@shared/services';

@Component({
  selector:      'photo-item',
  templateUrl: './photo-item.component.html',
  host: { class: 'height-full b-radius bg-card-grey' }
})
export class PhotoItemComponent implements OnInit {
  @Input() itemUrl: string;
  image: any;
  constructor(
    private imageService:        ImageService,
    public  notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.imageService.loadImage(this.itemUrl).subscribe(
      res => this.image = res,
      err => this.notificationService.alert(err)
    );
  }

}
