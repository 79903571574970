<div class="du-flex-column gap-m">
  <div class="du-flex-column gap-xs">
    <span class="font-l bold" translate="wantTouseDailyReports"></span>
    <span class="font-s"      translate="canEditInNextStep"></span>
  </div>
  <ul class="du-flex-column gap-m">
    <daily-overview-item [daily]="daily" *ngFor="let daily of report?.activeDailyReports"></daily-overview-item>
  </ul>
</div>

<div class="du-flex-column gap-m">
  <button class="width-full btn-secondary btn-round" translate="no"  (click)="clearDailies()"></button>
  <button class="width-full btn-primary   btn-round" translate="yes" (click)="goNext()"></button>
</div>
