import { Location          } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { from, map, of, switchMap } from 'rxjs';

import { ActivityReportLocal, ActivityReportOverview } from '@shared/factories';
import { PagingModel, PillSectionModel, SortModel    } from '@shared/models';
import { ActivityReportsService, DBService, NotificationService, SessionStorageService } from '@shared/services';

@Component({
  templateUrl: './reports-overview.component.html',
  host: { class: 'height-full du-flex-column gap-m p-xl overflow' }
})
export class ReportsOverviewComponent implements OnInit {
  search: string;
  page:   number;
  paging: PagingModel;
  pills:  PillSectionModel[] = [
    { label: 'specifications', field: 'additional_info', pills: [
      { label: 'millageAllowance',  value: 'withMileageMoney'  },
      { label: 'withHolidays',      value: 'withHolidays'      },
      { label: 'photoReport',       value: 'withAttachment'    },
      { label: 'regularReport',     value: 'notAttachment'     },
      { label: 'withSplitReport',   value: 'withSplitReport'   },
      { label: 'withCustomerEmail', value: 'withCustomerEmail' },
      { label: 'withReleasedState', value: 'withReleasedState' },
    ]}
  ];

  sort: SortModel[] = [
    { label: 'latest', column: 'created_at',    active: true },
    { label: 'newest', column: 'created_at',       dir: true },
    { label: 'A-Z',    column: 'assignment_title'            },
    { label: 'Z-A',    column: 'assignment_title', dir: true },
  ];

  reports: (ActivityReportOverview | ActivityReportLocal)[] = [];
  constructor(
    private location:               Location,
    private dbService:              DBService,
    private activityReportsService: ActivityReportsService,
    private notificationService:    NotificationService,
    private sessionStorageService:  SessionStorageService
  ) {}

  ngOnInit(): void {
    this.prepareHeader();
    this.sessionStorageService.paging.subscribe(paging => this.paging = paging);
    this.loadReports();
  }

  private prepareHeader(): void {
    this.sessionStorageService.setHeaderTitle('activityReport');
    this.sessionStorageService.setHeaderControls({ left: [{ icon: 'arrow-big-left color-blue font-icon', callback: () => this.location.back() }] });
    this.sessionStorageService.setProgressBar(null);
  }

  loadReports(reload: boolean = null): void {
    if (!this.paging || !this.paging.total_pages || !this.page || this.page < this.paging.total_pages || reload) {
      this.page = reload || !this.page ? 1 : this.page+1;
      this.notificationService.wait();
      this.activityReportsService.loadReports(this.page).pipe(
        switchMap(reports => {
          if (this.page === 1) return from(this.dbService.loadMultipleFromDB('pendingUpload', { useCase: 'ar' })).pipe(
            map(pendingAr => pendingAr.map(vr => new ActivityReportLocal(vr))),
            map(pendingAr => [...pendingAr, ...reports])
          );
          else return of(reports);
        })
      ).subscribe(
        reports => {
          if (reload) this.reports = [...reports];
          else this.reports = [...this.reports, ...reports];
          this.sessionStorageService.setCachedEntries(this.reports);
          this.notificationService.close();
        },
        err => this.notificationService.alert(err),
        () => this.sessionStorageService.setPulltoRefreshState(true)
      );
    }
  }

}
