

import { TemplateModel } from "@shared/models";
import { DailyLocal } from "./daily.factory";

export class Template {
  id:      number;
  userId:  number;
  name:    string;
  dailies: DailyLocal[];
  constructor(data: TemplateModel) {
    this.id      = data.id;
    this.userId  = data.userId;
    this.name    = data.name;
    this.dailies = data.dailies?.length ? data.dailies.map(d => new DailyLocal(d)) : null;
  }

  toJSON(): TemplateModel {
    let obj = {
      userId:  this.userId          ? this.userId                       : null,
      name:    this.name            ? this.name                         : null,
      dailies: this.dailies?.length ? this.dailies.map(d => d.toJSON()) : null
    };

    if (this.id) obj = Object.assign(obj, { id: this.id });
    return obj;
  }

}
