<img *ngIf="image" [id]="'pinch-zoom-image-id-' + currentPage" class="pinch-zoom-image" (load)="onLoad()" [src]="image">

<div class="absolute m-m top-0 left-0 right-0 du-flex-justify">
  <span class="b-radius bg-card-grey pt-xs pb-xs pl-s pr-s font-s height-full">
    {{ currentPage }} of {{ totalPages }}
  </span>
  <div class="du-flex gap-m">
    <div class="du-flex-center width-40 height-40 b-radius bg-card-grey p-s" (click)="zoomIn()">
      <img src="assets/svg/plus-black.svg">
    </div>
    <div class="du-flex-center width-40 height-40 b-radius bg-card-grey p-s" (click)="resetZoom()">
      <img src="assets/svg/expand-black.svg">
    </div>
    <div class="du-flex-center width-40 height-40 b-radius bg-card-grey p-s" (click)="zoomOut()">
      <img src="assets/svg/minus-black.svg">
    </div>
  </div>
</div>
