<div class="du-flex-justify du-flex-align bb-grey">
  <img  class="pl-xl pr-xl pt-m pb-m mra" src="assets/svg/arrow-left.svg" (click)="close()">
  <span class="absolute left-70 right-70 du-flex-center color-grey bold">{{ template.name }}</span>
</div>

<ul class="du-flex-column gap-s pl-xl pt-xl pr-xl pb-xs overflow-auto">
  <li class="du-flex-column gap-s pb-s" *ngFor="let daily of template.dailies; let i = index" [class.bb-grey]="i+1 !== template.dailies.length">
    <span class="bold">{{ daily.startsAt | date: 'EEEE' }}</span>
    <div class="du-flex-align-start">
      <div class="du-flex-column gap-xs width-120">
        <span class="font-s" translate="workingHours"></span>
        <span class="bold roboto">{{ daily.startsAt | date: 'HH:mm' }} - {{ daily.endsAt | date: 'HH:mm' }}</span>
      </div>
      <div class="du-flex-column du-flex-1 gap-xs">
        <span class="du-flex-align gap-xxs font-s">
          <span translate="breaks"></span>
          <span *ngIf="daily.pauses && daily.pauses.length">({{ daily.pauses.length }})</span>
        </span>
        <span class="du-flex-wrap gap-xxs bold">
          <span class="roboto" *ngFor="let pause of daily.pauses">
            {{ pause.start | date: 'HH:mm' }} - {{ pause.end | date: 'HH:mm' }}
          </span>
        </span>
      </div>
    </div>
  </li>
</ul>

<div class="du-flex-column gap-l pl-xl pr-xl pb-xl pt-m bt-grey">
  <span   class="lh-l font-500" translate="wantUseThisTemplate"></span>
  <button class="btn-secondary btn-round" translate="no"  (click)="close()"></button>
  <button class="btn-primary   btn-round" translate="yes" (click)="useTemplate()"></button>
</div>