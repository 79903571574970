import { Component, OnInit } from '@angular/core';
import { SessionStorageService } from '@shared/services';
import { collapse } from '@shared/animations';

@Component({
  selector:      'offline-note',
  templateUrl: './offline-note.component.html',
  host: { class: 'du-flex-column z-1' },
  animations: [ collapse ]
})
export class OfflineNoteComponent implements OnInit {
  offline:    boolean;
  backOnline: boolean;
  closeTimer: any;
  constructor( private sessionStorageService: SessionStorageService ) {}

  ngOnInit(): void {
    this.sessionStorageService.offlineMode.subscribe(offline => {
      if (this.offline    && !offline) this.backOnlineHandler();
      if (this.backOnline &&  offline) clearTimeout(this.closeTimer);
      this.offline = offline;
    });
  }

  private backOnlineHandler(): void {
    this.backOnline = true;
    this.closeTimer = setTimeout(() => this.backOnline = false, 1500);
  }

}
