import { Component, OnInit } from '@angular/core';
import { Network           } from '@capacitor/network';
import { from, take        } from 'rxjs';
import { TranslateService  } from '@ngx-translate/core';
import * as pdfjsLib         from 'pdfjs-dist';

import { PushNotificationsService, ReleasesService, SessionStorageService } from '@shared/services';

import { environment } from 'environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
  host: { class: 'du-flex-column height-full' }

})
export class AppComponent implements OnInit {
  constructor(
    private translateService:         TranslateService,
    private pushNotificationsService: PushNotificationsService,
    private releasesService:          ReleasesService,
    private sessionStorageService:    SessionStorageService,
  ) {}

  ngOnInit(): void {
    this.translateService.use(environment.defaultLocale);
    pdfjsLib.GlobalWorkerOptions.workerSrc = './assets/vendor/pdf.worker.js';
    this.pushNotificationsService.init();
    from(Network.getStatus()).pipe(take(1)).subscribe(res => this.releasesService.checkCurrentStoreVersion(res.connected));
    Network.addListener('networkStatusChange', status => {
      this.sessionStorageService.setOfflineMode(!status.connected);
      this.releasesService.checkCurrentStoreVersion(status.connected);
    });
  }

}
