import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { ActivationService, NotificationService, SessionStorageService } from '@shared/services';
import { Observable, take } from 'rxjs';

@Component({
  selector: 'code-stage',
  templateUrl: './code-stage.component.html',
  host: { class: 'height-full du-flex-column du-flex-justify p-xl' }
})
export class CodeStageComponent implements OnInit {
  pins:  number[] = [1,2,3,4,5,6];
  timer: number   = 59;

  @Input()  userId: string;
  @Input()  type:   string;
  @Output() callback = new EventEmitter<Observable<string>>();

  @HostListener('document:keydown', ['$event'])
  pinHandler(event: KeyboardEvent): void {
    if (event.target instanceof HTMLInputElement && event.target.closest('.pin')) {
      if (!Capacitor.isNativePlatform()) {
        if (event.target.previousElementSibling && (event.code === 'Backspace' || event.code === 'Delete' )) {
          if (event.target.value === '') (event.target.previousElementSibling as HTMLInputElement).focus();
        }
        if (event.code.includes('Digit') || event.code.includes('Numpad')) {
          event.preventDefault();
          event.target.value = event.key;
          if (event.target.nextElementSibling) (event.target.nextElementSibling as HTMLInputElement).focus();
          else setTimeout(() => this.checkAndSendCode());
        }
      } else {
        if (event.target.previousSibling && (event.code === 'Backspace' || event.code === 'Delete' )) {
          if (event.target.value === '') (event.target.previousSibling as HTMLInputElement).focus();
        }
        if (event.code.includes('Digit')) {
          event.preventDefault();
          event.target.value = event.key;
          if (event.target.nextElementSibling) (event.target.nextElementSibling as HTMLInputElement).focus();
          else setTimeout(() => this.checkAndSendCode());
        }
      }
    }
  }

  constructor(
    private activationService:     ActivationService,
    private sessionStorageService: SessionStorageService,
    private notificationService:   NotificationService,
  ) {}

  ngOnInit(): void {
    this.sessionStorageService.setProgressBar(2, 4);
    setInterval(() => { if (this.timer) --this.timer } , 1000);
  }

  checkAndSendCode(): void {
    let box = document.getElementsByClassName('pin-box')[0];
    let code = '';
    for (let i = 0; i < box.children.length; i++) {
      if ((box.children[i] as HTMLInputElement).value === '') return;
      else code += (box.children[i] as HTMLInputElement).value;
    }
    if (code.length === 6) this.sendCode(code);
  }

  sendCode(code: string): void {
    this.notificationService.wait();
    this.callback.emit(this.activationService.sendCode(this.type, this.userId, code));
  }

  resendCode(): void {
    this.notificationService.wait();
    this.activationService.requestCode(this.type, this.userId).pipe(take(1)).subscribe(
      res => {
        this.timer = 59;
        this.notificationService.close();
      },
      err => this.notificationService.alert(err)
    );
  }

  checkActive(index: number): boolean {
    let box = document.getElementsByClassName('pin-box')[0];
    return (box.children[index] as HTMLInputElement).value !== '';
  }

}
