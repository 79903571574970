<div class="du-flex-column gap-xl color-white">
  <language-toggler color="'white'"></language-toggler>
  <h1 class="font-xl bold mt-m" translate="login"></h1>
  <div class="du-flex-column gap-m" [formGroup]="loginForm">
    <div class="du-flex-column gap-xxs">
      <label for="email" translate="userId"></label>
      <input  id="email" class="light-blue" type="email" formControlName="email">
    </div>

    <div class="du-flex-column gap-xxs">
      <label for="password" translate="password"></label>
      <input  id="password" class="light-blue" type="password" formControlName="password">
    </div>

    <span class="font-s underline mla" translate="forgotPassword" [routerLink]="['/auth/reset']"></span>
  </div>
</div>

<button class="width-full btn-primary btn-round mla mra mt20" [routerLink]="['/auth/login']" translate="login" (click)="login()"></button>
