import { DocumentTypeModel } from "@shared/models";

export class DocumentType {
  name:   string;
  active: boolean;
  nameTranslations: {
    cz: string;
    de: string;
    en: string;
    hr: string;
    hu: string;
    po: string;
    ro: string;
    ru: string;
    tr: string;
    ua: string;
  };
  constructor(data: DocumentTypeModel) {
    this.name             = data.name;
    this.active           = data.active;
    this.nameTranslations = data.name_translations;
  }

  toJSON(): DocumentTypeModel{
    return {
      name:              this.name             ? this.name             : null,
      active:            this.active           ? this.active           : null,
      name_translations: this.nameTranslations ? this.nameTranslations : null
    };
  }

}
