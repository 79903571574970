import { EBSBasicModel, EBSModel } from "@shared/models";

export class EBSBasic {
  id:          number;
  confirmedAt: Date;
  constructor(data: EBSBasicModel) {
    this.id          = data.id;
    this.confirmedAt = this.parceDate(data.confirmed_at);
  }

  parceDate(date: any): Date {
    return date ? date instanceof Date ? date : new Date(date) : null;
  }

  toJSON(): EBSBasicModel {
    return {
      id:           this.id          ? this.id                        : null,
      confirmed_at: this.confirmedAt ? this.confirmedAt.toISOString() : null
    };
  }
}

export class EBS extends EBSBasic {
  userId:                  number;
  title:                   string;
  archivedAt:              Date;
  version:                 number;

  personalNumber:          number;
  persname:                string;
  persvorname:             string;
  assignmentId:            number;
  assignmentEndsAt:        Date;

  anspr_telefon:           string;
  kundname1:               string;
  auftelefon1:             string;
  kundstrasse:             string;
  kundplz:                 string;
  kundort:                 string;
  aufansprech1:            string;
  aufadresse1:             string;
  aufmuhrzeit:             string;
  aufbetrstaette:          string;
  aufberuf:                string;
  aufpostaet1:             string;
  aufueentfernung:         string;
  auftextmarken4:          string;
  auftarifgruppe:          string;
  auftarifmagruppe:        string;
  aufueberlbeginn:         string;
  aufarbzeitwoche:         string | number;
  auffahrbetrag:           string;
  aufvmabetrag:            string;
  aufuebernbetrag:         string;
  aufdeckelbetragakt:      number;
  pel705preis:             string;
  txt_sonstigeZulage:      string;
  aufarbs:                 string[];
  aufuntersuchidx:         string;
  aufgefahrarbeit:         string;
  auftarifbranche:         string;
  auftarifvertrag:         string;
  filtelefon1:             string;
  internal_contact_persnr: number;
  constructor(data: EBSModel) {
    super(data);
    this.userId                  = data.userId;
    this.title                   = data.title;
    this.archivedAt              = this.parceDate(data.archived_at);
    this.version                 = data.version;

    this.personalNumber          = data.personal_number;
    this.persname                = data.persname;
    this.persvorname             = data.persvorname;
    this.assignmentId            = data.assignment_id;
    this.assignmentEndsAt        = this.parceDate(data.assignment_ends_at);

    this.anspr_telefon           = data.anspr_telefon;
    this.kundname1               = data.kundname1;
    this.auftelefon1             = data.auftelefon1;
    this.kundstrasse             = data.kundstrasse;
    this.kundplz                 = data.kundplz;
    this.kundort                 = data.kundort;
    this.aufansprech1            = data.aufansprech1;
    this.aufadresse1             = data.aufadresse1;
    this.aufmuhrzeit             = data.aufmuhrzeit;
    this.aufbetrstaette          = data.aufbetrstaette;
    this.aufberuf                = data.aufberuf;
    this.aufpostaet1             = data.aufpostaet1;
    this.auftextmarken4          = data.auftextmarken4;
    this.auftarifgruppe          = data.auftarifgruppe;
    this.auftarifmagruppe        = data.auftarifmagruppe;
    this.aufueberlbeginn         = data.aufueberlbeginn;

    this.aufdeckelbetragakt      = data.aufdeckelbetragakt;
    this.aufuntersuchidx         = data.aufuntersuchidx;
    this.aufgefahrarbeit         = data.aufgefahrarbeit;
    this.auftarifbranche         = data.auftarifbranche;
    this.auftarifvertrag         = data.auftarifvertrag;
    this.filtelefon1             = data.filtelefon1;
    this.internal_contact_persnr = data.internal_contact_persnr;

    this.aufarbs                 = data.aufarbs ? Array.isArray(data.aufarbs) ? data.aufarbs : data.aufarbs.split('; ') : [];
    this.aufarbzeitwoche         = isNaN(data.aufarbzeitwoche) ? data.aufarbzeitwoche : data.aufarbzeitwoche.toFixed(1).replace('.', ',');
    this.auffahrbetrag           = data.auffahrbetrag          ? this.formatPayValue(data.auffahrbetrag)      : '0';
    this.aufueentfernung         = data.aufueentfernung        ? this.formatPayValue(data.aufueentfernung)    : '0';
    this.aufvmabetrag            = data.aufvmabetrag           ? this.formatPayValue(data.aufvmabetrag)       : '0';
    this.aufuebernbetrag         = data.aufuebernbetrag        ? this.formatPayValue(data.aufuebernbetrag)    : '0';
    this.pel705preis             = data.pel705preis            ? this.formatPayValue(data.pel705preis)        : '0';
    this.txt_sonstigeZulage      = data.txt_sonstigeZulage     ? this.formatPayValue(data.txt_sonstigeZulage) : '0';
  }

  toJSON(): EBSModel {
    return Object.assign(super.toJSON(), {
      userId:                  this.userId                  ? this.userId                         : null,
      title:                   this.title                   ? this.title                          : null,
      archived_at:             this.archivedAt              ? this.archivedAt.toISOString()       : null,
      version:                 this.version                 ? this.version                        : null,

      personal_number:         this.personalNumber          ? this.personalNumber                 : null,
      persname:                this.persname                ? this.persname                       : null,
      persvorname:             this.persvorname             ? this.persvorname                    : null,
      assignment_id:           this.assignmentId            ? this.assignmentId                   : null,
      assignment_ends_at:      this.assignmentEndsAt        ? this.assignmentEndsAt.toISOString() : null,

      anspr_telefon:           this.anspr_telefon           ? this.anspr_telefon                  : null,
      kundname1:               this.kundname1               ? this.kundname1                      : null,
      auftelefon1:             this.auftelefon1             ? this.auftelefon1                    : null,
      kundstrasse:             this.kundstrasse             ? this.kundstrasse                    : null,
      kundplz:                 this.kundplz                 ? this.kundplz                        : null,
      kundort:                 this.kundort                 ? this.kundort                        : null,
      aufansprech1:            this.aufansprech1            ? this.aufansprech1                   : null,
      aufadresse1:             this.aufadresse1             ? this.aufadresse1                    : null,
      aufmuhrzeit:             this.aufmuhrzeit             ? this.aufmuhrzeit                    : null,
      aufbetrstaette:          this.aufbetrstaette          ? this.aufbetrstaette                 : null,
      aufberuf:                this.aufberuf                ? this.aufberuf                       : null,
      aufpostaet1:             this.aufpostaet1             ? this.aufpostaet1                    : null,
      auftextmarken4:          this.auftextmarken4          ? this.auftextmarken4                 : null,
      auftarifgruppe:          this.auftarifgruppe          ? this.auftarifgruppe                 : null,
      auftarifmagruppe:        this.auftarifmagruppe        ? this.auftarifmagruppe               : null,
      aufueberlbeginn:         this.aufueberlbeginn         ? this.aufueberlbeginn                : null,
      aufdeckelbetragakt:      this.aufdeckelbetragakt      ? this.aufdeckelbetragakt             : null,
      aufuntersuchidx:         this.aufuntersuchidx         ? this.aufuntersuchidx                : null,
      aufgefahrarbeit:         this.aufgefahrarbeit         ? this.aufgefahrarbeit                : null,
      auftarifbranche:         this.auftarifbranche         ? this.auftarifbranche                : null,
      auftarifvertrag:         this.auftarifvertrag         ? this.auftarifvertrag                : null,
      filtelefon1:             this.filtelefon1             ? this.filtelefon1                    : null,
      internal_contact_persnr: this.internal_contact_persnr ? this.internal_contact_persnr        : null,

      aufarbs:                 this.aufarbs                 ? this.aufarbs.join('; ')                             : null,
      aufueentfernung:         this.aufueentfernung         ? +this.aufueentfernung.replace(',', '.')             : null,
      auffahrbetrag:           this.auffahrbetrag           ? +this.auffahrbetrag.replace(',', '.')               : null,
      aufvmabetrag:            this.aufvmabetrag            ? +this.aufvmabetrag.replace(',', '.')                : null,
      aufuebernbetrag:         this.aufuebernbetrag         ? +this.aufuebernbetrag.replace(',', '.')             : null,
      pel705preis:             this.pel705preis             ? +this.pel705preis.replace(',', '.')                 : null,
      txt_sonstigeZulage:      this.txt_sonstigeZulage      ? +this.txt_sonstigeZulage.replace(',', '.')          : null,
      aufarbzeitwoche:         this.aufarbzeitwoche         ? +(this.aufarbzeitwoche as string).replace(',', '.') : null,
    });
  }

  formatPayValue(val: any): string {
    return isNaN(val) ? val : val.toFixed(2).replace('.', ',');
  }

}
