import { Component } from '@angular/core';
import { Router    } from '@angular/router';
import { AuthService } from '@shared/services';

@Component({
  templateUrl: './blank.component.html',
})

export class BlankComponent {
  constructor(
    private router:      Router,
    private authService: AuthService,
  ) {}
  ngOnInit(): void {
    if (this.authService.accessTokenValue) this.router.navigate(['/time-tracking/home']);
    else if (this.router.url === '/') {
      if (localStorage.getItem('firstLogin')) this.router.navigate(['/auth/login']);
      else this.router.navigate(['/auth/start-screen']);
    }
  }
}
