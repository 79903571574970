import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { NotificationService, SalaryReportsService, SessionStorageService, UserService } from '@shared/services';
import { slideUp } from '@shared/animations';

@Component({
  selector:      'salary-two-fa',
  templateUrl: './salary-2fa.component.html',
  host: { class: 'absolute bottom-0 left-0 right-0 du-flex-column btl-radius btr-radius absolute bg-white z-2'},
  animations: [ slideUp ],
})
export class Salary2FAComponent implements OnInit, OnDestroy {
  day:   number;
  month: number;
  year:  number;

  @HostBinding('@slideUp') get slideIn() { return 'open'; }
  constructor(
    private router:                Router,
    private salaryReportsService:  SalaryReportsService,
    private sessionStorageService: SessionStorageService,
    private notificationService:   NotificationService,
    public  userService:           UserService
  ) {}

  ngOnInit(): void {
    this.sessionStorageService.pushOverflowStack('Salary2FA');
    if (!this.userService.salaryTwoFactorTokenValue) {
      this.notificationService.wait();
      this.salaryReportsService.requestSalaryReportsToken().subscribe(
        ()  => this.notificationService.close(),
        err => this.notificationService.alert(err)
      );
    }
  }

  ngOnDestroy(): void {
    this.sessionStorageService.popOverflowStack('Salary2FA');
  }

  birthDayInputHandler({ el, value }: { el: string, value: string }) {
    (this as any)[el] = value;
  }

  submit(): void {
    this.notificationService.wait();
    this.salaryReportsService.createSalaryReportsToken(`${this.formatExtraZero(this.day)}${this.formatExtraZero(this.month)}${this.year}`).subscribe(
      token => {
        if (token) this.userService.setSalaryTwoFactorToken(token);
        this.router.navigate(['time-tracking/salary-overview']);
        this.notificationService.close();
        this.close();
      },
      err => this.notificationService.alert(err)
    )
  }

  formatExtraZero(num: number): string | number {
    return +num > 9 ? num : '0' + +num;
  }

  close(): void {
    this.sessionStorageService.popDynamicComponent('Salary2FA');
  }

}
