<div class="du-flex-column" [@collapse] *ngIf="offline || backOnline">
  <div class="du-flex-column overflow">
    <div class="du-flex-justify du-flex-align pl-xl pr-xl pt-s pb-s animate-bg" [ngClass]="backOnline ? 'bg-green color-white' : 'bg-light-orange color-orange'">
      <div class="du-flex-align gap-s">
        <span class="du-icon-offline font-icon"></span>
        <span *ngIf="!backOnline" translate="youAreOffline"></span>
        <span *ngIf="backOnline" translate="backOnline"></span>
      </div>
      <span class="du-icon-close" (click)="offline = false"></span>
    </div>

    <div @collapse *ngIf="offline">
      <span class="du-flex-center overflow pl-60 pr-xl pt-xs pb-xs bg-grey font-s lh-l" translate="youWillBeSynced"></span>  
    </div>
  </div>
</div>
