import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { PhotoDocument, DocumentType, PushNotificationUnread, PhotoDocumentLocal } from '@shared/factories';
import { DocumentTypeModel, PagingModel, PillSectionModel, SortModel } from '@shared/models';
import { NotificationService, SessionStorageService, PhotoDocumentsService, DBService } from '@shared/services';
import { from, map, of, switchMap } from 'rxjs';

@Component({
  selector:      'documents-overview',
  templateUrl: './documents-overview.component.html',
  host: { class: 'p-xl gap-xl' }
})
export class DocumentsOverviewComponent implements OnInit {
  page:  number;
  pills: PillSectionModel[];
  sort:  SortModel[] = [
    { label: 'latest',       column: 'created_at', active: true },
    { label: 'alphabetical', column: 'name' },
  ];

  tabs: any[] = [
    { label: 'Submitted', value: 0, color: 'orange' },
    { label: 'Read',      value: 1, color: 'green'  },
  ];

  documents: (PhotoDocument | PhotoDocumentLocal)[] = [];
  unreadPn:  PushNotificationUnread[] = [];
  paging:    PagingModel;
  constructor(
    private location:              Location,
    private dbService:             DBService,
    private photoDocumentsService: PhotoDocumentsService,
    private sessionStorageService: SessionStorageService,
    private notificationService:   NotificationService
  ) {}

  ngOnInit(): void {
    this.prepareHeader();
    this.sessionStorageService.paging.subscribe(paging => this.paging = paging);

    this.notificationService.wait();
    from(this.dbService.loadMultipleFromDB('unreadPn')).subscribe(
      pns => {
        this.unreadPn = pns.map(pn => new PushNotificationUnread(pn)).filter(e => e.sourceType === 'photo_documents');
        this.loadDocuments();
        this.loadDocumentTypes();
      }
    );
  }

  private prepareHeader(): void {
    this.sessionStorageService.setHeaderTitle('sentDocument');
    this.sessionStorageService.setHeaderControls({ left: [{ icon: 'arrow-big-left color-blue font-icon', callback: () => this.location.back() }] });
    this.sessionStorageService.setProgressBar(null);
  }

  loadDocuments(reload: boolean = null): void {
    if (!this.paging || !this.paging.total_pages || !this.page || this.page < this.paging.total_pages || reload) {
      this.page = reload || !this.page ? 1 : this.page+1;
      this.notificationService.wait();
      this.photoDocumentsService.loadPhotoDocuments(this.page).pipe(
        switchMap(documents => {
          if (this.page === 1) return from(this.dbService.loadMultipleFromDB('pendingUpload', { useCase: 'pd' })).pipe(
            map(pendingPd => pendingPd.map(vr => new PhotoDocumentLocal(vr))),
            map(pendingPd => [...pendingPd, ...documents])
          );
          else return of(documents);
        })
      ).subscribe(
        documents => {
          if (reload) this.documents = [...documents];
          else this.documents = [...this.documents, ...documents];
          this.sessionStorageService.setCachedEntries(this.documents);
          this.notificationService.close();
        },
        err => this.notificationService.alert(err),
        () => this.sessionStorageService.setPulltoRefreshState(true)
      );
    }
  }

  private loadDocumentTypes(): void {
    this.dbService.loadAllFromDB('documentTypes')
    .then((res: DocumentTypeModel[]) => res.filter(t => t.active !== false).map(a => new DocumentType(a)))
    .then(types => this.pills = [{
      label: 'Dokumenttyp',
      field: 'subject',
      pills: types.map(t => ({
        label: t.name,
        value: t.name,
      }))
    }]);
  }

}
