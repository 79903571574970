<div class="du-flex-justify du-flex-align bb-grey">
  <img  class="pl-xl pr-xl pt-m pb-m mra" src="assets/svg/arrow-left.svg" (click)="close()">
  <span class="absolute left-70 right-70 du-flex-center color-grey bold" translate="sortBy"></span>
</div>

<div class="du-flex-column gap-xl p-xl">
  <div class="du-flex-column gap-m">
    <span class="bold" translate="specifications"></span>
    <ul class="du-flex-column gap-xs" >
      <li *ngFor="let sort of entries" (click)="sortBy(sort)" class="du-flex-align du-flex-justify">
        <span>{{ sort.label | translate }}</span>
        <span class="p-xs">
          <img src="assets/svg/circle.svg"     *ngIf="!sort.active">
          <img src="assets/svg/circle-dot.svg" *ngIf="sort.active">
        </span>
      </li>
    </ul>
  </div>

  <button class="btn-primary btn-round" translate="showResults" (click)="applySort()"></button>
</div>
