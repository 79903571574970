import { trigger, transition, style, animate } from '@angular/animations';

export const slideUp = trigger('slideUp', [
  transition(':leave', [
    style({ bottom: 0 }),
    animate(350, style({ bottom: '-100%' }))
  ]), 
  transition(':enter', [
    style({ bottom: '-100%' }),
    animate(250, style({ bottom: 0 }))
  ])
]);
