<div class="du-flex-justify du-flex-align bb-grey">
  <img  class="pl-xl pr-xl pt-m pb-m mra" src="assets/svg/arrow-left.svg" (click)="close()">
  <span class="absolute left-70 right-70 du-flex-center color-grey bold" translate="assignment"></span>
</div>

<div class="du-flex-column pl-xl pt-xl pr-xl">
  <div class="relative width-full">
    <input type="text" class="placeholder-primary-grey pl45-i" [(ngModel)]="search" placeholder="Search">
    <img src="assets/svg/search.svg" class="absolute-top-center ml-m">
  </div>
</div>

<div class="du-flex-column gap-m p-xl overflow">
  <ul class="du-flex-1 du-flex-column gap-m overflow-auto">
    <li *ngFor="let entry of getFilteredAssignments()"
        class="b-radius p-m pr-m pt-s pb-s lh-l"
        [ngClass]="assignment && assignment.id === entry.id ? 'bg-blue color-white' : 'bg-grey'"
        (click)="selectHandler(entry)">
      {{ entry.title }}
    </li>
  </ul>
  <span *ngIf="!getFilteredAssignments().length" class="du-flex-center p-m color-disabled">No results were found.</span>

  <button class="btn-primary btn-round du-flex-auto" translate="apply" (click)="close()" [disabled]="!getFilteredAssignments().length"></button>
</div>
