import { Injectable, Injector } from '@angular/core';
import { formatDate           } from '@angular/common';
import { Observable, defaultIfEmpty, forkJoin, from, switchMap, take, tap } from 'rxjs';

import { ActivityReportLocalModel, PhotoDocumentLocalModel, VacationRequestLocalModel } from '@shared/models';
import { ActivityReportLocal, ActivityReportOverview, PhotoDocument, PhotoDocumentLocal, VacationRequestLocal, VacationRequestOverview } from '@shared/factories';

import { DBService               } from './db.service'
import { ActivityReportsService  } from './activity-reports.service';
import { VacationRequestsService } from './vacation-requests.service';
import { PhotoDocumentsService   } from './photo-documents.service';

@Injectable({
  providedIn: 'root'
})
export class PendingService {
  constructor(
    private dbService: DBService,
    private injector:  Injector
  ) { }

  syncPendingList(): Observable<[ActivityReportOverview[], VacationRequestOverview[], PhotoDocument[]]> {
    return from(this.dbService.loadMultipleFromDB('pendingUpload')).pipe(
      take(1),
      switchMap(list => {
        let ar = this.prepareActivityReports(list);
        let vr = this.prepareVacationRequests(list);
        let pd = this.preparePhotoDocuments(list);
        return forkJoin(ar, vr, pd).pipe(defaultIfEmpty(null));
      }),
      tap(()  => console.log(formatDate(new Date(), 'h:mm:ss:SSS dd.MM.yyyy', 'en-US'), '|', `INFO: Pending upload entries was synced`),
          err => console.log(err)
      )
    );
  }

  private prepareActivityReports(list: ActivityReportLocalModel[]): Observable<ActivityReportOverview[]> {
    const activityReportsService = this.injector.get(ActivityReportsService);
    let items = list.filter(vr => vr.useCase === 'ar' && vr.notSynced).map(ar => new ActivityReportLocal(ar));
    return forkJoin(items.map(ar => activityReportsService.submitPendingActivityReport(ar))).pipe(defaultIfEmpty(null));
  }

  private prepareVacationRequests(list: VacationRequestLocalModel[]): Observable<VacationRequestOverview[]> {
    const vacationRequestsService = this.injector.get(VacationRequestsService);
    let items = list.filter(vr => vr.useCase === 'vr' && vr.notSynced).map(vr => new VacationRequestLocal(vr));
    return forkJoin(items.map(vr => vacationRequestsService.submitPendingVacationRequest(vr))).pipe(defaultIfEmpty(null));
  }

  private preparePhotoDocuments(list: PhotoDocumentLocalModel[]): Observable<PhotoDocument[]> {
    const photoDocumentsService = this.injector.get(PhotoDocumentsService);
    let items = list.filter(pd => pd.useCase === 'pd' && pd.notSynced).map(pd => new PhotoDocumentLocal(pd));
    return forkJoin(items.map(pd => photoDocumentsService.submitPendingPhotoDocument(pd))).pipe(defaultIfEmpty(null));
  }

}
