<div class="du-flex-justify ml-xl mr-xl height-50 shadow overflow" [routerLink]="['/time-tracking/report-constructor-t']">
  <div class="du-flex-align gap-m pt-s pb-s pl-m pr-m">
    <img src="assets/svg/template-black.svg">
    <span class="font-s font-500" translate="createTemplate"></span>
  </div>
  <span class="du-flex-center pl-l pr-l height-full bg-blue-gradient">
    <img src="assets/svg/arrow-right-white.svg">
  </span>
</div>

<div class="du-flex gap-s pl-xl pr-xl overflow-x-auto" *ngIf="templates && templates.length">
  <div class="du-flex-column du-flex-full overflow shadow relative" *ngFor="let template of templates; let i = index">
    <div class="du-flex-justify p-m bg-blue-gradient relative" (click)="useTemplate(i)">
      <span class="color-white">{{ template.name }}</span>
      <img  src="assets/svg/more-white.svg" (click)="toggleOptions(i, $event)">
      <div class="absolute right-0 top-0 mt-xl du-flex-column b-radius bg-card-grey z-1" *ngIf="optionsOpen === i">
        <span class="du-flex-align gap-s pt-s pb-s pl-m pr-m bb-grey hover-active" (click)="editTemplate(i, $event)">
          <img src="assets/svg/edit-black.svg">
          <span translate="edit"></span>
        </span>
        <span class="du-flex-align gap-s pt-s pb-s pl-m pr-m hover-active" (click)="deleteTemplatePrompt(i, $event)">
          <img src="assets/svg/bin-black.svg">
          <span translate="delete"></span>
        </span>
      </div>
    </div>
    <div class="du-flex-justify du-flex-align gap-m p-s">
      <div class="du-flex-column gap-xs">
        <span class="font-s" translate="days"></span>
        <span class="du-flex-align gap-xxs bold">
          <span *ngFor="let day of template.dailies; let i = index">{{ day.startsAt | date: 'EEE' }}{{ i+1 < template.dailies.length ? ',' : '' }}</span>
        </span>
      </div>
      <img class="rotate" src="assets/svg/arrow-left.svg">
    </div>
  </div>
</div>
