import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PDFPageProxy } from 'pdfjs-dist';

@Component({
  selector:      'salary-details-item',
  templateUrl: './salary-details-item.component.html',
  host: { class: 'du-flex-column height-full' }
})
export class SalaryDetailsItemComponent implements OnInit {
  @Input()  page:       PDFPageProxy;
  @Input()  pageNum:    number;
  @Input()  totalPages: number;
  @Output() load = new EventEmitter<void>;

  image: string;
  constructor( private container: ElementRef) {}

  ngOnInit(): void {
    setTimeout(() => this.renderPage());
  }

  async renderPage() {
    let scale    = 3;
    let viewport = this.page.getViewport({ scale });
    let canvas   = <HTMLCanvasElement>document.getElementById(`my-canvas-${this.pageNum}`);
    let context  = canvas.getContext('2d');
    
    canvas.height = viewport.height;
    canvas.width  = viewport.width;

    return Promise.resolve()
    .then(() => this.page.render({ canvasContext: context, viewport: viewport }).promise)
    .then(() => this.image = canvas.toDataURL('image/png'))
    .then(() => setTimeout(() => this.prepareImage(canvas)));
  }

  prepareImage(canvas: HTMLCanvasElement): void {
    let height  = this.container.nativeElement.offsetHeight;
    let width   = this.container.nativeElement.offsetWidth ;
    let image   = document.getElementById(`pinch-zoom-image-id-${this.pageNum}`);
  
    if (canvas.height > height || canvas.width > width) {
      if (canvas.height > canvas.width) {
        width  = Math.min(canvas.width  / (canvas.height / height), width);
        height = width * (canvas.height / canvas.width);
      }
      else {
        height = Math.min(canvas.height / (canvas.width / width), height);
        width  = height * (canvas.width / canvas.height);
      }
    }
    image.style.minHeight = `${height}px`;
    image.style.height    = `${height}px`;
    image.style.minWidth  = `${width}px`;   
    image.style.width     = `${width}px`;
  }

}
