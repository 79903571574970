import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';

import { SessionStorageService } from '@shared/services';
import { slideRight } from '@shared/animations';

@Component({
  selector:      'tutorials-list',
  templateUrl: './tutorials-list.component.html',
  host: { class: 'absolute-full-height left-0 width-300 du-flex-column gap-xl btr-radius bbr-radius pl-xl pr-xl bg-white' },
  animations: [ slideRight ]
})
export class TutorialsListComponent implements OnInit, OnDestroy {
  tutorials: any[] = [
    { useCase: 'ar', label: 'activityReport'  },
    { useCase: 'dr', label: 'dailyReport'     },
    { useCase: 'do', label: 'activityReport'  },
    { useCase: 'pd', label: 'photoDocuments'  },
    { useCase: 't',  label: 'activityReport'  },
    { useCase: 'vr', label: 'vacationRequest' },
  ];
  @HostBinding('@slideRight') get slideIn() { return 'open'; }
  private readonly COMPONENT_NAME: string = 'TutorialsList';
  constructor(
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.sessionStorageService.pushOverflowStack(this.COMPONENT_NAME);
  }

  ngOnDestroy(): void {
    this.sessionStorageService.popOverflowStack(this.COMPONENT_NAME);
  }

  close(): void {
    this.sessionStorageService.popDynamicComponent(this.COMPONENT_NAME)
  }

  openTutorial(useCase: string): void {
    this.sessionStorageService.pushDynamicComponent({
      component: 'TutorialScreen',
      props: {
        useCase,
        fullscreen: true
      }
    });
  }

}
