import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { SubscriptionLike } from 'rxjs';

import { SessionStorageService } from '@shared/services';
import { slideDown } from '@shared/animations';

@Component({
  selector:      'progress-bar',
  templateUrl: './progress-bar.component.html',
  host: { class: 'relative bg-progress-blue animate-height', '[class.height-0]': '!visible'},
})
export class ProgressBarComponent implements OnInit, OnDestroy {
  subscription:  SubscriptionLike;
  progressWidth: number;
  @HostBinding('style.height') height = '0px';
  constructor( private sessionStorageService: SessionStorageService ) {}

  ngOnInit() {
    this.subscription = this.sessionStorageService.progressBar.subscribe(bar => {
      setTimeout(() => {
        if (!this.progressWidth && bar) this.height = '12px';
        if (!bar) this.height = '0px';
      });
      setTimeout(() => {
        this.progressWidth = bar;
      }, 200)
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.sessionStorageService.setProgressBar(null);
  }

  get visible(): boolean {
    return this.progressWidth !== null && this.progressWidth !== undefined && !isNaN(this.progressWidth);
  }
}
