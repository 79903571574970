<div class="du-flex-half">
  <language-toggler color="blue" class=""></language-toggler>
</div>

<span class="du-flex-column du-flex-third text-center gap-s lh-l">
  <span translate="newVersionAvailable"></span>
  <span translate="downloadLatestApp"></span>
</span>

<button class="btn-primary btn-round width-200" (click)="openStore()" translate="update"></button>
