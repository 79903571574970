import { Component, Input } from '@angular/core';
import { VacationRequestLocal, VacationRequestOverview } from '@shared/factories';

@Component({
  selector:      'vacations-overview-item',
  templateUrl: './vacations-overview-item.component.html',
})
export class VacationsOverviewItemComponent {
  @Input() vacation: VacationRequestOverview | VacationRequestLocal;
  @Input() extended: boolean;

  getLocalVacation(): VacationRequestLocal {
    return this.vacation as VacationRequestLocal;
  }

  getOverviewVacation(): VacationRequestOverview {
    return this.vacation as VacationRequestOverview;
  }

}
