import { CommonModule    } from '@angular/common';
import { NgModule        } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { SignaturePadComponent } from '@shared/components/dynamic';
import {
  InfoComponent,
  LanguageTogglerComponent,
  ProgressBarComponent
} from '@shared/shared';

@NgModule({
  imports:      [ CommonModule, TranslateModule ],
  declarations: [ LanguageTogglerComponent, ProgressBarComponent, InfoComponent, SignaturePadComponent ],
  exports:      [ LanguageTogglerComponent, ProgressBarComponent, InfoComponent, SignaturePadComponent ],
})
export class SharedComponentsModule { }