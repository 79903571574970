import { EmployeeBasicModel, EmployeeModel } from "@shared/models";

export class EmployeeBasic {
  id: number;
  first_name: string;
  last_name: string;
  constructor(data: EmployeeBasicModel) {
    this.id = data.id;
    this.first_name = data.first_name;
    this.last_name = data.last_name;
  }

  get name(): string {
    return `${this.first_name} ${this.last_name}`;
  }

  get nameReverse(): string {
    return `${this.last_name}, ${this.first_name}`;
  }

  toJSON(): EmployeeBasicModel {
    return {
      id: this.id,
      first_name: this.first_name,
      last_name: this.last_name
    };
  }
}

export class Employee extends EmployeeBasic {
  email: string;
  personal_number: number;
  phone_number: string;
  constructor(data: EmployeeModel) {
    super(data);
    this.email = data.email;
    this.personal_number = data.personal_number;
    this.phone_number = data.phone_number;
  }
  
  toJSON(): EmployeeModel {
    return Object.assign(super.toJSON(), {
      email: this.email,
      personal_number: this.personal_number,
      phone_number: this.phone_number
    });
  }
}

export class ExternalEmployee extends Employee { 
  constructor(data: any) {
    super(data);
  }
}

export class InternalEmployee extends Employee { 
  constructor(data: any) {
    super(data);
  }
}

export class Customer extends Employee { 
  constructor(data: any) {
    super(data);
  }
}

export class CustomerEmailListItem extends Customer {
  active: boolean;
  constructor(data: EmployeeModel | CustomerEmailListItem) {
    super(data);
    this.active = (data as CustomerEmailListItem).active || false;
  }
}
