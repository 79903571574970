<div class="du-flex-justify du-flex-align bb-grey">
  <img  class="pl-xl pr-xl pt-m pb-m mra" src="assets/svg/arrow-left.svg" (click)="close()">
  <span class="absolute left-70 right-70 du-flex-center color-grey bold" translate="dataPrivacy"></span>
</div>
<div class="du-flex-column gap-xl pt-m pl-xl pr-xl pb-xl">
  <span class="font-s lh-l">
    <span translate="pricyTextOne"></span>
    <span class="pl-xxs bold underline color-blue" translate="dataPrivacy" (click)="openPrivacy()"></span>
    <span translate="pricyTextTwo"></span>
    <span class="pl-xxs bold underline color-blue" (click)="sendEmail('mailto:datenschutz@tempton.de')">datenschutz@tempton.de</span>
  </span>

  <div class="du-flex-align" (click)="privacy = !privacy">
    <span class="height-45 width-45 p-s">
      <img src="assets/svg/square.svg"      *ngIf="!privacy">
      <img src="assets/svg/square-tick.svg" *ngIf="privacy">
    </span>
    <span class="font-s lh-l" [innerHtml]="'iAcceptPrivacy' | translate"></span>
  </div>

  <button class="btn-primary btn-round" translate="done" (click)="acceptPrivacy()" [disabled]="!privacy"></button>
</div>
