import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router     } from '@angular/router';
import { Observable, catchError, forkJoin, from, lastValueFrom, of, switchMap } from 'rxjs';

import { Capacitor } from '@capacitor/core';

import { TranslateService    } from '@ngx-translate/core';

import { User                } from '@shared/factories';
import { NotificationService } from './notification.service';

import { environment         } from 'environments/environment';
import { FirebaseMessaging, Notification } from '@capacitor-firebase/messaging';

interface TemptonNotification extends Notification {
  aps:           any;
  deep_link:     string;
  assignment_id: number;
}

@Injectable({
  providedIn: 'root'
})
export class PushNotificationsService {
  private readonly PN_API_OLD = `${environment.apiUrl}/api/v2/push_notifications`;
  private readonly PN_API     = `${environment.apiUrl}/api/mobile/v3/push_notifications`;
  private readonly PN_FCM_API = `${environment.apiUrl}/time_tracking/api/v3/push_notification_targets`;

  constructor(
    private http:                HttpClient,
    private router:              Router,
    private translateService:    TranslateService,
    private notificationService: NotificationService,
  ) { }

  init(): void {
    if (Capacitor.isNativePlatform()) {
      console.log('requestPermissions');
      this.addListeners().pipe(
        switchMap(() => FirebaseMessaging.requestPermissions()),
        switchMap(result => {
          if (result.receive === 'granted') return from(FirebaseMessaging.getToken());
          else return of(null);
        }),
        switchMap(res => {
          console.log(res?.token);
          
          if (res?.token) return this.setFCMToken(res.token);
          else return of(null);
        }),
        catchError(err => {
          console.log(err);
          return err;
        }),
      ).subscribe((res: any) => {
        this.getDeliveredNotifications().subscribe(res => console.log(res))
      });
    }
  }

  private addListeners(): Observable<any> {
    return forkJoin([
      from(FirebaseMessaging.addListener('notificationReceived', notification => { console.log('Push notification received: ', notification); })),
      from(FirebaseMessaging.addListener('notificationActionPerformed', notification => { console.log('Push notification action performed', notification.actionId, notification.inputValue); })),
      // PushNotifications.addListener('pushNotificationActionPerformed', (action:       ActionPerformed)        => this.onNotification(action.notification as TemptonPushNotificationSchema) );
    ]);
  }

  private getDeliveredNotifications(): Observable<any> {
    return from(FirebaseMessaging.getDeliveredNotifications());
  }

  private setFCMToken(token: string): Observable<any> {
    console.log('setFCMToken', token);
    return this.http.put<any>(this.PN_FCM_API, { device_fcm_token: token });
  }

  subscribeUser(  user: User): Observable<any> { return Capacitor.isNativePlatform() ? this.subscribeUserHandler(true,  user) : of({}); }
  unSubscribeUser(user: User): Observable<any> { return Capacitor.isNativePlatform() ? this.subscribeUserHandler(false, user) : of({}); }

  private subscribeUserHandler(subscribe: boolean, user: User): Observable<any> {
    let calls = [of({})];
    if (user.pnTargets?.user_email_topic) calls.push(from(this.subscribeHandler(subscribe, user.pnTargets.user_email_topic)));
    if (user.pnTargets?.user_group_topic) calls.push(from(this.subscribeHandler(subscribe, user.pnTargets.user_group_topic)));
    return forkJoin(calls);
  }

  private subscribeHandler(subscribe: boolean, topic: string) {
    if (subscribe) return this.subscribe(topic);
    else return this.unSubscribe(topic);
  }

  private subscribe(  topic: string): Promise<any> { return FirebaseMessaging.subscribeToTopic({     topic }).catch((err) => console.log(err)); }
  private unSubscribe(topic: string): Promise<any> { return FirebaseMessaging.unsubscribeFromTopic({ topic }).catch((err) => console.log(err)); }

  private onNotification(notification: TemptonNotification): void {
    if (!notification.title && notification.aps && notification.aps.alert && notification.aps.alert.title) notification.title = notification.aps.alert.title;
    if (!notification.body  && notification.aps && notification.aps.alert && notification.aps.alert.body)  notification.body  = notification.aps.alert.body;
    this.actionHandler(notification);
  }

  private actionHandler(notification: TemptonNotification): any {
    switch (notification.clickAction) {
      case 'confirm_reading':
        return this.confirm_reading(notification);
      case 'open_link':
        return this.open_link(notification);
      case 'open_project':
        return this.open_project(notification);
      default:
        break;
    }
  }

  markAsRead(notificationId: number): Observable<any> {
    return this.http.put<any>(`${this.PN_API}/${notificationId}/mark_read`, {});
  }

  private confirm_reading(notification: TemptonNotification): Observable<any> {
    return this.http.put<any>(`${this.PN_API_OLD}/${notification.id}/mark_read`, {});
  }

  private open_link(notification: TemptonNotification): void {
    this.notificationService.confirm({
      title: notification.title,
      desc:  notification.body,
      buttons: { left: 'cancel', right: 'open' },
      rightFn: () => this.openLink(notification)
    });
  }

  private openLink(notification: TemptonNotification): void {
    if (notification.deep_link && notification.deep_link.startsWith("#")) this.router.navigateByUrl(notification.deep_link.replace('#', '/time-tracking'));
    this.confirm_reading(notification);
  }

  private async open_project(notification: TemptonNotification) {
    if (!notification.title) (notification.title as any) = await lastValueFrom(this.translateService.get('defaultEbsPushTitle'));
    if (!notification.body)  (notification.body as any)  = await lastValueFrom(this.translateService.get('defaultEbsPushBody'));

    this.notificationService.confirm({
      title: notification.title,
      desc:  notification.body,
      buttons: { left: 'cancel', right: 'open' },
      leftFn:  () => console.log('skip'), //add sync func
      rightFn: () => this.openProject(notification)
    });
  }

  private openProject(notification: TemptonNotification): void {
    notification.deep_link = `/ebs-details/${notification.assignment_id}`;
    this.openLink(notification);
  }

}
