<div class="du-flex-column gap-m du-flex-1 overflow-auto" *ngIf="report">
  <ul class="du-flex-column gap-m p-xl">
    <daily-overview-item [daily]="daily" *ngFor="let daily of report.activeDailyReports"></daily-overview-item>
  </ul>
</div>

<div class="du-flex-column gap-xl pt-s pl-xl pr-xl pb-xl bt-grey" *ngIf="report">
  <div class="du-flex-column gap-s">
    <span class="du-flex-align du-flex-justify">
      <span translate="totalWorkingHours"></span>
      <span class="font-l bold">{{ report && report.durationWithPauses || 0 | numberToTime }}</span>
    </span>
    <span class="du-flex-align du-flex-justify">
      <span translate="totalWorkingHoursWithoutBreaks"></span>
      <span class="font-l bold">{{ report && report.durationExludingPauses || 0 | numberToTime }}</span>
    </span>
  </div>

  <button class="width-full btn-primary btn-round" translate="next" (click)="callbackHandler()"></button>
</div>
