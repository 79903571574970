import { Component,ViewContainerRef, OnInit, NgZone, ComponentRef, ViewChildren, QueryList } from '@angular/core';
import { SubscriptionLike } from 'rxjs';

import {
  CustomerEmailBoxComponent,
  Salary2FAComponent,
  SignatureBoxComponent,
  DailyReportConstructorComponent,
  TutorialsListComponent,
  LanguageListComponent,
  VacationDetailsComponent
} from '@shared/components';

import {
  SignaturePadComponent,
  AssignmentSelectBoxComponent,
  FilterComponent,
  SortComponent,
  MileageConfigBoxComponent,
  PrivacyBoxComponent,
  TutorialScreenComponent,
  TemplateDetailsComponent
} from '@shared/components/dynamic';

import { SessionStorageService } from '@shared/services';
import { SidebarComponent      } from '@shared/shared';

interface LoaderData {
  component: string;
  type:      string;
  props:     any;
}

@Component({
  selector:      'component-loader',
  templateUrl: './component-loader.component.html'
})
export class ComponentLoaderComponent implements OnInit {
  subscriptions: SubscriptionLike[] = [];
  @ViewChildren('dynamic', { read: ViewContainerRef }) dynamic: QueryList<ViewContainerRef>;
  componentsArray: string[] = [];
  constructor(
    private zone:                  NgZone,
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(this.sessionStorageService.dynamicComponent.subscribe(data => {
      if (data) {
        if (data.type === 'create') this.addComponent(data);
        if (data.type === 'remove') this.removeComponent(data);
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions = [];
  }

  private addComponent(data: LoaderData): void {
    this.componentsArray.push(data.component);
    setTimeout(() => {
      let compRef = this.dynamic.get(this.dynamic.length-1).createComponent(this.getComponent(data.component));
      compRef.location.nativeElement.style.zIndex = this.componentsArray.length+1;
      this.setProps(compRef, data.props);
    });
  }

  private removeComponent(data: LoaderData): void {
    let el = this.componentsArray.findIndex(c => c === data.component);
    if (el !== -1) {
      this.dynamic.get(el).clear();
      this.componentsArray.splice(el, 1);
    }
  }

  private setProps(compRef: ComponentRef<any>, props: any): void {
    if (props) {
      this.zone.run(() => {
        Object.keys(props).forEach(key => {
          if (props[key] instanceof Function) compRef.instance[key].subscribe(props[key]);
          else Object.defineProperty(compRef.instance, key, {
            value:        props[key],
            writable:     true,
            configurable: true,
            enumerable:   true
          });
        });
      });
    }
  }

  private getComponent(comp: string): any {
    switch (comp) {
      case 'Sidebar':
        return SidebarComponent;
      case 'Salary2FA':
        return Salary2FAComponent;
      case 'SignatureBox':
        return SignatureBoxComponent;
      case 'SignaturePad':
        return SignaturePadComponent;
      case 'CustomerEmailBox':
        return CustomerEmailBoxComponent;
      case 'DailyReport':
        return DailyReportConstructorComponent;
      case 'AssignmentSelectBox':
        return AssignmentSelectBoxComponent;
      case 'MileageConfigBox':
        return MileageConfigBoxComponent;
      case 'PrivacyBox':
        return PrivacyBoxComponent;
      case 'Filter':
        return FilterComponent;
      case 'Sort':
        return SortComponent;
      case 'TutorialScreen':
        return TutorialScreenComponent;
      case 'TemplateDetails':
        return TemplateDetailsComponent;
      case 'TutorialsList':
        return TutorialsListComponent;
      case 'LanguageList':
        return LanguageListComponent;
      case 'VacationDetails':
        return VacationDetailsComponent;
      default:
        break;
    };
  }

}
