import { Component, EventEmitter, HostBinding, OnDestroy, OnInit, Output } from '@angular/core';
import { SessionStorageService, UserService } from '@shared/services';
import { slideUp } from '@shared/animations';

@Component({
  selector:      'signature-box',
  templateUrl: './signature-box.component.html',
  host: { class: 'absolute bottom-0 left-0 right-0 du-flex-column btl-radius btr-radius absolute bg-white z-2'},
  animations: [ slideUp ],
})
export class SignatureBoxComponent implements OnInit, OnDestroy {
  signature: string;
  signer:    string;
  @HostBinding('@slideUp') get slideIn() { return 'open'; }
  @Output() save          = new EventEmitter<any>;
  @Output() closeCallback = new EventEmitter<any>;
  constructor(
    private sessionStorageService: SessionStorageService,
    public  userService:           UserService
  ) {}

  ngOnInit(): void {
    this.sessionStorageService.pushOverflowStack('SignatureBox');
  }

  ngOnDestroy(): void {
    this.sessionStorageService.popOverflowStack('SignatureBox');
  }

  openSignaturePad(): void {
    this.sessionStorageService.pushDynamicComponent({
      component: 'SignaturePad',
      props: {
        signature: this.signature,
        callback: (signature: string) => this.signature = signature
      }
    });
  }

  submit(): void {
    this.save.emit({
      signature: this.signature,
      signer:    this.signer
    });
    this.close();
  }

  close(): void {
    this.sessionStorageService.popDynamicComponent('SignatureBox');
    this.closeCallback.emit();
  }
}
