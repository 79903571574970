import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Release } from '@shared/factories';

import { NotificationService, ReleasesService, SessionStorageService } from '@shared/services';

@Component({
  selector:      'releases',
  templateUrl: './releases.component.html',
  host: { class: 'height-full' }
})
export class ReleasesComponent implements OnInit {
  releases: Release[] = [];
  page:     number    = 0;
  constructor(
    private router:                Router,
    private releasesService:       ReleasesService,
    private notificationService:   NotificationService,
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.sessionStorageService.setHeaderTitle('releases');
    this.sessionStorageService.setHeaderControls({ left: [{ icon: 'arrow-big-left color-blue font-icon', callback: () => this.router.navigateByUrl('time-tracking/home') }] });
    this.sessionStorageService.setProgressBar(null);
    this.loadReleases();
  }


  loadReleases(): void {
    this.notificationService.wait();
    this.page = this.page ? this.page+1 : 1;
    this.releasesService.syncReleases(this.page).subscribe(
      releases => {
        this.releases = [...this.releases, ...releases];
        this.notificationService.close();
      },
      err => this.notificationService.alert(err)
    );
  }

}
