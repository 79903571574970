import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { map, take, tap } from 'rxjs/operators';

import { DocumentType                 } from '@shared/factories';
import { DocumentTypeModel, MetaModel } from '@shared/models';
import { DBService } from './db.service'

import { environment } from 'environments/environment';

interface DocumentTypesResponce {
  document_types: DocumentTypeModel[];
  meta:           MetaModel;
}

@Injectable({
  providedIn: 'root'
})
export class DocumentTypesService {
  private DOCUMENT_TYPES_API: string = `${environment.apiUrl}/api/mobile/v3/document_types?page=1`;

  constructor(
    private http:      HttpClient,
    private DBService: DBService,
  ) { }

  private loadDocumentTypes(): Observable<DocumentType[]> {
    return this.http.get<DocumentTypesResponce>(this.DOCUMENT_TYPES_API).pipe(
      take(1),
      map(res => res.document_types),
      map(types => types.map(t => new DocumentType(t)))
    );
  }

  syncDocumentTypes(): Observable<DocumentType[]> {
    return this.loadDocumentTypes().pipe(tap(res => this.DBService.saveMultipleToDB('documentTypes', res)));
  }

}
