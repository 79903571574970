import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimeService {
  constructor() { }

  setHours(from: Date, to: Date): Date {
    from.setHours(to.getHours(), to.getMinutes(), to.getSeconds(), 0);
    return new Date(from);
  }

  setDate(from: Date, to: Date): Date {
    from.setFullYear(to.getFullYear());
    from.setMonth(to.getMonth());
    from.setDate(to.getDate());
    return new Date(from);
  }

  setDateByWeekDay(from: Date, weekStart: Date, weekDay: number): Date {
    from.setFullYear(weekStart.getFullYear());
    from.setMonth(weekStart.getMonth());
    from.setDate(weekStart.getDate());
    return new Date(+from + ((weekDay || 7) - 1) * 24*60*60*1000);
  }

}
