import { Component, OnDestroy, OnInit } from '@angular/core';
import { SubscriptionLike, forkJoin, from, map, switchMap, take, tap } from 'rxjs';

import { Assignment, Banner, DocumentType, EBS, Holiday, User } from '@shared/factories';
import {
  UserService,
  AssignmentsService,
  HolidaysService,
  NotificationService,
  BannerService,
  DBService,
  EbsService,
  DocumentTypesService,
  PushNotificationsService,
  SessionStorageService,
  NotificationsOverviewService,
  PendingService
} from '@shared/services';

@Component({
  templateUrl: './full.component.html'
})
export class FullComponent implements OnInit, OnDestroy {
  private subscriptions: SubscriptionLike[] = [];
  initLoad: boolean;
  constructor(
    private userService:                  UserService,
    private sessionStorageService:        SessionStorageService,
    private pendingService:               PendingService,
    private assignmentService:            AssignmentsService,
    private holidaysService:              HolidaysService,
    private bannerService:                BannerService,
    private documentTypesService:         DocumentTypesService,
    private ebsService:                   EbsService,
    private dbService:                    DBService,
    private notificationService:          NotificationService,
    private notificationsOverviewService: NotificationsOverviewService,
    private pushNotificationsService:     PushNotificationsService,
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.sessionStorageService.syncData.subscribe(reload => { if (reload) this.syncData(); }));
    this.syncData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = [];
  }

  private syncData(): void {
    this.notificationService.wait();
    this.userService.getMe().pipe(
      take(1),
      switchMap(res => {
        if (!res) return from(this.dbService.loadLoggedUser()).pipe(
          map(user => new User(user)),
          tap((user: User) => this.userService.setUser(user))
        );
        else return forkJoin([
          this.pendingService.syncPendingList(),
          this.assignmentService.syncAssignments(),
          this.holidaysService.syncHolidays(),
          this.bannerService.syncBanners(),
          this.documentTypesService.syncDocumentTypes(),
          this.ebsService.syncEBS(),
          this.notificationsOverviewService.syncUnreadNotifications(),
          this.pushNotificationsService.subscribeUser(res)
        ]);
      })
    ).subscribe(
      (res: Array<Assignment[] | Holiday[] | Banner[] | DocumentType[] | EBS[]> | User) => {},
      () => {},
      () => {
        this.notificationService.close();
        this.initLoad = true;
        this.sessionStorageService.setPulltoRefreshState(true);
      }
    );
  }

}
