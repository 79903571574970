import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ActivityReportLocal, DailyLocal } from '@shared/factories';
import { fadeIn } from '@shared/animations';

@Component({
  selector:      'day-selector',
  templateUrl: './day-selector.component.html',
  host: { class: 'du-flex-justify gap5 font-12' },
  animations: [ fadeIn ]
})
export class DaySelectorComponent {
  @Input() report:            ActivityReportLocal;
  @Input() activeReportIndex: number;
  @Input() useCase:           string;

  @Output() daySelectCallback = new EventEmitter<number>;
  constructor( ) { }

  dayIsDisabled(daily: DailyLocal): boolean {
    let day = new Date(daily.startsAt);
    day.setHours(0,0,0);
    return day < this.report.startDate || day > this.report.endDate || day > new Date();
  }

}
