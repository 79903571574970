import { Component, EventEmitter, HostBinding, OnDestroy, OnInit, Output } from '@angular/core';

import { HelperService, NotificationService, SessionStorageService, UserService } from '@shared/services';
import { slideUp } from '@shared/animations';
import { Country } from '@shared/models';
import { User    } from '@shared/factories';

import { Countries } from '@assets/countries.data';
@Component({
  selector:      'mileage-config-box',
  templateUrl: './mileage-config-box.component.html',
  host: { class: 'absolute bottom-0 left-0 right-0 du-flex-column btl-radius btr-radius absolute bg-white z-2' },
  animations: [ slideUp ]
})
export class MileageConfigBoxComponent implements OnInit, OnDestroy {
  licensePlate:        string;
  licensePlateCountry: string;
  signature:           string;
  countries:           Country[] = Countries;
  @HostBinding('@slideUp') get slideIn() { return 'open'; }
  private readonly COMPONENT_NAME: string = 'MileageConfigBox';
  constructor(
    private userService:           UserService,
    private helperService:         HelperService,
    private notificationService:   NotificationService,
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.sessionStorageService.pushOverflowStack(this.COMPONENT_NAME);
  }

  ngOnDestroy(): void {
    this.sessionStorageService.popOverflowStack(this.COMPONENT_NAME);
  }

  close(): void {
    this.sessionStorageService.popDynamicComponent(this.COMPONENT_NAME)
  }

  openSignaturePad(): void {
    this.sessionStorageService.pushDynamicComponent({
      component: 'SignaturePad',
      props: {
        signature: this.signature,
        callback: (signature: string) => this.signature = signature
      }
    });
  }

  saveMileage(): void {
    this.notificationService.wait();
    let params = {
      user_license_plate: {
        license_plate: this.licensePlate.trim(),
        country: this.licensePlateCountry,
        signature: this.signature
      }
    };
    this.helperService.changeLicensePlate(params).subscribe(
      () => {
        let user = this.userService.currentUserValue;
        user = new User(Object.assign(user.toJSON(), params));
        this.userService.setUser(user);
        this.notificationService.close();
        this.close();
      },
      err => this.notificationService.alert(err)
    );
  }

}
