import { SalaryReportDocTypeModel, SalaryReportExtendedModel, SalaryReportModel } from "@shared/models";

export class SalaryReport {
  id:        number;
  filename:  string;
  subject:   string;
  createdAt: Date;
  docType:   SalaryReportDocTypeModel;
  constructor(data: SalaryReportModel) {
    this.id        = data.id;
    this.filename  = data.filename;
    this.subject   = data.subject;
    this.createdAt = this.parceDate(data.created_at);
    this.docType   = data.doc_type;
  }

  private parceDate(date: any) {
    return date ? date instanceof Date ? date : new Date(date) : null;
  }

  toJSON(): SalaryReportModel {
    return {
      id:         this.id        ? this.id                      : null,
      filename:   this.filename  ? this.filename                : null,
      subject:    this.subject   ? this.subject                 : null,
      created_at: this.createdAt ? this.createdAt.toISOString() : null,
      doc_type:   this.docType   ? this.docType                 : null,
    };
  }

}

export class SalaryReportExtended extends SalaryReport {
  base64pdf: string;
  constructor(data: SalaryReportExtendedModel) {
    super(data);
    this.base64pdf = data.base64pdf;
  }

  toJSON(): SalaryReportExtendedModel {
    return Object.assign(super.toJSON(), {
      base64pdf: this.base64pdf ? this.base64pdf : null
    });
  }
}