import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin, map, take, tap } from 'rxjs';

import { environment } from 'environments/environment';
import { Assignment, CustomerEmail, MileageData } from '@shared/factories';
import { AssignmentModel, CustomerEmailModel, MileageDataModel } from '@shared/models';
import { DBService } from './db.service'

interface AssignmentsResponse    { assignments:  AssignmentModel[];    }
interface MileageMoneyResponse   { mileage_data: MileageDataModel[];   }
interface CustomerEmailsResponce { customers:    CustomerEmailModel[]; }

@Injectable({
  providedIn: 'root'
})

export class AssignmentsService {
  private readonly ASSIGNMENTS_API   = `${environment.apiUrl}/api/mobile/v3/assignments`;
  private readonly MILEAGE_MONEY_API = `${this.ASSIGNMENTS_API}/mileage_money`;

  constructor(
    private http:      HttpClient,
    private DBService: DBService,
  ) { }

  private loadAssignments(): Observable<Assignment[]> {
    return forkJoin(
      this.http.get<AssignmentsResponse>(`${this.ASSIGNMENTS_API}?order_field=starts_at&order_direction=desc`).pipe(map(res => res.assignments)),
      this.syncMileageMoney()
    ).pipe(
      map((res: Array<AssignmentModel[] | MileageData[]>) => {
        return (res[0] as AssignmentModel[]).map(a => {
          return new Assignment(a, (res[1] as MileageData[]).find(md => md.assignmentId === a.id));
        });
      })
    );
  }

  syncAssignments(): Observable<Assignment[]> {
    return this.loadAssignments().pipe(tap(res => this.DBService.saveMultipleToDB('assignments', res)));
  }

  private loadMileageMoney(): Observable<MileageData[]> {
    return this.http.get<MileageMoneyResponse>(this.MILEAGE_MONEY_API).pipe(
      take(1),
      map(res => res.mileage_data),
      map(mileage_data => mileage_data.map(a => new MileageData(a)))
    );
  }

  syncMileageMoney(): Observable<MileageData[]> {
    return this.loadMileageMoney().pipe(tap(res => this.DBService.saveMultipleToDB('mileageData', res)));
  }

  loadCustomerEmails(assignmentId: number, page: number = 1): Observable<CustomerEmail[]> {
    return this.http.get<CustomerEmailsResponce>(`${this.ASSIGNMENTS_API}/${assignmentId}/customers?per_page=50&page=${page}`).pipe(
      take(1),
      map(res => res.customers),
      map(customers => customers.map(c => new CustomerEmail(c, assignmentId)))
    );
  }

}
