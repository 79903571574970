import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '@shared/services';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(
    private router:      Router,
    private authService: AuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.authService.isLoggedIn().then(check => this.checkAccess(check));
  }

  private checkAccess(check: boolean): boolean {
    return check ? check : this.deny();
  }

  private deny(): boolean {
    this.router.navigate(['auth/login']);
    return false;
  }
}
