import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { forkJoin, from, map, of, switchMap } from 'rxjs';

import { PillSectionModel, SortModel, PagingModel } from '@shared/models';
import { PushNotificationUnread, VacationRequestLocal, VacationRequestOverview } from '@shared/factories';
import { DBService, NotificationService, PushNotificationsService, SessionStorageService, VacationRequestsService } from '@shared/services';

@Component({
  selector:      'vacations-overview',
  templateUrl: './vacations-overview.component.html',
  host: { class: 'height-full du-flex-column gap-m p-xl overflow' }
})
export class VacationsOverviewComponent implements OnInit {
  search: string;
  page:   number;
  pills:  PillSectionModel[] = [
    { label: 'leaveType', field: 'leave_type', pills: [
      { label: 'annual',  value: 'annual'  },
      { label: 'special', value: 'special' },
      { label: 'unpaid',  value: 'unpaid'  }
    ]},
    { label: 'reason', field: 'reason', pills: [
      { label: 'wedding_birth',            value: 'wedding_birth'            },
      { label: 'death_of_close_relatives', value: 'death_of_close_relatives' },
      { label: 'death_of_relatives',       value: 'death_of_relatives'       }
    ]}
  ];

  sort: SortModel[] = [
    { label: 'latest',              column: 'starts_on',     active: true },
    { label: 'closest',             column: 'starts_on',        dir: true },
    { label: 'Vacation Type A-Z',   column: 'leave_type'                  },
    { label: 'Vacation Type Z-A',   column: 'leave_type',       dir: true },
    { label: 'Assignmant Name A-Z', column: 'assignment_title'            },
    { label: 'Assignmant Name Z-A', column: 'assignment_title', dir: true }
  ];

  tabs: any[] = [
    { label: 'Approved', value: 'approved', color: 'green'  },
    { label: 'Awaiting', value: 'awaiting', color: 'orange' },
    { label: 'Rejected', value: 'rejected', color: 'red'    }
  ];

  vacations: (VacationRequestLocal | VacationRequestOverview)[] = [];
  unreadPn:  PushNotificationUnread[]  = [];
  paging:    PagingModel;
  constructor(
    private location:                 Location,
    private dbService:                DBService,
    private vacationRequestsService:  VacationRequestsService,
    private pushNotificationsService: PushNotificationsService,
    private notificationService:      NotificationService,
    private sessionStorageService:    SessionStorageService
  ) {}

  ngOnInit(): void {
    this.prepareHeader();
    this.sessionStorageService.paging.subscribe(paging => this.paging = paging);

    this.notificationService.wait();
    from(this.dbService.loadMultipleFromDB('unreadPn')).subscribe(
      pns => {
        this.unreadPn = pns.map(pn => new PushNotificationUnread(pn)).filter(e => e.sourceType === 'vacation_request');
        this.loadVacations();
      }
    );
  }

  private prepareHeader(): void {
    this.sessionStorageService.setHeaderTitle('overviewVacation');
    this.sessionStorageService.setHeaderControls({ left: [{ icon: 'arrow-big-left color-blue font-icon', callback: () => this.location.back() }] });
    this.sessionStorageService.setProgressBar(null);
  }

  loadVacations(reload: boolean = null): void {
    if (!this.paging || !this.paging.total_pages || !this.page || this.page < this.paging.total_pages || reload) {
      this.page = reload || !this.page ? 1 : this.page+1;
      this.notificationService.wait();
      this.vacationRequestsService.loadVacations(this.page).pipe(
        switchMap(vacations => {
          if (this.page === 1) return from(this.dbService.loadMultipleFromDB('pendingUpload', { useCase: 'vr' })).pipe(
            map(pendingVr => pendingVr.map(vr => new VacationRequestLocal(vr))),
            map(pendingVr => [...pendingVr, ...vacations])
          );
          else return of(vacations);
        })
      ).subscribe(
        vacations => {
          if (reload) this.vacations = [...vacations];
          else this.vacations = [...this.vacations, ...vacations];
          this.notificationService.close();
          this.setPnAsRead();
        },
        err => this.notificationService.alert(err),
        ()  => this.sessionStorageService.setPulltoRefreshState(true)
      );
    }
  }

  openVacationDetails(vacation: VacationRequestOverview | VacationRequestLocal): void {
    this.sessionStorageService.pushDynamicComponent({
      component: 'VacationDetails',
      props: { vacation }
    });
  }

  private setPnAsRead(): void {
    let ids = this.unreadPn.filter(pn => this.vacations.find(vr => pn.sourceId === vr.id)).map(pn => pn.id);
    forkJoin(ids.map(id => this.pushNotificationsService.markAsRead(id))).pipe(
      switchMap(() => forkJoin(ids.map(id => this.dbService.deleteOneFromDB('unreadPn', id))))
    ).subscribe(
      ()  => {},
      err => this.notificationService.alert(err)
    );
  }

}
