import { Component, OnInit } from '@angular/core';
import { ActivatedRoute    } from '@angular/router';
import { Location          } from '@angular/common';

import { Observable, forkJoin, from, switchMap, take } from 'rxjs';
import { PDFDocumentProxy, PDFPageProxy, getDocument } from 'pdfjs-dist';

import { SessionStorageService, NotificationService, SalaryReportsService } from '@shared/services';

@Component({
  templateUrl: './salary-details.component.html',
  host: { class: 'height-full du-flex-column gap-m overflow-auto p-xl' }
})
export class SalaryDetailsComponent implements OnInit {
  pages:    PDFPageProxy[];
  rendered: number = 0
  constructor(
    private route:                 ActivatedRoute,
    private location:              Location,
    private salaryReportsService:  SalaryReportsService,
    public  notificationService:   NotificationService,
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.notificationService.wait();

    this.sessionStorageService.setHeaderTitle('salaryReport');
    this.sessionStorageService.setProgressBar(null);
    this.sessionStorageService.setHeaderControls({ left: [{ icon: 'arrow-big-left color-blue font-icon',  callback: () => this.location.back() }] });
    this.route.params.pipe(
      take(1),
      switchMap((params: any) => this.salaryReportsService.getSalaryReportsById(+params['id'])),
      switchMap(report => from(getDocument(this.convertDataURIToBinary(report.base64pdf)).promise)),
      switchMap(pdf => forkJoin(this.getPDFpages(pdf)))
    ).subscribe(
      pdfPages => this.pages = pdfPages,
      err => this.notificationService.alert(err)
    );
  }

  handleLoad(): void {
    this.rendered++;
    if (this.rendered === this.pages.length) this.notificationService.close();
  }

  private getPDFpages(pdf: PDFDocumentProxy): Observable<PDFPageProxy>[] {
    let calls: Observable<PDFPageProxy>[] = [];
    for (let i = 1; i <= pdf.numPages; i++) {
      calls.push(from(pdf.getPage(i)));
    }
    return calls;
  }

  private convertDataURIToBinary(dataURI: string): Uint8Array {
    let BASE64_MARKER = ';base64,';
    let base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    let base64 = dataURI.substring(base64Index);
    let raw;
    try { raw = window.atob(base64); }
    catch (err) { return null; }
    let rawLength = raw.length;
    let array = new Uint8Array(new ArrayBuffer(rawLength));

    for(let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }

    return array;
  }

}
