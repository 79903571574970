import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivationService, NotificationService, SessionStorageService } from '@shared/services';
import { Observable, map, take, tap } from 'rxjs';

@Component({
  selector:      'user-stage',
  templateUrl: './user-stage.component.html',
  host: { class: 'height-full du-flex-column du-flex-justify p-xl' }
})
export class UserStageComponent implements OnInit {
  userId: string;

  @Input()  type: string;
  @Output() callback = new EventEmitter<Observable<string>>();
  constructor(
    private activationService:     ActivationService,
    private notificationService:   NotificationService,
    private sessionStorageService: SessionStorageService,
  ) {}

  ngOnInit(): void {
    this.sessionStorageService.setProgressBar(1, 4);
  }

  callbackHandler(): void {
    this.notificationService.wait();
    this.callback.emit(this.activationService.requestCode(this.type, this.userId).pipe(
      take(1),
      map(res => this.userId)
    ));
  }
}
