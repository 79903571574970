import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Device, DeviceInfo } from '@capacitor/device'

import { HelperService, NotificationService, SessionStorageService } from '@shared/services';

import packageJson from '../../../../../package.json';

@Component({
  selector:      'support',
  templateUrl: './support.component.html',
  host: { class: 'height-full du-flex-column du-flex-justify' }
})
export class SupportComponent implements OnInit {
  device: DeviceInfo;
  appVer: string = packageJson.version;
  note:   string;
  constructor(
    private router:                Router,
    private helperService:         HelperService,
    private notificationService:   NotificationService,
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.sessionStorageService.setHeaderTitle('helpSupport');
    this.sessionStorageService.setHeaderControls({ left: [{ icon: 'arrow-big-left color-blue font-icon', callback: () => this.router.navigateByUrl('time-tracking/home') }] });
    this.sessionStorageService.setProgressBar(null);
    Device.getInfo().then(device => this.device = device);
  }


  sendSupportRequest(): void {
    this.notificationService.wait();
    let data = { 
      support_request: {
        device_info: {
          app_version:  this.appVer,
          manufacturer: this.device.manufacturer,
          model:        this.device.model,
          platform:     this.device.platform,
          version:      this.device.osVersion
        },
        note: this.note
      }
    };

    this.helperService.sendSupport(data).subscribe(
      () => {
        this.router.navigateByUrl('time-tracking/home');
        this.notificationService.close();
      },
      err => this.notificationService.alert(err)
    );
  }

}
