import { Component, OnInit                               } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router                                          } from '@angular/router';
import { forkJoin, of, switchMap, tap                                       } from 'rxjs';

import { AuthService, NotificationService, SessionStorageService, UserService } from '@shared/services';

@Component({
  templateUrl: './login.component.html',
  host: { class: 'du-flex-column du-flex-justify pt-xl-ios pb-xl-ios pl-xl pr-xl bg-blue-gradient-auth' }
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  constructor(
    private router:                Router,
    private formBuilder:           FormBuilder,
    private authService:           AuthService,
    private userService:           UserService,
    private notificationService:   NotificationService,
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.sessionStorageService.setHeaderTitle(null);
    this.sessionStorageService.setOnlyHeaderTitle('login');
    this.buildForm();
  }

  private buildForm(): void {
    this.loginForm = this.formBuilder.group({
      email:    new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required)
    });
    let creds = JSON.parse(localStorage.getItem('activation_creds'));
    if (creds && creds.userId && creds.pass) {
      this.loginForm.patchValue({
        email:    creds.userId,
        password: creds.pass
      });
    }
    localStorage.removeItem('activation_creds');
  }

  login(): void {
    if (!this.loginForm.invalid) {
      this.notificationService.wait();
      this.authService.login(this.loginForm.get('email').value, this.loginForm.get('password').value).pipe(
        switchMap((token: string) => forkJoin([
          this.userService.loadMe(),
          of(token)
        ])),
        tap(() => this.authService.saveRefreshToken(null))
      ).subscribe(
        res => {
          if (res && res[0] && res[1]) {
            if (res[0].accepted_privacy_policy) {
              this.authService.saveRefreshToken(res[1]);
              this.router.navigate(['time-tracking/home']);
            } else this.sessionStorageService.pushDynamicComponent({
              component: 'PrivacyBox',
              props: {
                token: res[1]
              }
            });
            this.notificationService.close();
          } else this.notificationService.alert('Something went wrong, try again.');
        },
        err => this.notificationService.alert(err)
      );
    }
  }

}
