import { Component, OnInit } from '@angular/core';
import { fadeIn } from '@shared/animations';
import { SessionStorageService } from '@shared/services';
@Component({
  selector:      'chip',
  templateUrl: './chip.component.html',
  animations: [ fadeIn ]
})
export class ChipComponent implements OnInit {
  chip:  string;
  timer: any;
  constructor(private sessionStorageService: SessionStorageService) {}

  ngOnInit(): void {
    this.sessionStorageService.chip.subscribe(chip => {
      clearTimeout(this.timer);
      this.chip  = chip;
      this.timer = setTimeout(() => this.chip = '', 1250);
    });
  }

}
