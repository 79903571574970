<div class="du-flex-column gap-s z-1 bg-white">
  <div class="relative width-full">
    <input type="text" class="placeholder-primary-grey pl45-i" [(ngModel)]="search" placeholder="Search">
    <span class="du-icon-search font-icon du-flex-center absolute-top-center ml-m"></span>
  </div>
  <filter-sort [pills]="pills" [assignment]="true" [from]="'starts_on'" [to]="'ends_on'" [sort]="sort" (updateData)="loadVacations(true)"></filter-sort>
  <tabs-box [tabs]="tabs" [field]="'approval_state'" (updateData)="loadVacations(true)"></tabs-box>
</div>

<ul class="du-flex-1 height-full du-flex-column gap-m overflow-auto"
    infiniteScroll
    [infiniteScrollDistance]="1"
    [infiniteScrollThrottle]="150"
    [scrollWindow]="false"
    (scrolled)="loadVacations()">
  <pull-to-refresh (callback)="loadVacations(true)"></pull-to-refresh>
  <vacations-overview-item *ngFor="let vacation of vacations" class="shadow" [vacation]="vacation" (click)="openVacationDetails(vacation)"></vacations-overview-item>
  <span *ngIf="paging && page === paging.total_pages" class="du-flex-center p-m color-disabled">No more entries to load (Total entries: {{ vacations.length }})</span>
</ul>
