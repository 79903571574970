import { enableProdMode } from '@angular/core';

import { environment } from './environments/environment';

import { DYNAMIC_ENVIRONMENT, env } from 'ngx-http-env';
import { IEnvironment } from './environments/ienvironment';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 'hammerjs';

import { AppModule } from './app/app.module';

env(environment, 'assets/environments/environment.json?ngsw-bypass=true').subscribe({
  next: (env: IEnvironment) => {
    if (env.production) {
      enableProdMode();
    }

    platformBrowserDynamic([{
      provide: DYNAMIC_ENVIRONMENT,
      useValue: env,
    }])
    .bootstrapModule(AppModule)
    // eslint-disable-next-line no-console
    .catch((reason: any) => console.error(reason));
  },
  // eslint-disable-next-line no-console
  error: (reason: any) => console.error('Failed to load environment configuration', reason),
});
