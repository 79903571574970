<div class="du-flex-justify p-m bb-grey">
  <div class="du-flex-column gap-xs">
    <span class="bold">{{ getOverviewDocument().createdAt | date: 'dd.MM.YYYY' }}</span>
    <span>{{ getOverviewDocument().subject || getLocalDocument().docType.name }}</span>
  </div>
  <span class="color-orange" *ngIf="!getOverviewDocument().readAt" translate="submitted"></span>
  <span class="color-green"  *ngIf=" getOverviewDocument().readAt" translate="read"></span>
  <span *ngIf="getLocalDocument().notSynced" class="du-flex-align gap-xs pl-xs pr-xs pt-xxs pb-xxs b-radius bg-light-orange color-orange">
    <span class="du-icon-offline font-l"></span>
    <span class="font-s" translate="notSynced"></span>
  </span>
</div>

<div class="du-flex-column gap-s p-m">
  <div class="du-flex-justify color-blue font-s" (click)="collapsePD()">
    <span *ngIf="!collapsed" translate="showMore"></span>
    <span *ngIf=" collapsed" translate="showLess"></span>
    <img  src="assets/svg/arrow-down-blue.svg" [class.rotate]="collapsed">
  </div>
  <div class="du-flex-wrap gap-s" *ngIf="collapsed">
    <div class="width-130 b-radius overflow relative animate-width" [class.width-full]="extendImage">
      <img class="width-full" [src]="document.attachment || src" (load)="attachmentLoaded()">
      <img class="absolute bottom-0 right-0 mr-m mb-m" *ngIf="!document.attachment && loaded" src="assets/svg/warning.svg">
      <img class="absolute bottom-0 right-0 mr-m mb-m" *ngIf="document.attachment"            src="assets/svg/expand-blue.svg" (click)="extendImage = !extendImage">
    </div>
    <div class="du-flex-column gap-xs font-s">
      <span translate="noteToManager"></span>
      <span class="lh-l">{{ document.messageExternal || '-' }}</span>
    </div>
  </div>
</div>
