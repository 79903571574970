import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { from } from 'rxjs';

import { DBService, HelperService, NotificationService, SessionStorageService } from '@shared/services';
import { Banner } from '@shared/factories';

@Component({
  selector:      'refer-a-friend',
  templateUrl: './refer-a-friend.component.html',
  host: { class: 'height-full du-flex-column du-flex-justify' }
})
export class ReferAFriendComponent implements OnInit {
  banner:     Banner;
  friendForm: FormGroup;
  constructor(
    private router:                Router,
    private formBuilder:           FormBuilder,
    private dbService:             DBService,
    public  helperService:         HelperService,
    public  notificationService:   NotificationService,
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.notificationService.wait();
    this.sessionStorageService.setHeaderTitle('referAFriend');
    this.sessionStorageService.setHeaderControls({ left: [{ icon: 'arrow-big-left color-blue font-icon', callback: () => this.router.navigateByUrl('time-tracking/home') }] });
    this.sessionStorageService.setProgressBar(null);
    from(this.dbService.loadOneFromDB('banners', { tag: 'referralProgramBanner'})).subscribe(
      banner => this.banner = new Banner(banner),
      err => this.notificationService.alert(err)
    );
    this.buildForm();
  }

  private buildForm(): void {
    this.friendForm = this.formBuilder.group({
      salutation:   new FormControl(null,  Validators.required),
      firstName:    new FormControl(null, [Validators.required, Validators.maxLength(50)]),
      lastName:     new FormControl(null, [Validators.required, Validators.maxLength(50)]),
      mobileNumber: new FormControl(null, [Validators.required, Validators.maxLength(20)]),
      email:        new FormControl(null, [Validators.required, Validators.maxLength(50)])
    });
  }

  referAFriend(): void {
    this.notificationService.wait();
    let data = {
      referral_program_referee: {
        salutation:   this.friendForm.get('salutation').value,
        first_name:   this.friendForm.get('firstName').value.trim(),
        last_name:    this.friendForm.get('lastName').value.trim(),
        mobile_phone: this.friendForm.get('mobileNumber').value.trim(),
        email:        this.friendForm.get('email').value.trim(),
      }
    };
    this.helperService.referAFriend(data).subscribe(
      () => {
        this.router.navigate(['time-tracking/info'], { queryParams: { type: 'success', useCase: 'refer-a-friend' }});
        this.notificationService.close();
      },
      err => this.notificationService.alert(err)
    );
  }

}
