import { CustomerEmailModel } from "@shared/models";

export class CustomerEmail {
  id:             number;
  assignmentId:   number;
  createdAt:      Date;
  email:          string;

  firstName:      string;
  lastName:       string;
  name:           string;

  personalNumber: number;
  phoneNumber:    number;
  active:         boolean;
  constructor(data: CustomerEmailModel, assignmentId: number) {
    this.id             = data.id;
    this.assignmentId   = data.assignment_id || assignmentId;
    this.createdAt      = this.parceDate(data.created_at);
    this.email          = data.email;
    this.firstName      = data.first_name;
    this.lastName       = data.last_name;
    this.name           = `${this.firstName || ''} ${this.lastName || ''}`;

    this.phoneNumber    = +data.phone_number;
    this.personalNumber = +data.personal_number;
    this.active         = data.active || false;
  }

  private parceDate(date: any) {
    return date ? date instanceof Date ? date : new Date(date) : null;
  }

  toJSON(): CustomerEmailModel {
    return {
      id:              this.id             ? this.id                      : null,
      assignment_id:   this.assignmentId   ? this.assignmentId            : null,
      email:           this.email          ? this.email                   : null,
      created_at:      this.createdAt      ? this.createdAt.toISOString() : null,
      first_name:      this.firstName      ? this.firstName               : null,
      last_name:       this.lastName       ? this.lastName                : null,

      personal_number: this.personalNumber ? this.personalNumber          : null,
      phone_number:    this.phoneNumber    ? this.phoneNumber.toString()  : null,
    };
  }

}
