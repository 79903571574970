<div class="du-flex-column p-xl du-flex-1 gap-m overflow" *ngIf="report && report.dailyReports">
  <span class="font-l bold" translate="fillInWorkingTimes"></span>
  <span class="font-s"      translate="swipeToSeeDays"></span>

  <day-selector *ngIf="report" [useCase]="useCase" [report]="report" [activeReportIndex]="activeReportIndex" (daySelectCallback)="moveToReport($event)" [tutorialStep]="tutorialStep" [tutorialNumber]="1" [tutorialText]="'canSeeDaysOfWeek' | translate"></day-selector>

  <ul class="du-flex gap-xxxl transition-left-200" [class.overflow]="!tutorialStep" id="hammerContainer" (swipeleft)="swipeLeft()" (swiperight)="swipeRight()" [tutorialStep]="tutorialStep" [tutorialNumber]="3" [tutorialText]="'canSeeDaysActivated' | translate">
    <daily-constructor-item *ngFor="let daily of report.dailyReports; let i = index"
                            class="du-flex-full"
                            [index]="i"
                            [daily]="daily"
                            [useCase]="useCase"
                            [tutorialStepVal]="tutorialStep"
                            (adjustDaily)="adjustDaily($event)"
                            (validate)="validate()"
                            (resetToDefault)="resetToDefault($event)">
    </daily-constructor-item>
  </ul>

</div>

<div class="du-flex-column" *ngIf="report">
  <ul class="du-flex-center gap-xs p-xl">
    <li *ngFor="let daily of report.dailyReports; let i = index"
        [ngClass]="activeReportIndex === i ? 'height-10 width-25 b-radius bg-blue' : 'circle-10 bg-light-blue'">
    </li>
  </ul>

  <div class="du-flex-column gap-m p-xl " [class.bt-grey]="useCase === 't'">
    <div class="du-flex-column gap-s" *ngIf="useCase === 't'">
      <span class="font-500" translate="templateName"></span>
      <input type="text" [(ngModel)]="templateName">
    </div>

    <button class="btn-primary btn-round"
            [disabled]="!report || !report.activeDailyReports || !report.activeDailyReports.length || report.techErrors.size || useCase === 't' && !templateName"
            (click)="callbackHandler()">
      {{ (useCase === 't' ? templateId ? 'saveTemplate' : 'createTemplate' : 'next') | translate }}
    </button>
  </div>
</div>
