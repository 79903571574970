import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from, map, take, tap } from 'rxjs';

import { environment  } from 'environments/environment';
import { Holiday      } from '@shared/factories';
import { HolidayModel } from '@shared/models';
import { DBService    } from './db.service'
import { formatDate } from '@angular/common';

interface HolidaysResponse { holidays: HolidayModel[]; }

@Injectable({
  providedIn: 'root'
})

export class HolidaysService {
  private readonly HOLIDAYS_API = `${environment.apiUrl}/api/mobile/v3/holidays`;

  constructor(
    private http:      HttpClient,
    private dbService: DBService,
  ) { }

  private loadHolidays(): Observable<Holiday[]> {
    let from = new Date(new Date().getTime() - 8*7*24*60*60*1000); // 8 weeks in past
    let to   = new Date(new Date().getTime() + 5*7*24*60*60*1000); // 5 weeks in future
    return this.http.get<HolidaysResponse>(`${this.HOLIDAYS_API}?q[date][from]=${formatDate(from, 'yyyy-MM-dd', 'de')}&q[date][to]=${formatDate(to, 'yyyy-MM-dd', 'de')}`).pipe(
      take(1),
      map(res => res.holidays),
      map(holidays => holidays.map(h => new Holiday(h)))
    );
  }

  syncHolidays(): Observable<Holiday[]> {
    
    return this.loadHolidays().pipe(
      tap(res => from(this.dbService.clearTable('holidays'))),
      tap(res => this.dbService.saveMultipleToDB('holidays', res)
    ));
  }

}
