<div class="pl-xl pt-xl pr-xl pb-m">
  <filter-sort [sort]="sort"></filter-sort>
</div>

<ul class="du-flex-1 height-full du-flex-column overflow-auto"
    infiniteScroll
    [infiniteScrollDistance]="1"
    [infiniteScrollThrottle]="150"
    [scrollWindow]="false"
    (scrolled)="loadNotifications()">
  <li class="du-flex-column gap-s pl-xl pr-xl pt-s pb-s bb-grey" *ngFor="let notification of notifications; let i = index">
    <div class="du-flex-justify du-flex-align-start">
      <span class="lh-l">{{ notification.subject }}</span>
      <span class="font-s">{{ notification.createdAt | date: 'dd.MM.YYYY' }}</span>
    </div>
    <span class="lh-l">{{ notification.text }}</span>
  </li>
</ul>
