import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router     } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { from, of   } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import { PhotoDocument, PhotoDocumentLocal } from '@shared/factories';
import { MetaModel, PhotoDocumentModel     } from '@shared/models';
import { DBService             } from './db.service'
import { QueryCollectorService } from './query-collector.service';
import { SessionStorageService } from './session-storage.service';

import { environment } from 'environments/environment';

interface PhotoDocumentResponce {
  photo_document: PhotoDocumentModel;
}

interface PhotoDocumentsResponce {
  photo_documents: PhotoDocumentModel[];
  meta:            MetaModel;
}

@Injectable({
  providedIn: 'root'
})
export class PhotoDocumentsService {
  private PHOTO_DOCUMENTS_API:     string = `${environment.apiUrl}/api/mobile/v3/photo_documents`;
  private PD_DB_TABLE:             string = 'photoDocuments';
  private LOCAL_PD_DB_TABLE:       string = 'localDocuments';
  private PENDING_UPLOAD_DB_TABLE: string = 'pendingUpload';

  constructor(
    private http:                  HttpClient,
    private router:                Router,
    private dbService:             DBService,
    private sessionStorageService: SessionStorageService,
    private queryCollectorService: QueryCollectorService,
  ) { }

  loadPhotoDocuments(page: number = 1): Observable<PhotoDocument[]> {
    let offline = this.sessionStorageService.offlineModeValue;
    if (offline) return this.loadPhotoDocumentsFromDB(page);
    else return this.requestDocuments(page);
  }

  private loadPhotoDocumentsFromDB(page: number = 1): Observable<PhotoDocument[]> {
    let { filter, sort } = this.queryCollectorService.parcePhotoDocumentsDBQuery(page);
    return from(this.dbService.loadMultipleFromDBPaginated(this.PD_DB_TABLE, page, filter, sort)).pipe(
      take(1),
      map(res => {
        this.sessionStorageService.setPaging(res.paging);
        return res.data.map((pd: PhotoDocumentModel) => new PhotoDocument(pd));
      })
    );
  }

  private requestDocuments(page: number = 1): Observable<PhotoDocument[]> {
    return this.http.get<PhotoDocumentsResponce>(`${this.PHOTO_DOCUMENTS_API}${this.queryCollectorService.getPhotoDocumentsQuery(page)}`).pipe(
      take(1),
      map(res => {
        this.sessionStorageService.setPaging(res.meta.paging);
        return res.photo_documents.map(pd => new PhotoDocument(pd));
      }),
      tap(res => this.dbService.saveMultipleToDB(this.PD_DB_TABLE, res))
    );
  }

  submitPhotoDocument(document: PhotoDocumentLocal): Observable<PhotoDocument | PhotoDocumentLocal> {
    let offline = this.sessionStorageService.offlineModeValue;
    if (offline) return this.pushDocumentToPendingList(document);
    else return this.postLocalPhotoDocument(document);
  }

  submitPendingPhotoDocument(document: PhotoDocumentLocal): Observable<PhotoDocument> {
    let offline = this.sessionStorageService.offlineModeValue;
    if (!offline) return this.postPendingPhotoDocument(document);
    return of(null);
  }

  private pushDocumentToPendingList(document: PhotoDocumentLocal): Observable<PhotoDocumentLocal> {
    return from(this.dbService.saveOneToDB(this.PENDING_UPLOAD_DB_TABLE, Object.assign(document, { useCase: 'pd', notSynced: true }))).pipe(
      tap(() => { if (document.id) from(this.dbService.deleteOneFromDB(this.LOCAL_PD_DB_TABLE, document.id));  }),
      tap(() => this.router.navigate(['time-tracking/info'], { queryParams: { type: 'offline', useCase: 'pd' }})),
      map(pd => document)
    );
  }

  private postPhotoDocument(document: PhotoDocumentLocal): Observable<PhotoDocument> {
    let data = document.toSubmitJSON();
    return this.http.post<PhotoDocumentResponce>(this.PHOTO_DOCUMENTS_API, data).pipe(
      map(res => new PhotoDocument(res.photo_document))
    );
  }

  private postLocalPhotoDocument(document: PhotoDocumentLocal): Observable<PhotoDocument> {
    return this.postPhotoDocument(document).pipe(
      tap(()  => { if (document.id) from(this.dbService.deleteOneFromDB(this.LOCAL_PD_DB_TABLE, document.id));  }),
      tap(()  => this.router.navigate(['time-tracking/info'], { queryParams: { type: 'submit', useCase: 'pd'   }}),
          err => this.router.navigate(['time-tracking/info'], { queryParams: { type: 'failed', useCase: 'pd'   }})
      )
    );
  }

  private postPendingPhotoDocument(document: PhotoDocumentLocal): Observable<PhotoDocument> {
    return this.postPhotoDocument(document).pipe(
      tap(() => { if (document.notSynced) from(this.dbService.deleteOneFromDB(this.PENDING_UPLOAD_DB_TABLE, document.id)); }),
    );
  }

  savePhotoDocument(document: PhotoDocumentLocal): Observable<PhotoDocumentLocal> {
    return from(this.dbService.saveOneToDB(this.LOCAL_PD_DB_TABLE, document)).pipe(
      tap(() => this.router.navigate(['time-tracking/info'], { queryParams: { type: 'save', useCase: 'pd' }})),
      map(pd => document)
    );
  }

}
