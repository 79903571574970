import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';

import { SessionStorageService   } from '@shared/services';
import { collapse, slideUp       } from '@shared/animations';
import { VacationRequestOverview } from '@shared/factories';

@Component({
  selector:      'vacation-details',
  templateUrl: './vacation-details.component.html',
  host: { class: 'height-max-700-auto absolute bottom-0 left-0 right-0 du-flex-column btl-radius btr-radius absolute bg-white'},
  animations: [ slideUp, collapse ]
})
export class VacationDetailsComponent implements OnInit, OnDestroy {
  private readonly COMPONENT_NAME: string = 'VacationDetails';
  @Input() vacation: VacationRequestOverview;

  @HostBinding('@slideUp') get slideIn() { return 'open'; }
  constructor( private sessionStorageService: SessionStorageService ) {}

  ngOnInit(): void {
    this.sessionStorageService.pushOverflowStack(this.COMPONENT_NAME);
  }

  ngOnDestroy(): void {
    this.sessionStorageService.popOverflowStack(this.COMPONENT_NAME);
  }

  close(): void {
    this.sessionStorageService.popDynamicComponent(this.COMPONENT_NAME);
  }

}
