import { Injectable            } from "@angular/core";
import { Title                 } from "@angular/platform-browser";
import { BehaviorSubject, take } from "rxjs";
import { TranslateService      } from "@ngx-translate/core";

import { ActivityReportLocal, Assignment, PhotoDocumentLocal, VacationRequestLocal } from "@shared/factories";
import { FilterFlowModel, HeaderControlsModel, PagingModel, SortFlowModel          } from "@shared/models";

import { PendingService } from "./pending.service";

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {
  private headerVisibilitySubject = new BehaviorSubject<boolean>(null);
  headerVisibility = this.headerVisibilitySubject.asObservable();

  private headerTitleSubject = new BehaviorSubject<string>(null);
  headerTitle = this.headerTitleSubject.asObservable();
  titleRaw: string;

  private headerControlsSubject = new BehaviorSubject<HeaderControlsModel>(null);
  headerControls = this.headerControlsSubject.asObservable();

  private overflowStackSubject = new BehaviorSubject<string[]>(null);
  overflowStack = this.overflowStackSubject.asObservable();

  private progressBarSubject = new BehaviorSubject<number>(null);
  progressBar = this.progressBarSubject.asObservable();

  private cachedEntriesSubject = new BehaviorSubject<any[]>(null);
  cachedEntries = this.cachedEntriesSubject.asObservable();

  private temporaryReportSubject = new BehaviorSubject<ActivityReportLocal>(null);
  temporaryReport = this.temporaryReportSubject.asObservable();

  private temporaryDocumentSubject = new BehaviorSubject<PhotoDocumentLocal>(null);
  temporaryDocument = this.temporaryDocumentSubject.asObservable();

  private temporaryVacationSubject = new BehaviorSubject<VacationRequestLocal>(null);
  temporaryVacation = this.temporaryVacationSubject.asObservable();

  private temporaryAssignmentsSubject = new BehaviorSubject<Assignment[]>(null);
  temporaryAssignments = this.temporaryAssignmentsSubject.asObservable();

  private pagingSubject = new BehaviorSubject<PagingModel>(null);
  paging = this.pagingSubject.asObservable();

  private sortFlowSubject = new BehaviorSubject<SortFlowModel>(null);
  sortFlow = this.sortFlowSubject.asObservable();

  private filterFlowSubject = new BehaviorSubject<FilterFlowModel>(null);
  filterFlow = this.filterFlowSubject.asObservable();

  private tabFlowSubject = new BehaviorSubject<string>(null);
  tabFlow = this.tabFlowSubject.asObservable();

  private dynamicComponentSource = new BehaviorSubject<any>(null);
  dynamicComponent = this.dynamicComponentSource.asObservable();

  private chipSource = new BehaviorSubject<string>(null);
  chip = this.chipSource.asObservable();

  private offlineModeSource = new BehaviorSubject<boolean>(null);
  offlineMode = this.offlineModeSource.asObservable();

  private storeUrlSource = new BehaviorSubject<string>(null);
  storeUrl = this.storeUrlSource.asObservable();

  private syncDataSource = new BehaviorSubject<boolean>(null);
  syncData = this.syncDataSource.asObservable();

  private pulltoRefreshStateSource = new BehaviorSubject<boolean>(null);
  pulltoRefreshState = this.pulltoRefreshStateSource.asObservable();

  debug:              boolean = false;
  skipCampaignBanner: boolean = false;
  constructor (
    private title:            Title,
    private pendingService:   PendingService,
    private translateService: TranslateService,
  ) { }

  get headerVisibilityValue(): boolean {
    return this.headerVisibilitySubject.value;
  }

  setHeaderVisibility(visible: boolean): void {
    return this.headerVisibilitySubject.next(visible);
  }

  get headerTitleValue(): string {
    return this.headerTitleSubject.value;
  }

  setOnlyHeaderTitle(title: string): void {
    this.titleRaw = title;
    this.translateService.get(title).pipe(take(1)).subscribe((res: string) => {
      this.title.setTitle(res);
      this.headerTitleSubject.next(title);
    });
  }

  setHeaderTitle(title: string): void {
    this.headerVisibilitySubject.next(!!title);
    if (title && title.trim()) this.setOnlyHeaderTitle(title);
    else {
      this.title.setTitle('Tempton Umbrella')
      this.headerTitleSubject.next(null);
    };
  }

  syncHeaderTitle(): void {
    this.translateService.get(this.titleRaw).pipe(take(1)).subscribe((res: string) => this.title.setTitle(res));
    return this.headerTitleSubject.next(this.titleRaw);
  }

  get headerControlsValue(): HeaderControlsModel {
    return this.headerControlsSubject.value;
  }

  setHeaderControls(controls: HeaderControlsModel): void {
    this.headerControlsSubject.next(controls);
  }

  get overflowStackValue(): string[] {
    return this.overflowStackSubject.value;
  }

  pushOverflowStack(item: string = ''): void {
    let stack = this.overflowStackSubject.value && this.overflowStackSubject.value.length ? this.overflowStackSubject.value : [];
    stack.push(item);
    return this.overflowStackSubject.next(stack);
  }

  popOverflowStack(item: string = ''): void {
    let stack = this.overflowStackSubject.value && this.overflowStackSubject.value.length ? this.overflowStackSubject.value : [];
    if (stack.indexOf(item) !== -1) stack.splice(stack.indexOf(item), 1);
    return this.overflowStackSubject.next(stack);
  }

  clearOverflowStack(): void {
    return this.overflowStackSubject.next([]);
  }

  get progressBarValue(): number {
    return this.progressBarSubject.value;
  }

  setProgressBar(progress: number, range: number = 0): void {
    if (range) progress = Math.round((100 / range) * progress);
    return this.progressBarSubject.next(progress);
  }

  get cachedEntriesValue(): any[] {
    return this.cachedEntriesSubject.value;
  }

  setCachedEntries(entries: any[]): void {
    return this.cachedEntriesSubject.next(entries);
  }

  get temporaryReportValue(): ActivityReportLocal {
    return this.temporaryReportSubject.value;
  }

  setTemporaryReport(report: ActivityReportLocal): void {
    return this.temporaryReportSubject.next(report);
  }

  get temporaryDocumentValue(): PhotoDocumentLocal {
    return this.temporaryDocumentSubject.value;
  }

  setTemporaryDocument(report: PhotoDocumentLocal): void {
    return this.temporaryDocumentSubject.next(report);
  }

  get temporaryVacationValue(): VacationRequestLocal {
    return this.temporaryVacationSubject.value;
  }

  setTemporaryVacation(report: VacationRequestLocal): void {
    return this.temporaryVacationSubject.next(report);
  }

  get temporaryAssignmentsValue(): Assignment[] {
    return this.temporaryAssignmentsSubject.value;
  }

  setTemporaryAssignments(assignments: Assignment[]): void {
    return this.temporaryAssignmentsSubject.next(assignments);
  }

  get pagingValue(): PagingModel {
    return this.pagingSubject.value;
  }

  setPaging(paging: PagingModel): void {
    return this.pagingSubject.next(paging);
  }

  get sortFlowValue(): SortFlowModel {
    return this.sortFlowSubject.value;
  }

  setSortFlow(sortFlow: SortFlowModel): void {
    return this.sortFlowSubject.next(sortFlow);
  }

  get filterFlowValue(): FilterFlowModel {
    return this.filterFlowSubject.value;
  }

  setFilterFlow(filterFlow: FilterFlowModel): void {
    return this.filterFlowSubject.next(filterFlow);
  }

  get tabFlowValue(): any {
    return this.tabFlowSubject.value;
  }

  setTabFlow(tabFlow: any): void {
    return this.tabFlowSubject.next(tabFlow);
  }

  changeDynamicComponents(comp: any): void {
    this.dynamicComponentSource.next(comp);
  }

  pushDynamicComponent(item: any): void {
    return this.dynamicComponentSource.next(Object.assign(item, { type: 'create' }));
  }

  popDynamicComponent(item: string): void {
    return this.dynamicComponentSource.next({
      component: item,
      type:     'remove'
    });
  }

  setDebug(debug: boolean): void {
    this.debug = debug;
  }

  setChip(chip: string): void {
    return this.chipSource.next(chip);
  }

  get offlineModeValue(): any {
    return this.offlineModeSource.value;
  }

  setOfflineMode(offline: boolean): void {
    if (!offline) this.pendingService.syncPendingList().subscribe();
    return this.offlineModeSource.next(offline);
  }

  get storeUrlValue(): string {
    return this.storeUrlSource.value;
  }

  setStoreUrl(url: string): void {
    return this.storeUrlSource.next(url);
  }

  triggerSyncData(): void {
    return this.syncDataSource.next(true);
  }

  setPulltoRefreshState(state: boolean): void {
    return this.pulltoRefreshStateSource.next(state);
  }

}
