import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subscriber, fromEvent } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  constructor (private http: HttpClient) { }

  loadImage(url: string): Observable<string | ArrayBuffer> {
    let urld = url.includes('https://') ? url : environment.apiUrl + url.replace(/\/\//g, '/');
    return this.http.get<Blob>(`${urld}`, {responseType: 'blob' as 'json'}).pipe(
      switchMap(response => this.fileReaderAsObservable(response))
    );
  }

  private fileReaderAsObservable(file: any): Observable<string> {
    return new Observable<string>((observer: Subscriber<string | ArrayBuffer>): void => {
      let reader = new FileReader();
      reader.onloadend = () => {
        observer.next(reader.result);
        observer.complete();
      }
      reader.readAsDataURL(file);
    })
  }

  loadImageXHR(url: string): Observable<string> {
    url = url.replace('https://app-staging-backend.tempton.de', environment.apiUrl);
    var xhr = new XMLHttpRequest();
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
    return new Observable<string>((observer: Subscriber<string | ArrayBuffer>): void => {
      xhr.onload = () => {
        let reader = new FileReader();
        reader.onloadend = () => {
          observer.next(reader.result);
          observer.complete();
        }
        reader.readAsDataURL(xhr.response);
      };
      xhr.onerror = (error): void => { observer.error(error); }
    })
  }

  arrayBufferToBase64(buffer: ArrayBuffer): string {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
  }
}
