import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SessionStorageService } from '@shared/services';

@Component({
  selector:      'language-toggler',
  templateUrl: './language-toggler.component.html',
  host: { class: 'relative height-full width-170' }
})
export class LanguageTogglerComponent {
  @Input() color: string;

  langOpen: boolean;
  langList: any[] = [
    { label: 'Deutsch',    code: 'de', flag: 'assets/svg/flags/de.svg' },
    { label: 'English',    code: 'en', flag: 'assets/svg/flags/en.svg' },
    { label: 'Română',     code: 'ro', flag: 'assets/svg/flags/ro.svg' },
    { label: 'Polski',     code: 'pl', flag: 'assets/svg/flags/pl.svg' },
    { label: 'Türk',       code: 'tr', flag: 'assets/svg/flags/tr.svg' },
    { label: 'Magyar',     code: 'hu', flag: 'assets/svg/flags/hu.svg' },
    { label: 'Русский',    code: 'ru', flag: 'assets/svg/flags/ru.svg' },
    { label: 'Čeština',    code: 'cz', flag: 'assets/svg/flags/cz.svg' },
    { label: 'Hrvatski',   code: 'hr', flag: 'assets/svg/flags/hr.svg' },
    { label: 'Українська', code: 'ua', flag: 'assets/svg/flags/ua.svg' }
  ];

  constructor(
    private sessionStorageService: SessionStorageService,
    private translateService:      TranslateService
  ) {}

  changeLang(lang: string): void {
    this.translateService.use(lang);
    this.sessionStorageService.syncHeaderTitle();
    this.langOpen = false;
  }

}
