// This app uses dynamic environments loaded from the asset folder.
// Changes to this file will not change the runtime envionment.
//
export const environment = {
  defaultLocale: 'en',
  production: false,
  apiUrl:  "https://app-staging-api.tempton.de",
  staging: "https://app-staging-api.tempton.de",
  preprod: "https://mt-api-preprod.tc.g8s.dev",
  prod:    "https://app-api.tempton.de",
  maxFileSize: 100,
  queueLimit: 5
};
