
<div class="du-flex-column gap-s pl-m pt-m pr-m pb-s">
  <span class="du-flex-justify gap-xl">
    <span class="lh-l bold">{{ report.assignment.title }}</span>
    <img src="assets/svg/close.svg" (click)="deleteReportPrompt(report)">
  </span>
  <span class="du-flex-justify gap-m">
    <span class="du-flex gap-xxs">
      <span translate="cw"></span>
      <span class="roboto">{{  report.startDate | date: 'ww' }}</span>
      <span class="roboto">({{ report.startDate | date: 'dd' }}.{{ report.startDate | date: 'MM' }} - {{ report.endDate | date: 'dd' }}.{{ report.endDate | date: 'MM' }})</span>
    </span>
    <span class="du-flex-wrap du-flex-justify-end width-140 gap-xxs roboto">
      <span *ngFor="let day of report.activeDailyReports; let i = index">
        {{ day.startsAt | date: 'dd.MM' }}
        <span [style.opacity]="+(i+1 !== report.activeDailyReports.length)">,</span>
      </span>
    </span>
  </span>
</div>
<div class="du-flex-column gap-xl bt-grey pt-s pl-m pr-m pb-m">
  <div *ngIf="!report.attachment" class="du-flex-column gap-s">
    <span class="du-flex-justify">
      <span translate="totalWorkingHours"></span>
      <span class="roboto bold">{{ report.durationWithPauses | numberToTime }}</span>
    </span>
    <span class="du-flex-justify">
      <span class="lh-l width-150" translate="totalWorkingHoursWithoutBreaks"></span>
      <span class="roboto bold">{{ report.durationExludingPauses | numberToTime }}</span>
    </span>
  </div>
  <div class="du-flex-1 b-radius bg-card-grey overflow" *ngIf="report.attachment">
    <img class="width-full object-contain" [src]="report.attachment">
  </div>
  <div class="du-flex gap-l">
    <button class="width-full btn-secondary btn-round font-l" translate="edit"   (click)="editReport(report)"></button>
    <button class="width-full btn-primary   btn-round font-l" translate="submit" (click)="submitReportPrompt(report)"></button>
  </div>
</div>
