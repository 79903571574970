import { PushNotificationOverviewModel, PushNotificationUnreadModel } from "@shared/models";

export class PushNotificationOverview {
  id:        number;
  subject:   string;
  text:      string;
  status:    string;
  createdAt: Date;
  constructor(data: PushNotificationOverviewModel) {
    this.id        = data.id;
    this.subject   = data.subject;
    this.text      = data.text;
    this.status    = data.status;
    this.createdAt = this.parceDate(data.created_at);
  }

  private parceDate(date: any) {
    return date ? date instanceof Date ? date : new Date(date) : null;
  }

  toJSON(): PushNotificationOverviewModel {
    return {
      id:         this.id         ? this.id                      : null,
      subject:    this.subject    ? this.subject                 : null,
      text:       this.text       ? this.text                    : null,
      status:     this.status     ? this.status                  : null,
      created_at: this.createdAt  ? this.createdAt.toISOString() : null
    };
  }
}

export class PushNotificationUnread {
  id:         number;
  userId:     number;
  createdAt:  Date;

  status:     string;
  sourceId:   number;
  sourceType: string;
  constructor(data: PushNotificationUnreadModel) {
    this.id         = data.id;
    this.userId     = data.userId;
    this.createdAt  = this.parceDate(data.created_at);

    this.status     = data.status;
    this.sourceId   = data.trigger.source_id;
    this.sourceType = data.trigger.source_type;
  }

  private parceDate(date: any) {
    return date ? date instanceof Date ? date : new Date(date) : null;
  }

  toJSON(): PushNotificationUnreadModel {
    return {
      id:         this.id        ? this.id                      : null,
      userId:     this.userId    ? this.userId                  : null,
      created_at: this.createdAt ? this.createdAt.toISOString() : null,
      status:     this.status    ? this.status                  : null,
      trigger: {
        source_id:   this.sourceId   ? this.sourceId   : null,
        source_type: this.sourceType ? this.sourceType : null
      }
    };
  }

}
