import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { SessionStorageService } from '@shared/services';
import { slideUp               } from '@shared/animations';
import { Assignment } from '@shared/factories';

@Component({
  selector:      'assignment-select-box',
  templateUrl: './assignment-select-box.component.html',
  host: { class: 'height-max-full-xl absolute bottom-0 left-0 right-0 du-flex-column btl-radius btr-radius bg-white z-3'},
  animations: [ slideUp ]
})
export class AssignmentSelectBoxComponent implements OnInit, OnDestroy {
  search: string;

  @Input() assignment:  Assignment;
  @Input() assignments: Assignment[];
  @Output() select = new EventEmitter<Assignment>;
  @HostBinding('@slideUp') get slideIn() { return 'open'; }
  private readonly COMPONENT_NAME: string = 'AssignmentSelectBox';

  constructor( private sessionStorageService: SessionStorageService ) {}

  ngOnInit(): void {
    this.sessionStorageService.pushOverflowStack(this.COMPONENT_NAME);
  }

  ngOnDestroy(): void {
    this.sessionStorageService.popOverflowStack(this.COMPONENT_NAME);
  }

  selectHandler(assignment: Assignment): void {
    this.assignment = assignment;
  }

  close(): void {
    this.select.emit(this.assignment);
    this.sessionStorageService.popDynamicComponent(this.COMPONENT_NAME);
  }

  getFilteredAssignments(): Assignment[] {
    return this.assignments.filter(a => this.search ? a.title.toLowerCase().includes(this.search.toLowerCase()) : true);
  }

}
