<div class="du-flex-column du-flex-1 overflow-auto" *ngIf="device">
  <div class="du-flex-column gap-m pl-xl pt-xl pr-xl pb-m bb-grey">
    <div class="du-flex-column gap-xs">
      <span class="bold" translate="phoneSystem"></span>
      <span class="lh-l" translate="supportDesc"></span>
    </div>
    <div class="du-flex gap-xl">
      <div class="du-flex-column width-80 gap-xs">
        <span class="font-s" translate="appVer"></span>
        <span class="bold">{{ appVer }}</span>
      </div>
      <div class="du-flex-column width-80 gap-xs">
        <span class="font-s" translate="model"></span>
        <span class="bold">{{ device.model }}</span>
      </div>
      <div class="du-flex-column width-80 gap-xs">
        <span class="font-s" translate="osVersion"></span>
        <span class="bold">{{ device.osVersion }}</span>
      </div>
    </div>
    <div class="du-flex gap-xl">
      <div class="du-flex-column width-80 gap-xs">
        <span class="font-s" translate="manufacturer"></span>
        <span class="bold">{{ device.manufacturer }}</span>
      </div>
      <div class="du-flex-column width-80 gap-xs">
        <span class="font-s" translate="platform"></span>
        <span class="bold">{{ device.platform }}</span>
      </div>
    </div>
  </div>
  <div class="du-flex-column gap-xs p-xl">
    <span class="bold" translate="comment"></span>
    <textarea class="b-grey p-xs height-120" placeholder="{{ 'whatNotWorking' | translate }}" [(ngModel)]="note"></textarea>
  </div>
</div>

<div class="pl-xl pr-xl pb-xl">
  <button class="width-full btn-primary btn-round" translate="send" [disabled]="!note" (click)="sendSupportRequest()"></button>
</div>
