import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, forkJoin, from, map, of, switchMap, take } from 'rxjs';

import { DBService, NotificationService, SessionStorageService, ValidationService } from '@shared/services';
import { ActivityReportLocal, DailyLocalExtended } from '@shared/factories';
import { DailyLocalExtendedModel } from '@shared/models';

@Component({
  selector:      'report-use-dailies',
  templateUrl: './report-use-dailies.component.html',
  host: { class: 'height-full du-flex-column du-flex-justify p-xl' },
})
export class ReportUseDailiesComponent implements OnInit {
  report: ActivityReportLocal;
  constructor(
    private router:                Router,
    private dbService:             DBService,
    private notificationService:   NotificationService,
    private validationService:     ValidationService,
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.notificationService.wait();
    this.sessionStorageService.setHeaderTitle('activityReport');
    this.sessionStorageService.setProgressBar(3, 7);
    this.sessionStorageService.temporaryReport.pipe(
      take(1),
      switchMap((report: ActivityReportLocal) => {
        if (!report || !report.assignment || !report.startDate) return of(null); 
        else return forkJoin([
          of(report),
          this.loadLocalDailies(report)
        ]);
      })
    ).subscribe(
      (res: Array<ActivityReportLocal | DailyLocalExtended[]>) => {
        if (res) {
          this.report = res[0] as ActivityReportLocal;
          this.report.dailyReports = res[1] as DailyLocalExtended[];
          this.validationService.validateAR(this.report);
          this.notificationService.close();
        } else this.goToStart();
      },
      err => this.notificationService.alert(err)
    );
  }

  private loadLocalDailies(report: ActivityReportLocal): Observable<DailyLocalExtended[]> {
    return from(this.dbService.loadMultipleFromDB('localDailies', { 'assignment.id': report.assignment.id  })).pipe(
      map((dailies: DailyLocalExtendedModel[]) => {
        return dailies.map(d => new DailyLocalExtended(d))
                      .filter(d => d.startsAt.getTime() >= report.startDate.getTime() && d.startsAt.getTime() <= report.endDate.getTime())
                      .sort((a, b) => a.startsAt.getTime() - b.startsAt.getTime())
      })
    );
  }

  private goToStart(): void {
    this.router.navigateByUrl('time-tracking/preselect-report');
  }

  clearDailies(): void {
    this.report.dailyReports = null;
    this.goNext();
  }

  goNext(): void {
    this.sessionStorageService.setTemporaryReport(this.report);
    this.router.navigateByUrl(`time-tracking/report-constructor-ar`);
  }

}
