<div class="du-flex-column du-flex-1 overflow-auto" *ngIf="ebs">
  <div class="du-flex-column gap-s p-xl bb-grey">
    <div class="du-flex-align du-flex-justify">
      <div class="du-flex-column gap-xs font-s">
        <span>Mitarbeiter</span>
        <span class="font-500">{{ ebs.persvorname }} {{ ebs.persname }}</span>
      </div>
      <div class="du-flex-align gap-s p-s b-blue b-radius-30 font-s" (click)="call(ebs.filtelefon1)">
        <img src="assets/svg/phone-blue.svg">
        <span class="color-blue">Niederlassung</span>
      </div>
    </div>
    <span class="font-500 lh-l">{{ ebs.title }}</span>
  </div>

  <div class="du-flex-column bb-grey du-flex-shrink-0">
    <div class="du-flex-justify du-flex-align pl-xl pr-xl pt-m pb-m bg-card-grey" (click)="collapseBlockOne = !collapseBlockOne">
      <span class="font-500">Einsatzdaten</span>
      <img [class.rotate]="collapseBlockOne" src="assets/svg/arrow-down-blue.svg">
    </div>
    <div class="du-flex-column pl-xl pr-xl pt-s pb-s" @collapse *ngIf="collapseBlockOne">
      <div class="du-flex-column gap-s overflow">
        <div class="du-flex-column gap-s">
          <span class="du-flex-align-start">
            <span class="pt-xxs font-s width-200">Einsatzbeginn</span>
            <span class="du-flex-1 font-500 lh-l">{{ ebs.aufueberlbeginn | date: 'dd.MM.yyyy' }}</span>
          </span>
          <span class="du-flex-align-start">
            <span class="pt-xxs font-s width-200">Melduhrzeit</span>
            <span class="du-flex-1 font-500 lh-l">{{ ebs.aufmuhrzeit || '-' }}</span>
          </span>
          <span class="du-flex-align-start">
            <span class="pt-xxs font-s width-200">Arbeitszeit pro Woche</span>
            <span class="du-flex-1 font-500 lh-l">{{ ebs.aufarbzeitwoche || '0' }}</span>
          </span>
          <span class="du-flex-align-start">
            <span class="pt-xxs font-s width-200">Meldeansprechpartner</span>
            <span class="du-flex-1 font-500 lh-l">{{ ebs.aufansprech1 || '-' }}</span>
          </span>
          <span class="du-flex-align-start">
            <span class="pt-xxs font-s width-200">Meldeadresse</span>
            <span class="du-flex-1 font-500 lh-l" (click)="openMap(ebs.aufadresse1)" >{{ ebs.aufadresse1 || '-' }}</span>
          </span>
          <span class="du-flex-align-start">
            <span class="pt-xxs font-s width-200">Meldeansprechpartner Telefon</span>
            <span class="du-flex-1 font-500 lh-l" (click)="call(ebs.anspr_telefon)">{{ ebs.anspr_telefon || '-' }}</span>
          </span>
          <span class="du-flex-align-start">
            <span class="pt-xxs font-s width-200">Meldetelefon</span>
            <span class="du-flex-1 font-500 lh-l" (click)="call(ebs.auftelefon1)">{{ ebs.auftelefon1 || '-' }}</span>
          </span>
          <span class="du-flex-align-start">
            <span class="pt-xxs font-s width-200">Betriebsstätte</span>
            <span class="du-flex-1 font-500 lh-l">{{ ebs.aufbetrstaette || '-' }}</span>
          </span>
          <span class="du-flex-align-start">
            <span class="pt-xxs font-s width-200">Einsatzberuf</span>
            <span class="du-flex-1 font-500 lh-l">{{ ebs.aufberuf || '-' }}</span>
          </span>
          <span class="du-flex-align-start">
            <span class="pt-xxs font-s width-200">Sonstige Bemerkungen</span>
            <span class="du-flex-1 font-500 lh-l">{{ ebs.auftextmarken4 || '-' }}</span>
          </span>
        </div>
        <div class="du-flex-column gap-xxs">
          <span class="font-s">Einsatztätigkeitsbeschreibung</span>
          <span class="font-500 lh-l">{{ ebs.aufpostaet1 || '-' }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="du-flex-column bb-grey du-flex-shrink-0">
    <div class="du-flex-justify du-flex-align pl-xl pr-xl pt-m pb-m bg-card-grey" (click)="collapseBlockTwo = !collapseBlockTwo">
      <span class="font-500">Sicherheits- und Schutzausrüstung</span>
      <img [class.rotate]="collapseBlockTwo" src="assets/svg/arrow-down-blue.svg">
    </div>
    <div class="du-flex-column pl-xl pr-xl pt-s pb-s" @collapse *ngIf="collapseBlockTwo">
      <div class="du-flex-column gap-m overflow">
        <div class="du-flex-column gap-s">
          <span class="font-s">Persönliche Schutzausrüstung</span>
          <ul class="du-flex-column gap-xs ml-xl list-disc">
            <li class="font-500" *ngFor="let item of ebs.aufarbs">{{item}}</li>
          </ul>
        </div>

        <div class="du-flex-column gap-xxs">
          <span class="font-s">Gefährliche Arbeit</span>
          <span class="font-500 lh-l">{{ ebs.aufgefahrarbeit || 'Nein' }}</span>
        </div>

        <div class="bb-grey"></div>

        <div class="du-flex-column gap-xs">
          <span class="font-s">Untersuchungen</span>
          <span class="font-500 lh-l">{{ ebs.aufuntersuchidx || 'Nein' }}</span>
        </div>

        <div class="bb-grey"></div>

        <div class="du-flex-column gap-xs">
          <span class="font-s">Sicherheits- und Gesundheitsschutz</span>
          <span class="font-500 lh-l">Die Sicherheitsunterweisung des Mitarbeiters deckt auch die o. a. Tätigkeit ab. Der Mitarbeiter wird vor Antritt seiner Tätigkeit vom Entleiher eine Belehrung über die Sicherheitsvorschriften am Arbeitsplatz erhalten. Er verpflichtet sich, die für diesen Einsatz notwendige Schutzausrüstung an seinem Arbeitsplatz zu tragen.</span>
          <span class="font-500 lh-l">Arbeitsmedizinische Vorsorge für diesen Einsatz wurde dem Mitarbeiter angeboten.</span>
        </div>
      </div>
    </div>
  </div>

  <div class="du-flex-column bb-grey du-flex-shrink-0">
    <div class="du-flex-justify du-flex-align pl-xl pr-xl pt-m pb-m bg-card-grey" (click)="collapseBlockThree = !collapseBlockThree">
      <span class="font-500">Aufwandsentschädigung / Tag</span>
      <img [class.rotate]="collapseBlockThree" src="assets/svg/arrow-down-blue.svg">
    </div>
    <div class="du-flex-column pl-xl pr-xl pt-s pb-s" @collapse *ngIf="collapseBlockThree">
      <div class="du-flex-column gap-m overflow">
        <div class="du-flex-column gap-s">
          <span class="du-flex-align-start">
            <span class="pt-xxs font-s width-200">KM Entfernung</span>
            <span class="font-500 du-flex-1 lh-l gap-xxs">{{ ebs.aufueentfernung || '0' }} Km</span>
          </span>
          <span class="du-flex-align-start">
            <span class="pt-xxs font-s width-200">Fahrbetrag</span>
            <span class="font-500 du-flex-1 lh-l gap-xxs">{{ ebs.auffahrbetrag || '0' }} €</span>
          </span>
          <span class="du-flex-align-start">
            <span class="pt-xxs font-s width-200">VMA</span>
            <span class="font-500 du-flex-1 lh-l gap-xxs">{{ ebs.aufvmabetrag || '0' }} €</span>
          </span>
          <span class="du-flex-align-start">
            <span class="pt-xxs font-s width-200">Übernachtungsbetrag</span>
            <span class="font-500 du-flex-1 lh-l gap-xxs">{{ ebs.aufuebernbetrag || '0' }} €</span>
          </span>
        </div>

        <div class="bb-grey"></div>

        <div class="du-flex-column gap-s">
          <span class="font-500">Zulagen(Brutto)</span>
          <span class="du-flex-align-start">
            <span class="pt-xxs font-s width-200">Entsendezulage</span>
            <span class="font-500 du-flex-1 lh-l gap-xxs">{{ ebs.pel705preis || '0' }} €</span>
          </span>
          <span class="du-flex-align-start">
            <span class="pt-xxs font-s width-200">Sonstige Zulagen</span>
            <span class="font-500 du-flex-1 lh-l gap-xxs">{{ ebs.txt_sonstigeZulage || '0' }} €</span>
          </span>
        </div>

        <div class="du-flex-column gap-xs">
          <span class="font-s">Mehrarbeit</span>
          <span class="font-500 lh-l">Der Mitarbeiter verpflichtet sich, im gesetzlich zulässigen Rahmen Mehrarbeit zu leisten.</span>
        </div>

        <div class="bb-grey"></div>

        <div class="du-flex-column gap-s">
          <span class="font-500">Tarifliches</span>
          <span class="du-flex-align-start">
            <span class="pt-xxs font-s width-200">Entgeltgruppe</span>
            <span class="font-500 du-flex-1 lh-l gap-xxs">{{ ebs.auftarifgruppe || '-' }}</span>
          </span>
          <span class="du-flex-align-start">
            <span class="pt-xxs font-s width-200">Entgelttarif</span>
            <span class="font-500 du-flex-1 lh-l gap-xxs">{{ ebs.auftarifmagruppe || '-' }}</span>
          </span>
          <span class="du-flex-align-start">
            <span class="pt-xxs font-s width-200">Decklungsbetrag</span>
            <span class="font-500 du-flex-1 lh-l gap-xxs">{{ ebs.aufdeckelbetragakt || '-' }}</span>
          </span>
          <span class="du-flex-align-start">
            <span class="pt-xxs font-s width-200">Branchenzuschlagstarifvertrag</span>
            <span class="font-500 du-flex-1 lh-l gap-xxs">{{ ebs.auftarifbranche || '-' }}</span>
          </span>
          <span class="du-flex-column gap-s">
            <span class="font-s">Tarifvertrag</span>
            <span class="font-500 lh-l">{{ ebs.auftarifvertrag || '-' }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="du-flex-column bb-grey du-flex-shrink-0">
    <div class="du-flex-justify du-flex-align pl-xl pr-xl pt-m pb-m bg-card-grey" (click)="collapseBlockFour = !collapseBlockFour">
      <span class="font-500">Kunde</span>
      <img [class.rotate]="collapseBlockFour" src="assets/svg/arrow-down-blue.svg">
    </div>
    <div class="du-flex-column pl-xl pr-xl pt-s pb-s" @collapse *ngIf="collapseBlockFour">
      <span class="du-flex-column gap-m overflow">
        <span class="du-flex-column gap-s">
          <span class="font-s">Anmerkungen (Kunden)</span>
          <span class="font-500 lh-l">Der Mitarbeiter ist verpflichtet, Tempton unverzüglich zu informieren, wenn er mit dem oben genannten Kunden oder einem Unternehmen, das mitdiesem Kunden einen Konzern gemäß §18 AktG bildet, in den letzten sechs Monaten vor Beginn der Überlassung in einem Arbeitsverhältnis gestanden hat oder in diesem Zeitraum -egal für welchen Arbeitgeber - bereits einmal in dem nachfolgend genannten Einsatzbetrieb eingesetzt war. Bei Arbeitsverhinderung verpflichtet sich der Mitarbeiter / die Mitarbeiterin vor Arbeitsbeginn den Kunden und seine / ihre Tempton-Niederlassung (erreichbar unter der Telefonnummer: {{ ebs.filtelefon1 }}) bis spätestens 8:30 Uhr unter Angabe der Gründe zu verständigen. Die Meldepflicht besteht auch, wenn aus irgendwelchen Gründen, die von Tempton mit dem Kunden vereinbarte Einsatzzeit nicht oder nicht voll abgeleistet werden kann, z.B. wenn der Kunde keinen oder nur einen kürzeren Einsatz ermöglicht.</span>
        </span>
        <span class="du-flex-column gap-s">
          <span class="font-s">Hinweis</span>
          <span class="font-500 lh-l">Wir informieren Sie ferner gem. §11 Abs. 2 AÜG darüber, dass Sie im o. g. Einsatz als Leiharbeitnehmer (m/w/d) tätig werden.</span>
        </span>
      </span>
    </div>
  </div>
</div>

<div class="p-xl">
  <button class="width-full btn-primary btn-round" (click)="confirm()" [disabled]="!ebs || ebs.confirmedAt">{{ !ebs || ebs.confirmedAt ? 'Bestätigt' : 'Bestätigen' }}</button>
</div>
