<div class="du-flex-align gap-m" *ngIf="left">
  <div *ngFor="let el of left" [ngClass]="el.class" (click)="el.callback && el.callback()">
    <img  *ngIf="el.svg"  [src]="'assets/svg/' + el.svg + '.svg'">
    <span *ngIf="el.icon" [ngClass]="'du-icon-' + el.icon"></span>
    <span *ngIf="el.label">{{ el.label | translate }}</span>
  </div>
</div>

<span class="absolute left-70 right-70 du-flex-center color-grey bold">{{ title | translate }}</span>

<div class="du-flex-align gap-m" *ngIf="right">
  <div *ngFor="let el of right" [ngClass]="el.class" (click)="el.callback && el.callback()">
    <img  *ngIf="el.svg"  [src]="'assets/svg/' + el.svg + '.svg'">
    <span *ngIf="el.icon" [ngClass]="'du-icon-' + el.icon"></span>
    <span *ngIf="el.label">{{ el.label | translate }}</span>
  </div>
</div>
