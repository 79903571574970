<div class="du-flex-justify du-flex-align bb-grey">
  <img  class="pl-xl pr-xl pt-m pb-m mra" src="assets/svg/arrow-left.svg" (click)="close()">
  <span class="absolute left-70 right-70 du-flex-center color-grey bold" translate="filters"></span>
  <span class="pl-xl pr-xl pt-m pb-m mla text-right color-blue" translate="reset" (click)="reset()"></span>
</div>

<assignment-picker *ngIf="assignment" [assignment]="activeAssignment" (selectCallback)="activeAssignment = $event"></assignment-picker>

<div class="du-flex-column gap-xl p-xl">
  <div class="du-flex-column gap-xs" *ngIf="from && to">
    <span class="bold" translate="period"></span>
    <div class="du-flex-align gap-l">
      <span translate="from"></span>
      <input class="date-input" type="date" placeholder="01.01.2001" [ngModel]="fromDate | date:'yyyy-MM-dd'" (ngModelChange)="adjustFrom($event)">
      <span translate="to"></span>
      <input class="date-input" type="date" placeholder="01.01.2001" [ngModel]="toDate | date:'yyyy-MM-dd'"   (ngModelChange)="adjustTo($event)">
    </div>
  </div>

  <div class="du-flex-column gap-m" *ngFor="let section of pills">
    <span class="bold">{{ section.label | translate }}</span>
    <ul class="du-flex-wrap gap-xs" >
      <li *ngFor="let pill of section.pills" (click)="pill.active = !pill.active"
           class="b-radius pt-s pb-s pl-m pr-m"
          [class.bg-card-grey]="!pill.active"
          [class.bg-blue]="pill.active"
          [class.color-white]="pill.active">
        {{ pill.label | translate }}
      </li>
    </ul>
  </div>

  <button class="btn-primary btn-round" translate="showResults" (click)="applyFilters()"></button>
</div>
