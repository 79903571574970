import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { SalaryReport } from '@shared/factories';

import { PagingModel, PillSectionModel, SortFlowModel } from '@shared/models';
import { NotificationService, SalaryReportsService, SessionStorageService } from '@shared/services';

@Component({
  templateUrl: './salary-overview.component.html',
  host: { class: 'height-full du-flex-column gap-m p-xl overflow' }
})
export class SalaryOverviewComponent implements OnInit {
  search:  string;
  page:    number;
  paging:  PagingModel;

  pills:   PillSectionModel[] = [];
  sort:    SortFlowModel[]    = [];

  reports: SalaryReport[]     = [];
  constructor(
    private location:              Location,
    private salaryReportsService:  SalaryReportsService,
    private notificationService:   NotificationService,
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.prepareHeader();
    this.sessionStorageService.paging.subscribe(paging => this.paging = paging);
    this.loadReports();
  }

  private prepareHeader(): void {
    this.sessionStorageService.setHeaderTitle('salaryReports');
    this.sessionStorageService.setHeaderControls({ left: [{ icon: 'arrow-big-left color-blue font-icon', callback: () => this.location.back() }] });
    this.sessionStorageService.setProgressBar(null);
  }

  loadReports(reload: boolean = null): void {
    if (!this.paging || !this.paging.total_pages || !this.page || this.page < this.paging.total_pages || reload) {
      this.page = reload || !this.page ? 1 : this.page+1;
      this.notificationService.wait();
      this.salaryReportsService.loadSalaryReports(this.page).subscribe(
        reports => {
          if (reload) this.reports = [...reports];
          else this.reports = [...this.reports, ...reports];
          this.sessionStorageService.setCachedEntries(this.reports);
          this.notificationService.close();
        },
        err => this.notificationService.alert(err),
        () => this.sessionStorageService.setPulltoRefreshState(true)
      );
    }
  }

}
