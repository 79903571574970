import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, from, of, switchMap, take, tap } from 'rxjs';

import { ActivityReportLocal, DailyLocal, Holiday, Template } from '@shared/factories';
import { DBService, NotificationService, SessionStorageService, UserService, ValidationService, WeeksService } from '@shared/services';
import { HolidayModel, TemplateModel } from '@shared/models';
import { fadeIn } from '@shared/animations';

@Component({
  templateUrl: './report-constructor.component.html',
  host: { class: 'height-full du-flex-column du-flex-justify overflow' },
  animations: [ fadeIn ]
})
export class ReportConstructorComponent implements OnInit, AfterViewInit {
  report:            ActivityReportLocal;
  activeReportIndex: number    = 0;
  holidays:          Holiday[] = [];
  useCase:           string;

  lastScrollPos:     number;
  skipScrollCheck:   boolean;
  hammerContainer:   HammerManager;

  templateId:        number;
  templateName:      string;

  tutorialStep:      number = 0;
  tempDailyState:    boolean;
  @HostListener('document:mousedown', ['$event']) close(event: MouseEvent) {
    if (this.tutorialStep) {
      event.preventDefault();
      event.stopPropagation();
      ++this.tutorialStep;
      if (this.tutorialStep === 3) this.report.dailyReports[this.activeReportIndex].placeholder = false;
      if (this.tutorialStep > 6) this.endOnboarding();
    }
  }
  constructor(
    private route:                 ActivatedRoute,
    private router:                Router,
    private location:              Location,
    private sessionStorageService: SessionStorageService,
    private notificationService:   NotificationService,
    private validationService:     ValidationService,
    private weeksService:          WeeksService,
    private userService:           UserService,
    private dbService:             DBService
  ) {}

  ngOnInit(): void {
    this.useCase = this.route.snapshot.data['useCase'];
    this.prepareHeader();
    if (this.useCase === 't') this.prepareTemplate();
    else this.prepareReport();
  }

  ngAfterViewInit(): void {
    this.setupHammerContainer();
  }

  private setupHammerContainer(): void {
    let container = document.getElementById('hammerContainer');
    if (container) this.hammerContainer = new Hammer(container);
  }

  private prepareTemplate(): void {
    this.route.params.pipe(
      take(1),
      switchMap(params => {
        if (+params['templateId']) return from(this.dbService.loadOneFromDB('templates', +params['templateId']));
        return of(null);
      })
    ).subscribe((template: TemplateModel) => {
      this.report = new ActivityReportLocal({
        start_date: this.weeksService.getStartOfWeek(+new Date()-7*24*60*60*1000).toISOString(),
        end_date:   this.weeksService.getEndOfWeek(+new Date()-7*24*60*60*1000).toISOString(),
      });
      this.report.dailyReports = this.prepareDefaultDailies(this.report.startDate, this.report.endDate);
      if (template) this.mapTemplate(new Template(template));
    });
    if (!this.userService.checkTutorial('t')) this.sessionStorageService.pushDynamicComponent({
      component: 'TutorialScreen',
      props: { useCase: 't' }
    });
  }

  private mapTemplate(template: Template): void {
    this.templateId   = template.id;
    this.templateName = template.name;

    template.dailies.forEach(d => {
      let day = this.report.dailyReports.find(dr => dr.startsAt.getDay() === d.startsAt.getDay());
      if (day) {
        day.startsAt.setHours(d.startsAt.getHours(), d.startsAt.getMinutes(), d.startsAt.getSeconds(), 0);
        day.endsAt.setHours(  d.endsAt.getHours(),   d.endsAt.getMinutes(),   d.endsAt.getSeconds(),   0);
        day.pauses = d.pauses;
        day.pauses.forEach(p => {
          p.start.setFullYear(day.startsAt.getFullYear());
          p.start.setMonth(day.startsAt.getMonth());
          p.start.setDate(day.startsAt.getDate());
          p.end.setFullYear(day.startsAt.getFullYear());
          p.end.setMonth(day.startsAt.getMonth());
          p.end.setDate(day.startsAt.getDate());
          if (p.end.getTime() < p.start.getTime()) p.end.setDate(p.end.getDate()+1);
        });
        day.project     = d.project;
        day.placeholder = false;
      }
    });
  }

  private prepareReport(): void {
    this.sessionStorageService.temporaryReport.pipe(
      take(1),
      switchMap((report: ActivityReportLocal): Observable<HolidayModel[]> => {
        if (!report || !report.assignment|| !report.startDate) this.goBack();
        else {
          this.report = report;
          return from(this.dbService.loadMultipleFromDB('holidays', { state_iso: this.report.assignment.zipCode })).pipe(
            tap(holidays => { this.holidays = holidays.map(h => new Holiday(h)); }),
            tap(() => this.report.dailyReports = this.prepareDefaultDailies(this.report.startDate, this.report.endDate)),
            tap(() => this.validate())
          );
        }
        return of();
      })
    ).subscribe();
  }

  moveToReport(index: number): void {
    let el = document.getElementsByTagName('daily-constructor-item')[index];
    el.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    this.activeReportIndex = index;
  }

  adjustDaily({ index, daily }: { index: number, daily: DailyLocal }): void {
    this.report.dailyReports[index] = daily;
  }

  resetToDefault({ index, daily }: { index: number, daily: DailyLocal }): void {
    this.report.dailyReports[index] = this.prepareDefaultDaily(daily.startsAt);
  }

  private prepareDefaultDailies(start: Date, end: Date): DailyLocal[] {
    start.setHours(0,0,0,0);
    end.setHours(0,0,0,0);

    let dailies = this.report.dailyReports || [];
    let day = new Date(start);
    do {
      let created = dailies.find(d => {
        let date = new Date(d.startsAt);
        date.setHours(0,0,0,0);
        return date.getTime() === day.getTime();
      })
      if (!created) dailies.push(this.prepareDefaultDaily(day));

      day.setDate(day.getDate() + 1);
    } while (day.getTime() <= end.getTime());

    return dailies.sort((a, b) => a.startsAt.getTime() - b.startsAt.getTime());
  }

  private prepareDefaultDaily(day: Date): DailyLocal {
    day.setHours(0,0,0,0);
    return new DailyLocal({
      placeholder: true,
      mileage:     this.report.mileageStart && this.report.mileageEnd ? day.getTime() >= this.report.mileageStart.getTime() && day.getTime() <= this.report.mileageEnd.getTime() : null,
      holidays:    this.holidays?.filter(h => h.date.getTime() === day.getTime()),
      started_at:  new Date(day.getFullYear(), day.getMonth(), day.getDate(), 8,  0, 0),
      ended_at:    new Date(day.getFullYear(), day.getMonth(), day.getDate(), 16, 0, 0),
      pauses: [{
        id: 1,
        start: new Date(day.getFullYear(), day.getMonth(), day.getDate(), 12,  0, 0),
        end:   new Date(day.getFullYear(), day.getMonth(), day.getDate(), 12, 30, 0),
      }]
    });
  }

  validate(): void {
    this.cleanErrors();
    this.validationService.validateAR(this.report);
  }

  private cleanErrors(): void {
    this.report.dailyReports.forEach(d => {
      d.errors = [];
      if (d.pauses && d.pauses.length) d.pauses.forEach(p => { p.errors = []; });

      d.endsAt.setDate(d.startsAt.getDate());
      d.endsAt.setMonth(d.startsAt.getMonth());
      if (d.endsAt < d.startsAt) d.endsAt.setDate(d.startsAt.getDate() + 1);
    });
  }

  private prepareHeader(): void {
    if (this.useCase === 'ar' || this.useCase === 'ar-t') {
      this.sessionStorageService.setHeaderTitle('activityReport');
      this.sessionStorageService.setProgressBar(3, 6);
    } else if (this.useCase === 'dr') {
      this.sessionStorageService.setHeaderTitle('combineDailyReports');
      this.sessionStorageService.setProgressBar(null);
    } else if (this.useCase === 't') {
      this.sessionStorageService.setHeaderTitle('templateCreation');
      this.sessionStorageService.setProgressBar(null);
    }
    this.sessionStorageService.setHeaderControls({
      left:  [{ icon: 'arrow-big-left color-blue font-icon',   callback: () => this.location.back()   }],
      right: [{ icon: 'circle-click color-blue font-icon', callback: () => this.startOnboarding() }]
    });
  }

  private goBack(): void {
    if (this.useCase === 'ar'  ) this.router.navigateByUrl('time-tracking/preselect-report-ar');
    if (this.useCase === 'ar-t') this.router.navigateByUrl('time-tracking/week-select-ar-t');
    if (this.useCase === 'dr'  ) this.router.navigateByUrl('time-tracking/daily-overview');
    if (this.useCase === 't'   ) this.router.navigateByUrl('time-tracking/templates-overview');
  }

  callbackHandler(): void {
    if (this.useCase === 't') this.saveTemplate();
    else {
      this.sessionStorageService.setTemporaryReport(this.report);
      this.router.navigateByUrl(`time-tracking/report-confirm-${this.useCase}`);
    }
  }

  private saveTemplate(): void {
    this.notificationService.wait();
    let template = new Template({
      id:      this.templateId,
      name:    this.templateName,
      dailies: this.report.activeDailyReports.map(d => d.toJSON())
    });
    this.dbService.saveOneToDB('templates', template)
    .then(() => {
      if (this.templateId) this.location.back();
      else this.router.navigate(['time-tracking/info'], { queryParams: { type: 'save', useCase: 't' }});
      this.notificationService.close();
    })
    .catch(err => this.notificationService.alert(err))
  }

  private startOnboarding(): void {
    this.tutorialStep = 1;
    this.toggleDaysForOnboardingDemo();
    this.tempDailyState = this.report.dailyReports[this.activeReportIndex].placeholder;
    this.report.dailyReports[this.activeReportIndex].placeholder = true;
    this.sessionStorageService.pushOverflowStack('TutorialStep');
  }

  private toggleDaysForOnboardingDemo() {
    for (let i = 0; i < this.activeReportIndex; i++) {
      let el = document.getElementsByTagName('daily-constructor-item')[i];
      el.classList.toggle('hide');
    }
  }

  private endOnboarding(): void {
    setTimeout(() => {
      this.tutorialStep = 0;
      this.toggleDaysForOnboardingDemo();
      this.report.dailyReports[this.activeReportIndex].placeholder = this.tempDailyState;
    });
    this.sessionStorageService.popOverflowStack('TutorialStep');
  }

  swipeLeft(): void {
    this.moveToReport(Math.min(this.activeReportIndex+1, 6));
  }

  swipeRight(): void {
    this.moveToReport(Math.max(this.activeReportIndex-1, 0));
  }

}
