<div class="du-flex-justify p-m bb-grey">
  <span class="bold">{{ report.createdAt | date: 'dd.MM.YYYY' }}</span>
  <span>{{ report.docType.name }}</span>
</div>

<div class="du-flex-justify gap-m p-m">
  <span class="lh-l">{{ report.subject }}</span>
  <div class="du-flex gap-m">
    <div class="du-flex-center bg-card-grey p-s b-radius" translate="view" [routerLink]="['/time-tracking/salary-details', report.id]"></div>
    <div class="b-blue pt-xs pb-xs pl-s pr-s" (click)="saveReport(report)">
      <img src="assets/svg/save-blue.svg">
    </div>
  </div>
</div>
