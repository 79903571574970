import { ReleaseModel } from "@shared/models";

export class Release {
  id:          number;
  title:       string;
  version:     string;
  application: string;
  notes:       string;
  parcedNotes: string[];
  releasedAt:  Date;
  constructor(data: ReleaseModel) {
    this.id          = data.id;
    this.title       = data.title;
    this.version     = data.version;
    this.application = data.application;
    this.notes       = data.notes;
    this.parcedNotes = this.parceNotes(data.notes);
    this.releasedAt  = this.parceDate(data.released_at);
  }

  private parceNotes(notes: string): string[] {
    return notes.split('- ').map(n => n.trim()).filter(n => n);
  }

  private parceDate(date: any) {
    return date ? date instanceof Date ? date : new Date(date) : null;
  }

  toJSON(): ReleaseModel {
    return {
      id:          this.id          ? this.id                       : null,
      title:       this.title       ? this.title                    : null,
      version:     this.version     ? this.version                  : null,
      application: this.application ? this.application              : null,
      notes:       this.notes       ? this.notes                    : null,
      released_at: this.releasedAt  ? this.releasedAt.toISOString() : null
    };
  }
}
