import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse, HttpClient } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, of  } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { AuthService, NotificationService } from '@shared/services';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class JwtInterceptor implements HttpInterceptor {
  private isRefreshing: boolean = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private http:                HttpClient,
    private authService:         AuthService,
    private notificationService: NotificationService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = this.addDefaultHeaders(request);
    if (this.authService.accessTokenValue) request = this.addToken(request);
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && !request.url.includes('/sign_out')) {
          let accessToken = event.headers.get('authorization');
          if (accessToken) this.authService.saveAccessToken(accessToken);
        }
        return next.handle(request);
      }),
      catchError((err: HttpErrorResponse) => {
        let error;
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401 && this.authService.refreshTokenValue) {
            let tokenExpired = err.error && (err.error.message === 'invalid_refresh_token' || err.error.message === 'revoked token');
            let signatureExpired = err.error && err.error.errors && err.error.errors.find((e: any) => e.code === 'signature_has_expired') ||
                                  err.error.message && err.error.message === 'Signature has expired';

            if (this.authService.refreshTokenValue && signatureExpired && !tokenExpired) return this.handle401Error(request, next);
            this.authService.logout();
            this.notificationService.alert('Sie sind nicht authorisiert. Bitte loggen Sie sich erneut ein.');
            return of([]) as Observable<any>;
          }
          else if (err.error && err.error.errors && err.error.errors.find((e: any) => e.code === 'illegal_role' && (err.status === 401 || err.status === 403))) error = err.error.errors;
          else if (err.status === 403) error = 'Aktion verboten.';
          else if (err.status === 404) error = 'Eintrag nicht gefunden.';
          else if (err.status === 503 || err.status === 500) error = 'Die Applikation befindet sich im Moment im Wartungsmodus. Bitte versuchen Sie es in wenigen Minuten erneut.';
        }
        error = error || err.error.message || err.error.errors || err.statusText;
        if (!error || (typeof error === 'string' && error.toLowerCase().includes('unknown error'))) error = 'Hoppla, da ist ein Fehler aufgetreten. Bitte lade die Seite neu.';
        else if (Array.isArray(error)) error = error.map(e => this.formatV3Error(e));
        return throwError(error);
      })
    )
  }

  formatV3Error(err: any) {
    if (err.code) return err.title || err.message;
    return err;
  }

  getErrors(res: any): string {
    return res.error && (res.error.message || res.error.errors.map((e: any) => e.title).join('\n'));
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isRefreshing) {      
      return this.refreshTokenSubject.pipe(
        filter(result => result !== null),
        take(1),
        switchMap(() => {
          let newRequest = this.addToken(request);
          newRequest = this.addDefaultHeaders(newRequest);
          return next.handle(newRequest) as Observable<HttpEvent<any>>;
        })
      )
    } else {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      return this.http.post<any>(`${environment.apiUrl}/api/v3/users/refresh`, {
        'refresh_token': this.authService.refreshTokenValue
      }).pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.authService.saveRefreshToken(token.refresh_token);
          this.refreshTokenSubject.next(token);
          return next.handle(this.addDefaultHeaders(this.addToken(request))) as Observable<HttpEvent<any>>;
        }),
        catchError((err: any) => {
          this.isRefreshing = false;
          this.authService.logout();
          return throwError(err);
        })
      )
    }
  }

  private addToken(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        'Authorization': `${this.authService.accessTokenValue}`
      }
    });
  }

  private addDefaultHeaders(request: HttpRequest<any>): HttpRequest<any> {
    let req = request.clone({ setHeaders: {
      'X-AppName': 'TemptonApp',
      'X-Locale': 'de'
    }});
    if (request.url.includes('/v3/')) req = req.clone({ setHeaders: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json'
    }});
    return req;
  }

}
