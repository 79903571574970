import { ValidatorFn, Validators } from '@angular/forms';
import { PatternValidator        } from '@shared/validators';

export function PasswordStrengthValidator(): ValidatorFn {
  return Validators.compose([
    Validators.required,
    Validators.minLength(8),
    PatternValidator(new RegExp("(?=.*[0-9])"),      { requiresDigit:        true }),
    PatternValidator(new RegExp("(?=.*[A-Z])"),      { requiresUppercase:    true }),
    PatternValidator(new RegExp("(?=.*[a-z])"),      { requiresLowercase:    true }),
    PatternValidator(new RegExp("(?=.*[$@^!%*?&])"), { requiresSpecialChars: true })
  ]);
}