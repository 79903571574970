import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, defer, forkJoin, from, map, of, take, tap } from 'rxjs';

import { App       } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';

import { fadeIn } from '@shared/animations';
import { Banner, PushNotificationUnread, User } from '@shared/factories';
import { SessionStorageService, UserService, NotificationService, DBService } from '@shared/services';

@Component({
  selector:      'home',
  templateUrl: './home.component.html',
  host: { class: 'height-full du-flex-column p-xl gap-xl overflow-y-auto' },
  animations: [fadeIn]
})
export class HomeComponent implements OnInit, OnDestroy {
  user:            User;
  campaignBanner:  Banner;
  skipBanner:      boolean;

  unconfirmedEbs:  boolean;
  photoReports:    boolean;
  photoDocuments:  boolean;
  unreadVacations: number;

  tutorialStep:    number = 0;
  closeApp:        boolean;
  initLoad:        boolean;
  @HostListener('document:mousedown', ['$event']) close(event: MouseEvent) {
    if (this.tutorialStep) ++this.tutorialStep;
    if (this.tutorialStep > 3) {
      this.sessionStorageService.popOverflowStack('TutorialStep');
      setTimeout(() => this.tutorialStep = 0);
    }
  }
  constructor(
    private router:                Router,
    private userService:           UserService,
    private dbService:             DBService,
    public  notificationService:   NotificationService,
    public  sessionStorageService: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.prepareHeader();
    this.clearTemp();
    this.closeAppOnDoubleBack();
    this.userService.currentUser.pipe(take(1)).subscribe(user => user && this.syncData());
  }

  ngOnDestroy(): void {
    if (Capacitor.isNativePlatform) App.removeAllListeners();
  }

  private prepareHeader(): void {
    this.sessionStorageService.setHeaderTitle(' ');
    this.sessionStorageService.setHeaderControls({
      left: [
        { icon: 'sidebar color-blue font-icon', callback: () => this.sessionStorageService.pushDynamicComponent({ component: 'Sidebar' }) },
        { svg: 'logo-blue' }
      ],
      right: [
        { icon: 'bell    color-blue font-icon', callback: () => this.startTutorial(),       class: 'relative dot-orange-after-top-right' },
        { icon: 'message color-blue font-icon', callback: () => this.router.navigate(['']), class: 'relative dot-orange-after-top-right' },
      ]
    });
  }

  private clearTemp(): void {
    this.sessionStorageService.setProgressBar(null);
    this.sessionStorageService.setTemporaryReport(null);
    this.sessionStorageService.setTemporaryDocument(null);
    this.sessionStorageService.setTemporaryVacation(null);
    this.sessionStorageService.setTemporaryAssignments(null);
    this.sessionStorageService.setCachedEntries(null);
    this.sessionStorageService.setSortFlow(null);
    this.sessionStorageService.setFilterFlow(null);
    this.sessionStorageService.setTabFlow(null);
  }

  private closeAppOnDoubleBack(): void { 
    if (Capacitor.isNativePlatform) {
      App.addListener('backButton', () => {
        if (this.closeApp) {
          this.closeApp = true;
          setTimeout(() => this.closeApp = false, 3000);
        } else App.exitApp();
      });
    }
  }

  syncData(): void {
    this.notificationService.wait();
    forkJoin([
      this.checkUnconfirmedEBS(),
      this.checkUnreadCounters(),
      this.checkLocalEntries(),
      this.loadCampaignBanner()
    ]).pipe(take(1)).subscribe(res => {
      this.initLoad = true;
      this.notificationService.close();
    });
  }

  private checkUnconfirmedEBS(): Observable<any> {
    return defer(() => from(this.dbService.loadMultipleFromDB('ebs')).pipe(take(1),
      map(ebs => ebs.filter(e => !e.archived_at && !e.confirmed_at )),
      map(ebs => ebs.filter(e => e.assignment_ends_at > new Date() )),
      map(ebs => this.unconfirmedEbs = !!ebs.length)
    ));
  }

  private checkUnreadCounters(): Observable<any> {
    return defer(() => from(this.dbService.loadMultipleFromDB('unreadPn')).pipe(take(1),
      map(pn => pn.map(pn => new PushNotificationUnread(pn))),
      map(pn => pn.filter(e => e.sourceType === 'vacation_request')),
      map(pn => this.unreadVacations = pn.length))
    );
  }

  private checkLocalEntries(): Observable<any[]> {
    return defer(() => forkJoin([
      from(this.dbService.loadMultipleFromDB('photoReports')).pipe(take(1),tap(reports => this.photoReports = !!reports?.length)),
      from(this.dbService.loadMultipleFromDB('localDocuments')).pipe(take(1),tap(documents => this.photoDocuments = !!documents?.length))
    ]));
  }

  private loadCampaignBanner(): Observable<any> {
    this.skipBanner = this.sessionStorageService.skipCampaignBanner;
    if (!this.skipBanner) {
      return defer(() => from(this.dbService.loadOneFromDB('banners', { id: `Campaign_${this.userService.currentUserValue.id}`})).pipe(take(1),
        tap(banner => {
          if (banner) this.campaignBanner = new Banner(banner);
          else this.notificationService.close();
        })
      ));
    } else return of(null);
  }

  handleSalaryReportAuth(): void {
    if (this.userService.salaryTwoFactorTokenValue) this.router.navigate(['/time-tracking/salary-overview']);
    else this.sessionStorageService.pushDynamicComponent({ component: 'Salary2FA' });
  }

  setSkipBanner(): void {
    this.sessionStorageService.skipCampaignBanner = true;
    this.skipBanner = true;
  }

  openDailyReport(): void {
    this.sessionStorageService.pushDynamicComponent({ component: 'DailyReport' });
  }

  // openVacationRequest(): void {
  //   this.sessionStorageService.pushDynamicComponent({ component: 'VacationRequest' });
  // }

  openPhotoReports(): void {
    if (this.photoReports) this.router.navigateByUrl('/time-tracking/preselect-report-pr');
    else this.router.navigateByUrl('/time-tracking/assignment-select-pr');
  }

  openPhotoDocuments(): void {
    if (this.photoDocuments) this.router.navigateByUrl('/time-tracking/document-preselect');
    else this.router.navigateByUrl('/time-tracking/document-select');
  }

  openTutorialList(): void {
    this.sessionStorageService.pushDynamicComponent({ component: 'TutorialsList' });
  }

  private startTutorial(): void {
    if (!this.tutorialStep) this.sessionStorageService.pushOverflowStack('TutorialStep');
    ++this.tutorialStep;
  }

}
