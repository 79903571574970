import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  private readonly REFER_A_FRIEND_API = `${environment.apiUrl}/api/mobile/v3/referral_program_referees`;
  private readonly SUPPORT_API        = `${environment.apiUrl}/api/mobile/v3/support_requests`;
  // private readonly MILEAGE_API        = `${environment.apiUrl}/api/mobile/v3/license_plates`;
  private readonly MILEAGE_API        = `${environment.apiUrl}/time_tracking/api/v2/user_license_plates.json`;

  constructor(
    private http: HttpClient,
  ) { }

  referAFriend(data: any): Observable<void> {
    return this.http.post<any>(this.REFER_A_FRIEND_API, data);
  }

  sendSupport(data: any): Observable<void> {
    return this.http.post<any>(this.SUPPORT_API, data);
  }

  changeLicensePlate(data: any): Observable<void> {
    return this.http.post<any>(this.MILEAGE_API, data);
  }

}
