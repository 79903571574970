<pull-to-refresh *ngIf="initLoad" (callback)="sessionStorageService.triggerSyncData(); syncData()"></pull-to-refresh>

<div class="du-flex-max-content relative b-radius overflow" *ngIf="!skipBanner && campaignBanner && campaignBanner.image">
  <img class="width-full" [src]="campaignBanner.image" (load)="notificationService.close()">
  <span class="du-icon-close absolute top-0 right-0 mt-m mr-m" (click)="setSkipBanner()"></span>
</div>

<div class="du-flex-column gap-m"  [tutorialStep]="tutorialStep" [tutorialNumber]="1" [tutorialText]="'theseAreFunctions' | translate">
  <div class="du-flex-justify du-flex-align">
    <span class="font-l bold" translate="functions"></span>
    <span class="du-icon-question font-icon color-blue p-xs" (click)="openTutorialList()"></span>
  </div>

  <div class="du-flex shadow relative" (click)="openPhotoDocuments()" [class.dot-orange-after-top-left]="photoDocuments">
    <div class="du-flex-1 du-flex-align gap-m pt-m pb-m pl-xl pr-xl">
      <span class="du-icon-upload font-icon"></span>
      <span class="font-s bold" translate="uploadNewDocument"></span>
    </div>
    <span class="du-flex-center pl-l pr-l height-full bg-blue-gradient btr-radius bbr-radius overflow du-icon-plus font-l color-white"></span>
  </div>

  <div class="du-flex shadow overflow" [routerLink]="['/time-tracking/preselect-report-ar']">
    <div class="du-flex-1 du-flex-align gap-m pt-m pb-m pl-xl pr-xl">
      <span class="du-icon-calendar font-icon"></span>
      <span class="font-s bold" translate="activityReport"></span>
    </div>
    <span class="du-flex-center pl-l pr-l height-full bg-blue-gradient btr-radius bbr-radius overflow du-icon-plus font-l color-white"></span>
  </div>

  <div class="du-flex shadow relative" (click)="openPhotoReports()" [class.dot-orange-after-top-left]="photoReports">
    <div class="du-flex-1 du-flex-align gap-m pt-m pb-m pl-xl pr-xl">
      <span class="du-icon-camera font-icon"></span>
      <span class="font-s bold" translate="photoActivityReport"></span>
    </div>
    <span class="du-flex-center pl-l pr-l height-full bg-blue-gradient btr-radius bbr-radius overflow du-icon-plus font-l color-white"></span>
  </div>

  <div class="du-flex shadow overflow" [routerLink]="['/time-tracking/vacation-type-select']">
    <div class="du-flex-1 du-flex-align gap-m pt-m pb-m pl-xl pr-xl">
      <span class="du-icon-holiday font-icon"></span>
      <span class="font-s bold" translate="vacationRequest"></span>
    </div>
    <span class="du-flex-center pl-l pr-l height-full bg-blue-gradient btr-radius bbr-radius overflow du-icon-plus font-l color-white"></span>
  </div>

  <div class="du-flex shadow overflow" (click)="openDailyReport()">
    <div class="du-flex-1 du-flex-align gap-m pt-m pb-m pl-xl pr-xl">
      <span class="du-icon-calendar-day font-icon"></span>
      <span class="font-s bold" translate="dailyReport"></span>
    </div>
    <span class="du-flex-center pl-l pr-l height-full bg-blue-gradient btr-radius bbr-radius overflow du-icon-plus font-l color-white"></span>
  </div>
</div>

<div class="du-flex-column gap-s">
  <div class="du-flex-column gap-m" [tutorialStep]="tutorialStep" [tutorialNumber]="2" [tutorialText]="'useThisFeatureToMergeDailies' | translate">
    <span class="font-l bold" translate="overview"></span>
    <div class="du-flex shadow" [routerLink]="['/time-tracking/dailies-overview']">
      <div class="du-flex-1 du-flex-align gap-m pt-m pb-m pl-xl pr-xl">
        <span class="du-icon-combine font-icon"></span>
        <span class="font-s bold" translate="combineDailyReports"></span>
      </div>
      <span class="du-flex-center pl-l pr-l height-full bg-blue-gradient btr-radius bbr-radius overflow du-icon-plus font-l color-white"></span>
    </div>
  </div>

  <div class="du-flex-column gap-s" [tutorialStep]="tutorialStep" [tutorialNumber]="3" [tutorialText]="'thisIsOverviewSection' | translate">
    <div class="du-flex gap-s">
      <span class="du-flex-center du-flex-1 shadow pt-m pb-m bold font-s lh-l"          [routerLink]="['/time-tracking/reports-overview']">Tätigkeitsnachweise</span>
      <span class="du-flex-center du-flex-1 shadow pt-m pb-m bold font-s lh-l relative" [routerLink]="['/time-tracking/ebs-overview']"
            [class.dot-orange-after-top-right]="unconfirmedEbs">
        Einsatzbegleitscheine
      </span>
    </div>
    <div class="du-flex gap-s">
      <span class="du-flex-center du-flex-1 shadow pt-m pb-m bold font-s lh-l" (click)="handleSalaryReportAuth()">Lohnabrechnungen</span>
      <span class="du-flex-center du-flex-1 shadow pt-m pb-m bold font-s lh-l" [routerLink]="['/time-tracking/documents-overview']">Dokumente</span>
    </div>
    <div class="du-flex gap-s">
      <span class="du-flex-1 gap-xl shadow pt-m pb-m relative font-s" [routerLink]="['/time-tracking/vacations-overview']">
        <span class="du-flex-center du-flex-1 lh-l bold">Übersicht Urlaub</span>
        <span class="du-flex-center bg-orange circle-xxl color-white absolute-right-center mr-m" *ngIf="unreadVacations">{{ unreadVacations }}</span>
      </span>
    </div>
  </div>

</div>
