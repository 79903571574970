import { NgModule             } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { BlankComponent } from './layout/blank/blank.component';
import { FullComponent } from './layout/full/full.component';

import { AuthGuard } from './guards/auth.guard';

export const routes: Routes = [
  { path: '', component: BlankComponent,
    children: [{ path: 'auth', loadChildren: () => import('./components/auth/auth.module').then(m => m.AuthenticationModule) }]
  },
  { path: '', component: FullComponent,
    children: [{ path: 'time-tracking', loadChildren: () => import('./components/time-tracking/time-tracking.module').then(m => m.TimeTrackingModule), canActivate: [AuthGuard] }] 
  },
  { path: '**',
    redirectTo: '404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
