import { Component, OnInit        } from '@angular/core';
import { ActivatedRoute, Router   } from '@angular/router';
import { Location                 } from '@angular/common';
import { Capacitor                } from '@capacitor/core';
import { Observable, forkJoin, from, map, of, switchMap } from 'rxjs';

import { AssignmentsService, DBService, EbsService, NotificationService, SessionStorageService } from '@shared/services';
import { collapse } from '@shared/animations';
import { EBS } from '@shared/factories';

@Component({
  selector:      'ebs-details',
  templateUrl: './ebs-details.component.html',
  host: { class: 'height-full du-flex-column du-flex-justify' },
  animations: [ collapse ]
})
export class EbsDetailsComponent implements OnInit {
  ebs:     EBS;
  version: any;

  collapseBlockOne:   boolean;
  collapseBlockTwo:   boolean;
  collapseBlockThree: boolean;
  collapseBlockFour:  boolean;
  constructor(
    private route:                 ActivatedRoute,
    private router:                Router,
    private location:              Location,
    private dbService:             DBService,
    private ebsService:            EbsService,
    private assignmentsService:    AssignmentsService,
    private notificationService:   NotificationService,
    private sessionStorageService: SessionStorageService,
  ) { }

  ngOnInit(): void {
    this.sessionStorageService.setHeaderTitle('ebs');
    this.sessionStorageService.setHeaderControls({ left: [{ icon: 'arrow-big-left color-blue font-icon', callback: () => this.location.back() }] });
    this.sessionStorageService.setProgressBar(null);
    this.notificationService.wait();
    this.route.params.pipe(
      switchMap(params => {
        if (params['assignmentId']) {
          this.version = params['version'];
          if (!isNaN(this.version)) return this.loadEbsBeVer(+params['assignmentId'], +this.version);
          else return this.loadLatestEBS(+params['assignmentId']);
        } else return of();
      })
    ).subscribe(
      ebs => {
        if (ebs) {
          this.ebs = ebs;
          this.notificationService.close();
        } else this.notificationService.alert(
          'There is no ebs assigned to this Assignment. Please contact your branch support.',
          () => this.location.back()
        );
      },
      err => this.notificationService.alert(err)
    );
  }

  private loadLatestEBS(assignmentId: number): Observable<EBS> {
    return from(this.dbService.loadMultipleFromDB('ebs', { assignment_id: assignmentId })).pipe(
      map(list => list.find(el => +el.version === +Math.max(...list.map(e => e.version)))),
      map(ebs => ebs ? new EBS(ebs) : null)
    );
  }

  private loadEbsBeVer(assignmentId: number, version: number): Observable<EBS> {
    return from(this.dbService.loadOneFromDB('ebs', { assignment_id: assignmentId, version })).pipe(
      map(ebs => new EBS(ebs))
    );
  }

  openMap(value: string): void {
    if (Capacitor.isNativePlatform()) {
      if (Capacitor.getPlatform() === 'ios')     window.open(`maps://?q=${value}`, '_system');
      if (Capacitor.getPlatform() === 'android') window.open(`geo:0,0?q=${value}`, '_system');
    } else window.open(`https://www.google.com/maps?q=${value}` , '_system');
  }

  call(phone: string): void {
    let tel;
    let num = phone.replace(/ /g, '');
    if (num) {
      if (num.charAt(0) === '0') tel = `+49${num.substring(1)}`;
      else if (num.substring(0,2) === '49' && num.length === 12) tel = `+${num}`;
      else if (num.substring(0,3) === '+49') tel = `${num}`;
      else tel = `+49${num}`;
    } else tel = '+49 699 0501 4320'; // Tempton Digital (Support)

    let link = `tel:${tel}`;
    window.open(link, '_system');
  }

  confirm(): void {
    this.notificationService.wait();
    this.ebsService.confirmEBS(this.ebs.id).pipe(
      switchMap(res => forkJoin([
        this.assignmentsService.syncAssignments(),
        this.ebsService.syncEBS()
      ]))
    ).subscribe(
      res => {
        if (!this.version) this.router.navigateByUrl('time-tracking/home');
        else this.location.back();
      },
      err => this.notificationService.alert(err)
    );
  }

}
