import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ActivityReportLocal, Template      } from '@shared/factories';
import { TemplateModel } from '@shared/models';
import { DBService, SessionStorageService     } from '@shared/services';

@Component({
  selector:      'templates-box',
  templateUrl: './templates-box.component.html',
  host: { class: 'du-flex-column gap-xl pt-xl pb-xl bb-grey' }
})
export class TemplatesBoxComponent implements OnInit {
  templates:   Template[];
  optionsOpen: number = null;
  @HostListener('document:click', ['$event']) close(event: MouseEvent) {
    if (this.optionsOpen !== null) this.optionsOpen = null;
  }
  constructor(
    private router:                Router,
    private dbService:             DBService,
    private sessionStorageService: SessionStorageService,
  ) {}

  ngOnInit(): void {
    this.dbService.loadMultipleFromDB('templates')
    .then((templates: TemplateModel[]) => this.templates = templates.map(r => new Template(r)));
  }

  useTemplate(index: number): void {
    this.sessionStorageService.pushDynamicComponent({
      component: 'TemplateDetails',
      props: {
        template: this.templates[index]
      }
    });
  }

  toggleOptions(index: number, e: any): void {
    e.stopPropagation();
    setTimeout(() => { 
      if (this.optionsOpen !== index) this.optionsOpen = index;
      else this.optionsOpen = null;
    });
  }

  editTemplate(index: number, e: any): void {
    e.stopPropagation();
    this.optionsOpen = null;
    this.router.navigate(['/time-tracking/report-constructor-t', this.templates[index].id]);
  }

  deleteTemplatePrompt(index: number, e: any): void {
    e.stopPropagation();
    this.dbService.deleteOneFromDB('templates', this.templates[index].id)
    .then(() => {
      this.templates.splice(index, 1);
      this.optionsOpen = null;
    });
  }

}
