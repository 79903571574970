<span class="tooltip-hover" *ngIf="holidays && holidays.length" >
  <div class="circle-xl p-xxs b-radius bg-light-orange">
    <img class="width-14" src="assets/svg/holiday.svg">
  </div>
  <span class="du-tooltip small mt-xxs bg-light-orange word-no-wrap absolute-full-top-center">
    <span *ngFor="let holiday of holidays">{{ holiday.name }}</span>
  </span>
</span>
<span class="tooltip-hover" *ngIf="mileage">
  <div class="circle-xl p-xxs b-radius bg-light-blue">
    <img class="width-14" src="assets/svg/mileage.svg">
  </div>
  <span class="du-tooltip small mt-xxs bg-light-blue word-no-wrap absolute-full-top-center" translate="millageAllowanceApplied"></span>
</span>
<span class="tooltip-hover" *ngIf="errors">
  <div class="circle-xl p-xxs b-radius bg-light-orange">
    <img class="width-14" src="assets/svg/warning.svg">
  </div>
  <span class="du-tooltip small mt-xxs bg-light-orange word-no-wrap absolute-full-top-center" translate="legalErrors"></span>
</span>
