import { Component, OnInit      } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location               } from '@angular/common'

import { ActivityReportLocal      } from '@shared/factories';
import { ActivityReportLocalModel } from '@shared/models';
import { ActivityReportsService, DBService, NotificationService, SessionStorageService, UserService } from '@shared/services';

@Component({
  templateUrl: './preselect-report.component.html',
  host: { class: 'height-full du-flex-column du-flex-justify' }
})
export class PreselectReportComponent implements OnInit {
  useCase: string;
  reports: ActivityReportLocal[] = [];
  constructor(
    private route:                  ActivatedRoute,
    private router:                 Router,
    private location:               Location,
    private dbService:              DBService,
    private activityReportsService: ActivityReportsService,
    private userService:            UserService,
    private sessionStorageService:  SessionStorageService,
    private notificationService:    NotificationService
  ) {}

  ngOnInit(): void {
    this.useCase = this.route.snapshot.data['useCase'];
    this.notificationService.wait();
    this.sessionStorageService.setHeaderControls({ left: [{ icon: 'arrow-big-left color-blue font-icon', callback: () => this.router.navigateByUrl('/time-tracking/home')}] });
    this.sessionStorageService.setProgressBar(null);
    this.sessionStorageService.setTemporaryReport(null);

    if (!this.userService.checkTutorial(this.useCase)) this.sessionStorageService.pushDynamicComponent({
      component: 'TutorialScreen',
      props: { useCase: this.useCase }
    });

    if (this.useCase === 'ar') this.prepareActivityReport('activityReports');
    if (this.useCase === 'pr') this.prepareActivityReport('photoReports'); 
  }

  private prepareActivityReport(useCase: string): void {
    this.sessionStorageService.setHeaderTitle(useCase);

    this.dbService.loadMultipleFromDB(this.dbTable)
    .then((reports: ActivityReportLocalModel[]) => this.reports = reports.map(r => new ActivityReportLocal(r)).sort((a, b) => a.startDate.getTime() - b.startDate.getTime()))
    .then(() => this.notificationService.close());
  }

  submitReport(report: ActivityReportLocal): void {
    this.notificationService.wait();
    this.activityReportsService.submitActivityReport(report).subscribe(
      res => {
        this.deleteReport(report.id);
        this.router.navigate(['time-tracking/info'], { queryParams: { type: 'submit', useCase: this.useCase }});
      },
      err => this.notificationService.alert(err)
    );
  }

  deleteReport(reportId: number): Promise<ActivityReportLocal[] | void> {
    this.notificationService.wait();
    return this.dbService.deleteOneFromDB(this.dbTable, reportId)
    .then(() => this.reports = this.reports.filter(r => r.id !== reportId))
    .then(() => this.notificationService.close())
    .catch(err => this.notificationService.alert(err));
  }

  private get dbTable(): string {
    if (this.useCase === 'ar') return 'localReports';
    if (this.useCase === 'pr') return 'photoReports';
    return '';
  }

  callbackHandler(): void {
    this.router.navigateByUrl(`time-tracking/assignment-select-${this.useCase}`);
  }

}
