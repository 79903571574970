<div class="du-flex-justify du-flex-align bb-grey">
  <span class="absolute left-70 right-70 du-flex-center color-grey bold" translate="sendCopyByEmail"></span>
  <img  class="pl-xl pr-xl pt-m pb-m mla" src="assets/svg/close.svg" (click)="close()">
</div>

<div class="du-flex-column gap-m pt-s pb-m pl-xl pr-xl bb-grey">
  <span translate="New Email"></span>
  <div class="du-flex-align gap-s">
    <input type="text" class="width-full" [(ngModel)]="customEmail">
    <button class="btn-secondary width-50" (click)="addEmail()">
      <img src="assets/svg/plus.svg">
    </button>
  </div>
</div>

<div class="du-flex-column gap-m pb-m pl-xl pr-xl bb-grey">
  <div class="du-flex-column">
    <div class="du-flex-justify pt-s pb-s gap-m" *ngFor="let email of customerEmails; let i = index" [class.bb-grey]="i+1 !== customerEmails.length" (click)="email.active = !email.active">
      <div class="du-flex-column gap-s">
        <span>{{ email.email }}</span>
        <span>{{ email.name }}</span>
      </div>
      <img class="mla" src="assets/svg/square.svg"           *ngIf="!email.active">
      <img class="mla" src="assets/svg/square-tick-blue.svg" *ngIf="email.active">
    </div>
  </div>

  <button class="btn-primary btn-round mt-s" translate="next" (click)="submit()"></button>
</div>
