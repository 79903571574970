<div class="du-flex-column gap-xxxl color-white">
  <language-toggler color="'white'"></language-toggler>
  <div class="du-flex-column gap-xs">
    <h1 class="lh-l width-perc-70 font-xl bold" translate="welcome"></h1>  
    <h2 class="lh-l width-perc-70 font-l" translate="activateBeforeLogin"></h2>
  </div>
</div>

<div class="du-flex-column gap-l">
  <button class="btn-secondary btn-round" [routerLink]="['/auth/login']"    translate="login"></button>
  <button class="btn-primary   btn-round" [routerLink]="['/auth/activate']" translate="activateAccount"></button>
</div>
