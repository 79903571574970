import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';

import { FilterFlowModel       } from '@shared/models';
import { SessionStorageService } from '@shared/services';

@Component({
  selector:      'tabs-box',
  templateUrl: './tabs-box.component.html',
  host: { class: 'du-flex height-45 b-radius bg-card-grey overflow' }
})
export class TabsBoxComponent implements OnDestroy {
  @Input() tabs:  any[];
  @Input() field: string;
  @Output() updateData = new EventEmitter<FilterFlowModel>;

  tab: string;
  constructor( private sessionStorageService: SessionStorageService ) {}

  ngOnDestroy(): void {
    this.sessionStorageService.setTabFlow(null);
  }

  changeTab(tab: string): void {
    if (this.tab === tab) this.tab = null;
    else this.tab = tab;

    this.sessionStorageService.setTabFlow({
      field: this.field,
      value: this.tab
    });
    this.updateData.emit();
  }

}
