<div class="du-flex-justify du-flex-align bb-grey">
  <img  class="pl-xl pr-xl pt-m pb-m mra" src="assets/svg/arrow-left.svg" (click)="close()">
  <span class="absolute left-70 right-70 du-flex-center color-grey bold" translate="mileage"></span>
</div>
<div class="du-flex-column gap-xl pt-m pl-xl pr-xl pb-xl">
  <span translate="fillInformationMillageMoney"></span>

  <div class="du-flex-column gap-m">
    <span class="bold" translate="country"></span>
    <select [(ngModel)]="licensePlateCountry">
      <option value="" disabled selected>Select Country</option>
      <option *ngFor="let country of countries" [value]="country.code">{{country.name}}</option>
    </select>
  </div>

  <div class="du-flex-column gap-m">
    <span class="bold" translate="licensePlate"></span>
    <input type="text" [(ngModel)]="licensePlate">
  </div>



  <div class="du-flex-column gap-m">
    <span class="bold" translate="signHere"></span>
    <div class="du-flex-align b-grey gap-xs p-m shadow-header" (click)="openSignaturePad()">
      <img src="assets/svg/signature.svg">
      <span translate="signature"></span>
      <div class="du-flex-align gap-s mla">
        <span class="color-blue  bold" translate="sign"   *ngIf="!signature"></span>
        <span class="color-green bold" translate="signed" *ngIf="signature"></span>
      </div>
    </div>
  </div>

  <button class="btn-primary btn-round" translate="done" (click)="saveMileage()" [disabled]="!licensePlateCountry || !licensePlate || !signature"></button>
</div>
