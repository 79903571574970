<div class="du-flex gap-m">
  <span class="width-80  bold" translate="day"></span>
  <span class="width-80  bold" translate="month"></span>
  <span class="width-110 bold" translate="year"></span>
</div> 
<div class="du-flex gap-m">
  <input id="day"   [ngModel]="day"   class="width-80  active-ready" type="number" (keydown)="keyDown($event)" (keyup)="nextInput($event)" inputmode="numeric" step="1" pattern="[0-9]*" min="1"    max="31"               placeholder="{{ 'dayAbbr'   | translate }}">
  <input id="month" [ngModel]="month" class="width-80  active-ready" type="number" (keydown)="keyDown($event)" (keyup)="nextInput($event)" inputmode="numeric" step="1" pattern="[0-9]*" min="1"    max="12"               placeholder="{{ 'monthAbbr' | translate }}">
  <input id="year"  [ngModel]="year"  class="width-110 active-ready" type="number" (keydown)="keyDown($event)" (keyup)="nextInput($event)" inputmode="numeric" step="1" pattern="[0-9]*" min="1940" max="{{getMaxYear()}}" placeholder="{{ 'yearAbbr'  | translate }}">
</div>
