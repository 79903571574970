import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable() export class NotificationService {
  private subject = new Subject<any>();
  constructor( ) { }

  alert(message: string, backFn:()=>void = ()=>{}): void {
    this.subject.next({
      title: 'warning',
      desc: message,
      buttons: { back: 'back' },
      backFn:() => {
        this.subject.next(null);
        backFn();
      }
    });
  }

  confirm({ title, desc, descHTML, buttons, leftFn = ()=>{}, rightFn = ()=>{} }: { title: string, desc?: string, descHTML?: string, buttons?: { left: string, right: string }, leftFn?:()=>void , rightFn?:()=>void }): void {
    this.subject.next({
      title, desc, descHTML,
      buttons: {
        left: buttons && buttons.left   || 'no',
        right: buttons && buttons.right || 'yes'
      },
      leftFn:() => {
        this.subject.next(null);
        leftFn();
      },
      rightFn:() => {
        this.subject.next(null);
        rightFn();
      }
    });
  }

  wait(): void {
    this.subject.next({ wait: true });
  }

  close(): void {
    // this.sessionStorageService.clearOverflowStack();
    this.subject.next(null);
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
}