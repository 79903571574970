import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, map, of, take, tap } from 'rxjs';

import { UserModel } from '@shared/models';
import { User      } from '@shared/factories';

import { environment } from 'environments/environment'
import { db } from '@database';

@Injectable({
  providedIn: 'root'
})

export class UserService {
  private readonly GET_ME_API    = `${environment.apiUrl}/api/mobile/v3/users/me`;
  private readonly TUTORIALS_KEY = 'passedTutorials';

  private accessTokenSubject = new BehaviorSubject<string>(null);
  accessToken = this.accessTokenSubject.asObservable();

  private salaryTwoFactorTokenSubject = new BehaviorSubject<string>(null);
  salaryTwoFactorToken = this.salaryTwoFactorTokenSubject.asObservable();

  private currentUserSubject = new BehaviorSubject<User>(null);
  currentUser = this.currentUserSubject.asObservable();

  constructor (
    private http: HttpClient
  ) { }

  get accessTokenValue(): string {
    return this.accessTokenSubject.value;
  }

  setAccessToken(token: string): void {
    return this.accessTokenSubject.next(token);
  }

  get salaryTwoFactorTokenValue(): string {
    return this.salaryTwoFactorTokenSubject.value;
  }

  setSalaryTwoFactorToken(token: string): void {
    return this.salaryTwoFactorTokenSubject.next(token);
  }

  get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  setUser(user: User): Promise<void> {
    this.currentUserSubject.next(user);
    return Promise.resolve(db.users.put(user.toJSON()));
  }

  loadMe(): Observable<UserModel> {
    return this.http.get<UserModel>(this.GET_ME_API).pipe(take(1));
  }

  getMe(): Observable<User> {
    return this.loadMe().pipe(
      map(res => new User(res)),
      tap(user => this.setUser(user)),
      catchError(err => of(null))
    );
  }

  checkTutorial(useCase: string): boolean {
    let temp: string[] = JSON.parse(localStorage.getItem(this.TUTORIALS_KEY)) || [];
    return !!temp?.find(t => t === `${useCase}_${this.currentUserValue.id}`);
  }

  passTutorial(useCase: string): void {
    let temp = JSON.parse(localStorage.getItem(this.TUTORIALS_KEY)) || [];
    temp.push(`${useCase}_${this.currentUserValue.id}`);
    localStorage.setItem(this.TUTORIALS_KEY, JSON.stringify(temp));
  }

}
