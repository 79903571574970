import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { collapse } from '@shared/animations';
import { ActivityReportOverview } from '@shared/factories';
import { NotificationService, SessionStorageService } from '@shared/services';
import { switchMap } from 'rxjs';

@Component({
  templateUrl: './report-details.component.html',
  host: { class: 'height-full du-flex-column overflow-auto' },
  animations: [ collapse ]
})
export class ReportDetailsComponent implements OnInit {
  cachedReports: ActivityReportOverview[] = [];
  report:        ActivityReportOverview;

  reportIndex:       number = 0;
  collapseSignature: boolean;
  collapseNote:      boolean;
  constructor(
    private route:                 ActivatedRoute,
    private location:              Location,
    private notificationService:   NotificationService,
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.notificationService.wait();
    this.sessionStorageService.setHeaderTitle('activityReport');
    this.sessionStorageService.setProgressBar(null);
    this.sessionStorageService.cachedEntries.pipe(
      switchMap(reports => {
        this.cachedReports = reports || [];
        return this.route.params;
      })
    ).subscribe(
      params => {
        this.renderReport(this.cachedReports.findIndex(r => r.id === +params['id']));
        this.notificationService.close();
      },
      err => this.notificationService.alert(err)
    );
  }

  renderReport(reportIndex: number): void {
    if (reportIndex !== -1) {
      this.reportIndex = reportIndex;
      this.report = this.cachedReports[reportIndex];
      this.sessionStorageService.setHeaderControls({
        left:  [{ icon: 'arrow-big-left color-blue font-icon',  callback: () => this.location.back() }],
        right: this.report && this.report.customerSignature ? [{ icon: 'check color-green font-icon', label: 'signed', class: 'du-flex-align gap-xs p-s font-s bg-green-light b-radius' }] : []
      });
    } else this.location.back();
  }

  showEmailDetails(): void {
    this.sessionStorageService.setChip(this.report.confirmationEmails.join('\n'))
  }

  goPrevReport(): void {
    this.renderReport(this.reportIndex-1);
  }

  goNextReport(): void {
    this.renderReport(this.reportIndex+1);
  }

}
