<div class="du-flex-1 du-flex-column gap-xl overflow-auto">
  <span class="du-flex-1 du-flex-column gap-s" *ngIf="!dailyGroups || !dailyGroups.length">
    <span translate="noLocalReports"></span>
    <span class="font-s lh-l" translate="createReportBy"></span>
  </span>

  <div class="du-flex-column gap-m" *ngFor="let assignmentGroup of dailyGroups; trackBy: trackByAssignmentId">
    <div class="du-flex-justify du-flex-align-start">
      <span class="font-l bold lh-l">{{ assignmentGroup.assignment.title }}</span>
      <div class="b-blue width-45 height-45 du-flex-center p-m" (click)="openDailyConstructor(assignmentGroup)">
        <img src="assets/svg/plus.svg">
      </div>
    </div>
    <div class="du-flex-column shadow" *ngFor="let weekGroup of assignmentGroup.dailiesByWeek; trackBy: trackByWeekStart">
      <div class="du-flex-justify du-flex-align p-s bb-grey">
        <span class="du-flex-column gap-xxs">
          <span class="bold">
            <span translate="cw"></span>
            <span class="roboto">{{ weekGroup.weekStart | date: 'ww' }}</span>
          </span>
          <span class="roboto">({{ weekGroup.weekStart | date: 'dd' }}.{{ weekGroup.weekStart | date: 'MM' }} - {{ weekGroup.weekEnd | date: 'dd' }}.{{ weekGroup.weekEnd | date: 'MM' }})</span>
        </span>
        <span class="du-flex-align gap-s color-blue"  translate="createReport" (click)="createReport(assignmentGroup, weekGroup)"></span>
      </div>
      <div class="du-flex-column gap-s p-s">
        <div class="du-flex-justify color-blue font-s" (click)="weekGroup.collapsed = !weekGroup.collapsed">
          <span *ngIf="!weekGroup.collapsed" translate="showMore"></span>
          <span *ngIf="weekGroup.collapsed"  translate="showLess"></span>
          <img  src="assets/svg/arrow-down-blue.svg" [class.rotate]="weekGroup.collapsed">
        </div>
        <div class="du-flex-column" @collapse *ngIf="weekGroup.collapsed">
          <div class="du-flex-column du-flex-justify-end gap-m overflow">
            <div class="du-flex-column" @collapse *ngFor="let daily of weekGroup.dailies; trackBy: trackById">
              <daily-overview-item class="overflow" [daily]="daily" [allowEdit]="true" (delete)="deleteDaily($event)" ></daily-overview-item>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<button class="btn-secondary btn-round" translate="createDailyReport" (click)="openDailyConstructor()"></button>
