import { Component, Input } from '@angular/core';
import { ActivityReportLocal, ActivityReportOverview } from '@shared/factories';

@Component({
  selector:      'reports-overview-item',
  templateUrl: './reports-overview-item.component.html',
  host: { class: 'shadow' }
})
export class ReportsOverviewItemComponent {
  @Input() report: ActivityReportOverview | ActivityReportLocal;

  getLocalReport(): ActivityReportLocal {
    return this.report as ActivityReportLocal;
  }

  getOverviewReport(): ActivityReportOverview {
    return this.report as ActivityReportOverview;
  }

}
