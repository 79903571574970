import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { FilterFlowModel, SortFlowModel } from '@shared/models';
import { SessionStorageService } from '@shared/services';

@Component({
  selector:      'filter-sort',
  templateUrl: './filter-sort.component.html',
})
export class FilterSortComponent implements OnInit, OnDestroy {
  @Input() pills:      any[];
  @Input() assignment: boolean;
  @Input() from:       string;
  @Input() to:         string;
  @Input() sort:       SortFlowModel[];

  @Output() updateData = new EventEmitter<FilterFlowModel>;

  filterActive: number;
  sortActive:   string;
  constructor( private sessionStorageService: SessionStorageService ) {}

  ngOnInit(): void {
    this.sessionStorageService.filterFlow.subscribe(flow => this.filterActive = flow && +(+!!flow.assignment + +!!flow.from?.date + +!!flow.to?.date + +flow.pills?.length));
    this.sessionStorageService.sortFlow.subscribe(sort => this.sortActive = sort?.label);
  }

  ngOnDestroy(): void {
    this.sessionStorageService.setFilterFlow(null);
    this.sessionStorageService.setSortFlow(null);
  }

  openFilter(): void {
    this.sessionStorageService.pushDynamicComponent({
      component:'Filter',
      props: {
        pills:      this.pills,
        assignment: this.assignment,
        from:       this.from,
        to:         this.to,
        save: () => this.updateData.emit()
      }
    });
  }

  openSort(): void {
    this.sessionStorageService.pushDynamicComponent({
      component:'Sort',
      props: {
        entries: this.sort,
        save: () => this.updateData.emit()
      }
    });
  }

}
