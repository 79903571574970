import { Injectable } from '@angular/core';
import { Router     } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { map, take, tap } from 'rxjs/operators';

import { Capacitor } from '@capacitor/core';

import { Release                 } from '@shared/factories';
import { MetaModel, ReleaseModel } from '@shared/models';

import { DBService             } from './db.service'
import { SessionStorageService } from './session-storage.service';

import { environment } from 'environments/environment';
import { appVersion } from 'app/appVer';

interface ReleasesResponce {
  releases: ReleaseModel[];
  meta:     MetaModel;
}

interface StoreReleaseResponseModel {
  app_release: StoreReleaseModel;
}

interface StoreReleaseModel {
  version:     string;
  track_id:    string;
  released_at: string;
  platform:    string;
  store_url:   string;
}

@Injectable({
  providedIn: 'root'
})
export class ReleasesService {
  private RELEASES_API: string      = `${environment.apiUrl}/time_tracking/api/v3/releases`;
  private STORE_RELEASE_API: string = `${environment.apiUrl}/api/mobile/v3/app_releases/version`;

  constructor(
    private http:                  HttpClient,
    private router:                Router,
    private DBService:             DBService,
    private sessionStorageService: SessionStorageService
  ) { }

  private loadReleases(page: number = 1): Observable<Release[]> {
    let sort = `&q[sort][0][pos]=0&q[sort][0][name]=released_at&q[sort][0][dir]=desc&q[sort][1][pos]=1&q[sort][1][name]=version&q[sort][1][dir]=desc`;
    return this.http.get<ReleasesResponce>(`${this.RELEASES_API}?per_page=10&page=${page}${sort}`).pipe(
      take(1),
      map(res => res.releases),
      map(release => release.map(r => new Release(r)))
    );
  }

  syncReleases(page: number = 1): Observable<Release[]> {
    return this.loadReleases(page).pipe(tap(res => this.DBService.saveMultipleToDB('releases', res)));
  }

  checkCurrentStoreVersion(online: boolean): void {
    let platform = Capacitor.getPlatform();
    let appName = platform === 'ios' ? 'de.tempton.business' : 'de.tempton.app';
    if (platform !== 'web' && online) this.requestCurrentStoreVesrion(platform, appName).pipe(take(1)).subscribe(data => this.appNeedUpdate(data));
  }

  private requestCurrentStoreVesrion(platform: string, appName: string): Observable<StoreReleaseModel> {
    return this.http.get<StoreReleaseResponseModel>(`${this.STORE_RELEASE_API}?mobile_app_release[platform]=${platform}&mobile_app_release[app_name]=${appName}&mobile_app_release[country_code]=de`).pipe(
      map(res => res.app_release)
    );
  }

  private appNeedUpdate(data: StoreReleaseModel): void {
    let currentVersion = appVersion.split('.');
    let version = data.version.split('.');
    let update = +version[0]  >  +currentVersion[0] ||
                (+version[0] === +currentVersion[0] && +version[1]  >  +currentVersion[1]) ||
                (+version[0] === +currentVersion[0] && +version[1] === +currentVersion[1] && +version[2] > +currentVersion[2]);
    if (update) {
      this.sessionStorageService.setStoreUrl(data.store_url);
      this.router.navigateByUrl('time-tracking/blocker');
    }
  }

}
