<div class="du-flex-1 du-flex-column gap-xxl du-flex-center bg-blue-gradient">
  <div class="du-flex-center p-l bg-card-grey-light b-circle">
    <div class="du-flex-center p-l bg-card-grey-light b-circle">
      <span class="du-flex-center font-40 color-blue" [ngClass]="'du-icon-'+tutorial.icon"></span>
    </div>
  </div>

  <span class="du-flex-column gap-m color-white text-center" [ngClass]="useCase !== 'dr' ? 'ml-xxxl mr-xxxl pl-xxxl pr-xxxl' : '' ">
    <span class="font-xl bold">{{ tutorial.title    | translate }}</span>
    <span class="font-l lh-l">{{  tutorial.subtitle | translate }}</span>
  </span>
</div>

<div class="du-flex-column mt-15 btl-radius btr-radius bg-white">
  <span class="pl-xl pr-xl pt-m pb-m bb-grey bold" translate="instructions"></span>
  <div class="du-flex-column du-flex-justify gap-xxl p-xl">
    <div class="du-flex-column gap-m">
      <div class="du-flex-align gap-s" *ngFor="let step of tutorial.steps">
        <span class="du-flex-center font-icon color-blue" [ngClass]="'du-icon-'+step.icon"></span>
        <span class="font-s bold">{{ step.label | translate }}</span>
      </div>
    </div>
    <button class="width-full btn-primary btn-round" (click)="close()">{{ fullscreen ? 'close' : 'start' | translate }}</button>
  </div>
</div>
