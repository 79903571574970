<div class="du-flex-column gap-s du-flex-1">
  <span class="bold font-l lh-l" *ngIf="document && document.docType && document.docType.name">
    {{ document.docType.name }}
  </span>
  <div class="du-flex-1 b-radius bg-card-grey relative overflow" (click)="!attachment && takePicture()">
    <img class="width-full object-contain"            [src]="attachment">
    <img class="absolute-center width-150 opacity-25" *ngIf="!attachment" src="assets/svg/camera-default.svg">
  </div>
</div>

<div class="du-flex-column gap-l">
  <div class="du-flex-column gap-m">
    <span class="font-s" translate="noteToManagerOpt"></span>
    <textarea cols="30" rows="3" class="p-xs b-grey" placeholder="{{ 'messageToOffice' | translate }}" [(ngModel)]="note"></textarea>
  </div>
  <button class="du-flex-align gap-xs width-full btn-primary btn-round" (click)="takePicture()" *ngIf="!attachment">
    <img class="width-20" src="assets/svg/camera.svg">
    <span translate="takePicture"></span>
  </button>
  <button class="du-flex-align gap-xs width-full btn-secondary btn-round" (click)="takePicture()" *ngIf="attachment">
    <img class="width-20" src="assets/svg/camera-blue.svg">
    <span translate="retakePicture"></span>
  </button>
  <button class="width-full btn-secondary btn-round" translate="save"   (click)="save()"        *ngIf="attachment"></button>
  <button class="width-full btn-primary   btn-round" translate="submit" (click)="submit()" [disabled]="!attachment"></button>
</div>
