<div class="du-flex-column gap-s bg-white z-1">
  <div class="relative width-full">
    <input type="text" class="placeholder-primary-grey pl45-i" [(ngModel)]="search" placeholder="Search">
    <img src="assets/svg/search.svg" class="absolute-top-center ml-m">
  </div>
  <filter-sort [pills]="pills" [assignment]="true" [from]="'date_from'" [to]="'date_to'" [sort]="sort" (updateData)="loadReports(true)"></filter-sort>
</div>

<ul class="du-flex-1 height-full du-flex-column gap-m overflow-auto"
    infiniteScroll
    [infiniteScrollDistance]="1"
    [infiniteScrollThrottle]="150"
    [scrollWindow]="false"
    (scrolled)="loadReports()">
  <pull-to-refresh (callback)="loadReports(true)"></pull-to-refresh>
  <reports-overview-item *ngFor="let report of reports" [report]="report" [routerLink]="['/time-tracking/report-details', report.id]"> </reports-overview-item>
  <span *ngIf="paging && page === paging.total_pages" class="du-flex-center p-m color-disabled">No more entries to load (Total entries: {{ reports.length }})</span>
</ul>