import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { map, take, tap } from 'rxjs/operators';

import { PushNotificationOverview, PushNotificationUnread } from '@shared/factories';
import { MetaModel, PushNotificationOverviewModel, PushNotificationUnreadModel } from '@shared/models';
import { DBService } from './db.service'

import { environment } from 'environments/environment';

interface NotificationsResponce {
  push_notifications: PushNotificationOverviewModel[];
  meta:               MetaModel;
}

interface NotificationsUnreadResponce {
  push_notifications: PushNotificationUnreadModel[];
  meta:               MetaModel;
}

@Injectable({
  providedIn: 'root'
})
export class NotificationsOverviewService {
  // private pnOverview_API: string = `${environment.apiUrl}/api/mobile/v3/employee_notifications/dashboard`;
  private PN_OVERVIEW_API: string = `${environment.apiUrl}/time_tracking/api/v3/employee_notifications/dashboard`;
  private UNREAD_PN_API:   string = `${environment.apiUrl}/api/mobile/v3/push_notifications`;

  constructor(
    private http:      HttpClient,
    private DBService: DBService,
  ) { }

  private loadPnOverview(page: number = 1): Observable<PushNotificationOverview[]> {
    let sort = `&q[sort][0][pos]=0&q[sort][0][name]=created_at&q[sort][0][dir]=desc`;
    return this.http.get<NotificationsResponce>(`${this.PN_OVERVIEW_API}?per_page=10&page=${page}${sort}`).pipe(
      take(1),
      map(res => res.push_notifications),
      map(pn => pn.map(r => new PushNotificationOverview(r)))
    );
  }

  syncNotifications(page: number = 1): Observable<PushNotificationOverview[]> {
    return this.loadPnOverview(page).pipe(tap(res => this.DBService.saveMultipleToDB('pnOverview', res)));
  }

  private loadUnreadPnOverview(page: number = 1): Observable<PushNotificationUnread[]> {
    let query = `&q[recipients][status]=sent&q[triggers][trigger_types][]=vacation_request_approved&q[triggers][trigger_types][]=vacation_request_rejected`;
    return this.http.get<NotificationsUnreadResponce>(`${this.UNREAD_PN_API}?per_page=50&page=${page}${query}`).pipe(
      take(1),
      map(res => res.push_notifications),
      map(pn => pn.map(r => new PushNotificationUnread(r)))
    );
  }

  syncUnreadNotifications(page: number = 1): Observable<PushNotificationUnread[]> {
    return this.loadUnreadPnOverview(page).pipe(tap(res => this.DBService.saveMultipleToDB('unreadPn', res)));
  }

}
