<div class="du-flex-column gap-m" [formGroup]="userForm">
  <div class="du-flex-column gap-m">
    <label for="password" class="font-500" translate="setPassword"></label>
    <input  id="password" type="password"  formControlName="password">
  </div>
  <div class="du-flex-column gap-m">  
    <label for="password_confirm" class="font-500" translate="userId"></label>
    <input  id="password_confirm" type="password"  formControlName="confirm_password">
  </div>
</div>

<ul *ngIf="this.userForm.invalid">
  <!-- <li *ngFor="let err of userForm.errors">{{ err }}</li> -->
</ul>

<button class="width-full btn-primary btn-round mla mra mt20" translate="next"  (click)="callbackHandler()"></button>
