import { Component, EventEmitter, HostListener, Output } from '@angular/core';

@Component({
  selector:      'birth-day-input',
  templateUrl: './birth-day-input.component.html',
  host: { class: 'du-flex-column gap-xs'},
})
export class BirthDayInputComponent {
  day:   number;
  month: number;
  year:  number;

  activeInput: string;

  @Output() callback = new EventEmitter<any>();
  @HostListener('keypress', ['$event']) onClick(event: KeyboardEvent) {
    if (event.which != 8 && event.which != 0 && event.which < 48 || event.which > 57) event.preventDefault();
  }

  constructor() {}

  nextInput(event: KeyboardEvent): void {
    let target = (event.target as HTMLInputElement);
    if (this.activeInput === target.id && isFinite(+event.key) && event.key !== ' ') {
      if (+target.value > +target.max) target.value = target.max;
      if (target.value.length > 1 && target.nextElementSibling) (target.nextElementSibling as HTMLElement).focus();
    }
    target.value = target.value.replace(/[^0-9]/g,'');
    this.save(event);
  }

  keyDown(event: KeyboardEvent): any {
    let target = (event.target as HTMLInputElement);
    this.activeInput = target.id;
    if (event.key === 'Backspace' || event.key === 'Delete') {
      event.preventDefault();
      if (target.value) this.cleanField(event);
      else if (target.previousElementSibling) (target.previousElementSibling as HTMLElement).focus();
      return false;
    }
    else if (isFinite(+event.key) && (this as any)[target.id]) this.cleanField(event);
    else if (!isFinite(+event.key) || event.key === ' ') return false;
  }

  cleanField(event: KeyboardEvent): void {
    (this as any)[(event.target as HTMLInputElement).id] = null;
  }

  getMaxYear(): number {
    let noKidsAllowed = 16;
    return new Date().getFullYear() - noKidsAllowed;
  }

  private save(event: Event): void {
    this.callback.emit({
      el:    (event.target as HTMLInputElement).id,
      value: (event.target as HTMLInputElement).value,
    });
  }
}
