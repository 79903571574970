import { trigger, transition, style, animate } from '@angular/animations';

export const slideDown = trigger('slideDown', [
  transition(':leave', [
    style({ height: '12px' }),
    animate(3500, style({ height: 0 }))
  ]), 
  transition(':enter', [
    style({ height: '0px' }),
    animate(2500, style({ height: '12px' }))
  ])
]);
