import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { from } from 'rxjs';

import { DBService, NotificationService, SessionStorageService } from '@shared/services';
import { Banner } from '@shared/factories';

@Component({
  selector:      'employee-benefits',
  templateUrl: './employee-benefits.component.html',
  host: { class: 'height-full du-flex-column du-flex-justify' }
})
export class EmployeeBenefitsComponent implements OnInit {
  banner: Banner;

  promoCode: string = 'tempton2018';
  promoLink: string = 'https://tempton.vorteile.net';
  constructor(
    private router:                Router,
    private dbService:             DBService,
    public  notificationService:   NotificationService,
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.notificationService.wait();
    this.sessionStorageService.setHeaderTitle('employeeBenefits');
    this.sessionStorageService.setHeaderControls({ left: [{ icon: 'arrow-big-left color-blue font-icon', callback: () => this.router.navigateByUrl('time-tracking/home') }] });
    this.sessionStorageService.setProgressBar(null);
    from(this.dbService.loadOneFromDB('banners', { tag: 'employeeBenefitsBanner'})).subscribe(
      banner => this.banner = new Banner(banner),
      err => this.notificationService.alert(err)
    );
  }

  openBannerUrl(): void {
    if (this.banner.url.startsWith("#")) this.router.navigateByUrl(`/time-tracking/${this.banner.url.replace('#', '').replace('/', '')}`);
    else return this.openBenefitProgram(this.banner.url);
  }

  openBenefitProgram(url: string = null): void {
    return this.notificationService.confirm({
      title: 'note',
      descHTML: 'benefitsPopup',
      buttons: {
        left: 'cancel',
        right: 'yes'
      },
      rightFn: () => window.open(url || this.promoLink, '_system')
    });
  }

  copyToClipboard(): void {
    var copyElement = document.createElement("textarea");
    copyElement.style.position = 'fixed';
    copyElement.style.opacity = '0';
    copyElement.textContent = decodeURI(this.promoCode);
    var body = document.getElementsByTagName('body')[0];
    body.appendChild(copyElement);
    copyElement.select();
    document.execCommand('copy');
    body.removeChild(copyElement);
  }

}
