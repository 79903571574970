import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Assignment      } from '@shared/factories';
import { AssignmentModel } from '@shared/models';
import { DBService, SessionStorageService } from '@shared/services';

@Component({
  selector:      'assignment-picker',
  templateUrl: './assignment-picker.component.html',
  host: { class: 'du-flex-justify gap-xl pl-xl pr-xl pt-m pb-m bb-grey', '[class.du-flex-align]': '!this.assignment' }
})
export class AssignmentPickerComponent implements OnInit {
  @Input() assignment:  Assignment;
  @Input() assignments: Assignment[];
  @Output() selectCallback = new EventEmitter<Assignment>;
  constructor(
    private sessionStorageService: SessionStorageService,
    private dbService:             DBService,
  ) { }

  ngOnInit(): void {
    if (!this.assignments) this.dbService.loadMultipleFromDB('assignments')
    .then((res: AssignmentModel[]) => {
      this.assignments = res.map(a => new Assignment(a)).filter(a => 
        a.startsAt.getTime() <= new Date().getTime() && 
        a.endsAt.getTime()   >= new Date(new Date().getTime() - 7*7*24*60*60*1000).getTime() // ending not more then 7 weeks in the past
      );
    });
  }

  openAssignmentSelect(): void {
    this.sessionStorageService.pushDynamicComponent({
      component:  'AssignmentSelectBox',
      props: {
        assignment:  this.assignment,
        assignments: this.assignments,
        select: (assignment: Assignment) => this.selectCallback.emit(assignment)
      }
    });
  }

}
