import { Component, OnInit } from '@angular/core';
import { App } from '@capacitor/app';
import { SessionStorageService } from '@shared/services';

@Component({
  selector:      'blocker',
  templateUrl: './blocker.component.html',
  host: { class: 'du-flex-column du-flex-align du-flex-justify-end p-xl bg-blocker' }
})
export class BlockerComponent implements OnInit {
  constructor(
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.blockBackNavigation()
    this.clearTemp();
  }

  private clearTemp(): void {
    this.sessionStorageService.setHeaderTitle(' ');
    this.sessionStorageService.setHeaderVisibility(false);
    this.sessionStorageService.setProgressBar(null);
    this.sessionStorageService.setSortFlow(null);
    this.sessionStorageService.setFilterFlow(null);
    this.sessionStorageService.setTabFlow(null);
  }

  openStore(): void {
    window.open(this.sessionStorageService.storeUrlValue, '_system');
  }

  private blockBackNavigation(): void {
    App.addListener('backButton', () => { });
  }

}
