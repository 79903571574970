import { Component, Input } from '@angular/core';
import { Holiday } from '@shared/factories';

@Component({
  selector:      'daily-tooltips',
  templateUrl: './daily-tooltips.component.html',
  host: { class: 'du-flex-1 gap-xl' }
})
export class DailyTooltipsComponent {
  @Input() holidays: Holiday[];
  @Input() mileage:  boolean;
  @Input() errors:   boolean;
}
