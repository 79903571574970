import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ActivationService {
  private readonly USERS_API      = `${environment.apiUrl}/api/mobile/v3/users`;
  private readonly ACTIVATION_API = `${this.USERS_API}/activations`;
  private readonly RESET_API      = `${this.USERS_API}/passwords`;

  constructor( private http: HttpClient ) { }

  requestCode(type: string, email: string): Observable<any> {
    if (type === 'activation') return this.requestCodeHandler(this.ACTIVATION_API, email);
    if (type === 'reset')      return this.requestCodeHandler(this.RESET_API,      email);
    return of();
  }

  private requestCodeHandler(url: string, email: string): Observable<any> {
    let data = { user: { email }};
    return this.http.post<any>(url, data);
  }

  sendCode(type: string, email: string, activation_code: string): Observable<any> {
    if (type === 'activation') return this.sendCodeHandler(this.ACTIVATION_API, email, activation_code);
    if (type === 'reset')      return this.sendCodeHandler(this.RESET_API,      email, activation_code);
    return of();
  }

  private sendCodeHandler(url: string, email: string, activation_code: string): Observable<any> {
    let data = { user: { email, activation_code }};
    return this.http.post<any>(`${url}/activate`, data);
  }

  setPassword(type: string, email: string, activation_code: string, password: string): Observable<any> {
    if (type === 'activation') return this.setPasswordHandler(this.ACTIVATION_API, email, activation_code, password);
    if (type === 'reset')      return this.setPasswordHandler(this.RESET_API,      email, activation_code, password);
    return of();
  }

  private setPasswordHandler(url: string, email: string, activation_code: string, password: string): Observable<any> {
    let data = { user: { activation_code, email, password, password_confirmation: password }};
    return this.http.post<any>(`${url}/set_password`, data);
  }

}
