<ul class="du-flex-1 height-full du-flex-column overflow-auto"
    infiniteScroll
    [infiniteScrollDistance]="1"
    [infiniteScrollThrottle]="150"
    [scrollWindow]="false"
    (scrolled)="loadReleases()">
  <li class="du-flex-column gap-m p-xl" *ngFor="let release of releases; let i = index" [class.bb-grey]="i+1 !== releases.length">
    <div class="du-flex-column gap-s">
      <span *ngIf="release.application === 'mobile'" class="bold" translate="mobile"></span>
      <span *ngIf="release.application === 'mt.api'" class="bold">API</span>
      <div class="du-flex-justify font-s">
        <span>{{ release.releasedAt | date: 'dd.MM.YYYY' }}</span>
        <span>{{ release.version }}</span>
      </div>
    </div>
    <div class="du-flex-column gap-s bold">
      <span translate="notes"></span>
      <ul class="du-flex-column gap-s ml-xl list-disc">
        <li *ngFor="let note of release.parcedNotes" class="lh-l">{{ note }}</li>
      </ul>
    </div>
  </li>
</ul>
