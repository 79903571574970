<div class="du-flex-column gap-m">
  <div class="du-flex-column gap-xxs">
    <h2 class="font-500 lh-l" translate="enterActivationCode"></h2>
    <h3 class="font-s   lh-l" translate="sendBySms"></h3>
  </div>

  <div class="du-flex-justify height-50 width-full font-xl pin-box">
    <input *ngFor="let pin of pins; let i = index"
           [class.active]="checkActive(i)"
            class="p-s width-45 text-center pin"
            onfocus="this.select()"
            type="tel"
            min="0" max="9">
  </div>

  <span class="du-flex-align gap-s mt-m">
    <span class="font-s" translate="resendActivationCode"></span>
    <span *ngIf="timer"  class="font-m font-500 roboto">00:{{ timer < 10 ? '0' : '' }}{{ timer }}</span>
    <span *ngIf="!timer" class="font-m font-500 underline" (click)="resendCode()" translate="resend"></span>
  </span>

</div>

<button class="width-full btn-primary btn-round mla mra mt20" translate="next"></button>
